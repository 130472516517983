import { useEffect, useState } from 'react';
import { ModalContent } from '@components/modal';
import PublicNoticeInfo from './PublicNoticeInfo';
import PublicNoticeComment from '../public-notice-comment/PublicNoticeComment';
import { Button } from '@trussworks/react-uswds';
import { connect } from 'redux-bundler-react';

const PublicNoticeInfoModal = connect(
  'doFetchPublicNoticeById',
  'selectPublicNoticeById',
  ({ doFetchPublicNoticeById, publicNoticeById, publicNoticeId }) => {
    const [view, setView] = useState('info');

    const handleCommentsClick = () => {
      setView('comments');
    };
    const handleClose = () => {
      setView('info');
    };

    useEffect(() => {
      doFetchPublicNoticeById(publicNoticeId);
    }, [doFetchPublicNoticeById, publicNoticeId]);

    const modalTitle =
      view === 'info' ? (
        <>
          Public Notice: <span>{publicNoticeById.daNumber}</span>
        </>
      ) : (
        <>Submit Public Notice Comments</>
      );
    const hasCloseButton = view === 'info';

    return (
      <ModalContent title={modalTitle} hasCloseButton={hasCloseButton}>
        <div className='modal-body'>
          {!publicNoticeById.publicNoticeID && <div>Loading...</div>}
          {publicNoticeById.publicNoticeID && view === 'info' && (
            <>
              <div className='pn-comment-button-container'>
                <Button className='button-small' onClick={handleCommentsClick}>
                  Submit Comments
                </Button>
              </div>
              <PublicNoticeInfo data={publicNoticeById} />
            </>
          )}
          {publicNoticeById.publicNoticeID && view === 'comments' && (
            <PublicNoticeComment data={publicNoticeById} onClose={handleClose} />
          )}
        </div>
      </ModalContent>
    );
  }
);

export default PublicNoticeInfoModal;
