import { connect } from 'redux-bundler-react';
import { Link } from '@trussworks/react-uswds';

import Dropdown from '../dropdown';
import { classArray, hrefAsString } from '../../utils/helpers.jsx';

/**
 * @param {Array} href - An array of strings, or objects structured as { text: '', uri: '' }.
 * @param {Array} includedLinks - An array of uris to force the display of the active class when user is on one of the provided uris.
 * @param {boolean} asDropdown - Will cause single href arrays to be displayed as Dropdown menus. Default: false
 * @param {Icon} icon - Icon to be displayed on the Navigation item's content.
 * @param {String} iconPosition - One of ['left', 'right']. Displays the icon the left or right of provided children, respectively.
 */
const NavItem = connect(
  'doUpdateUrl',
  'selectRelativePathname',
  'selectUserIsDeveloper',
  ({
    doUpdateUrl,
    relativePathname: pathname,
    userIsDeveloper,
    href,
    icon,
    iconPosition = 'left',
    isHidden = false,
    asDropdown = false,
    isHome = false,
    includedLinks = [],
    handler,
    className,
    children,
  }) => {
    const isActive = () => {
      if (isHome && pathname === '') return true;

      if (href && href.length) {
        if (includedLinks.includes(pathname)) return true;
        if (typeof href[0] === 'string') {
          if (href.includes(pathname)) return true;
        } else {
          if (href.filter((el) => el.uri === pathname).length) return true;
        }
      }

      return false;
    };

    const cls = classArray([
      'nav-item',
      href && (href.includes(pathname) || includedLinks.includes(pathname)) && 'active',
      isActive() && 'active',
      className,
    ]);

    const isDropdown = asDropdown || (href && href.length > 1);

    const handleClick = (e) => {
      if (handler && typeof handler === 'function') {
        userIsDeveloper && doUpdateUrl('/developer');
        handler(e);
      }
    };

    const ItemContent = () => (
      <>
        {isDropdown === true && iconPosition === 'left' && icon}
        {isDropdown === true && icon && iconPosition === 'left' && <>&nbsp;</>}
        {children}
        {isDropdown === true && icon && iconPosition === 'right' && <>&nbsp;</>}
        {isDropdown === true && iconPosition === 'right' && icon}
      </>
    );

    return !isHidden ? (
      handler ? (
        <li className={cls} onClick={handleClick}>
          <span className='nav-link'>
            <ItemContent />
          </span>
        </li>
      ) : (
        <li className={cls}>
          {isDropdown ? (
            <Dropdown.Menu
              withToggleArrow={false}
              menuClass='dropdown-menu-left'
              buttonClass='btn-small p-0 nav-dropdown-button'
              buttonContent={(
                <span className='nav-link'>
                  <ItemContent />
                </span>
              )}
            >
              {href.map((link) => {
                if (typeof link === 'string') {
                  return (
                    <Dropdown.Item key={link} href={link} className={link === pathname ? 'active' : ''}>
                      {hrefAsString(link)}
                    </Dropdown.Item>
                  );
                } else {
                  const { text, uri } = link;
                  return (
                    <Dropdown.Item key={text} href={uri} className={uri === pathname ? 'active' : ''}>
                      {text}
                    </Dropdown.Item>
                  );
                }
              })}
            </Dropdown.Menu>
          ) : (
            <Link className='nav-link' href={href}>
              <ItemContent />
            </Link>
          )}
        </li>
      )
    ) : null;
  }
);

export default NavItem;
