import { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'redux-bundler-react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { ModalContent } from '@components/modal';
import Icon from '@components/icon/Icon';
import { mdiDownloadMultiple, mdiAlertOutline } from '@mdi/js';
import DownloadRenderer from './DownloadRenderer';
import { Button } from '@trussworks/react-uswds';
import { dateFormatter } from '@src/utils/ag-grid-helpers';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import '@styles/_buttons.scss';

const CustomLoadingOverlay = () => (
  <div>
    <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
    <p className='mt-2'>Loading...</p>
  </div>
);

const FilesModal = connect(
  'doDownloadZip',
  'doFetchUserFilesDetails',
  'doResetUsersFileData',
  'selectGeneratedFiles',
  ({
    doDownloadZip,
    doFetchUserFilesDetails,
    doResetUsersFileData,
    generatedFiles,
    data
  }) => {
    const gridRef = useRef();

    const [isDataLoading, setIsDataLoading] = useState(true);

    const loadingOverlayComponent = useMemo(() => CustomLoadingOverlay, []);

    useEffect(() => {
      const paramsID = { projectID: data?.projectID, requestID: data?.requestID, version: 0 };
      // Fetch all request files from S3
      setIsDataLoading(true);
      doResetUsersFileData();
      const myPromise = new Promise((resolve, reject) => {
        resolve(doFetchUserFilesDetails(data?.id, false, paramsID));
      });
      Promise.all([myPromise]).then((res) => {
        res && setIsDataLoading(false);
        gridRef.current.api.hideOverlay();
        gridRef.current.api.showLoadingOverlay();
      });
    }, [data?.id, doFetchUserFilesDetails, doResetUsersFileData, data?.projectID, data?.requestID]);

    return (
      <ModalContent hasCloseButton title='Documents'>
        <section className='modal-body'>
          <div className='container-fluid'>
            {(generatedFiles?.length === 0 && !isDataLoading) && (<><Icon focusable={false} path={mdiAlertOutline} size='16px' />The generated PDF of your request is not available.</>)}
            {(generatedFiles?.length !== 0 && !isDataLoading) &&
            <Button
              className='download-all-btn'
              title='Download All'
              size='small'
              onClick={() => doDownloadZip(generatedFiles?.map((item) => item.DocumentType ? `Generated/${item.fileName}` : item.fileName), { projectID: data?.projectID, requestID: data?.requestID, version: 0 })}
            >
              <Icon focusable={false} path={mdiDownloadMultiple} size={'16px'} />
              Download All
            </Button>
            }
            <div className='ag-theme-balham dashboard-table mt-2'>
              <AgGridReact
                ref={gridRef}
                rowHeight={35}
                pagination={true}
                paginationAutoPageSize={true}
                rowData={generatedFiles}
                defaultColDef={{ width: 150 }}
                overlayLoadingTemplate='<span>No documents available</span>'
                noRowsOverlayComponent={loadingOverlayComponent}
                components={{
                  'downloadRenderer': DownloadRenderer,
                }}
              >
                <AgGridColumn headerName='Actions' cellRenderer='downloadRenderer' cellRendererParams={{ dashboardData: data }} width={70} />
                <AgGridColumn field='fileName' headerName='Filename' width={350} resizable />
                <AgGridColumn field='section' valueGetter={(params) => (params.data.DocumentType ?? params.data.section)} resizable />
                <AgGridColumn field='lastModified' valueGetter={(params) => dateFormatter(params.data.lastModified)} />
              </AgGridReact>
            </div>
          </div>
        </section>
      </ModalContent>
    );
  });

export default FilesModal;
