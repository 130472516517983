import { connect } from 'redux-bundler-react';
import TextAreaInput from '@components/textarea/TextArea';

const PermitsDischarges = connect('selectIsReadOnly', ({ isReadOnly, handleChange = () => {} }) => (
  <div className='container-fluid mt-2'>
    <>
      <div className='ml-2'>
        <TextAreaInput
          name='dischargeMaterialSource'
          label='Describe the source of the material'
          onChange={handleChange}
          readOnly={isReadOnly}
          required
        />
      </div>
      <div className='ml-2'>
        <TextAreaInput
          name='dischargeReason'
          label='Reason(s) for Discharge'
          tooltip='If the activity involves the discharge of dredged and/or fill material into a wetland or other waterbody, including the temporary placement of material, explain the specific purpose of the placement of the material (such as erosion control).'
          onChange={handleChange}
          readOnly={isReadOnly}
          required
        />
      </div>
    </>
  </div>
));

export default PermitsDischarges;
