import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';
import L from 'leaflet';
import * as ELG from 'esri-leaflet-geocoder';
import icon from '../assets/search-marker-icon.png';

const customIcon = L.icon({
  iconUrl: icon,
  iconSize: [32, 32], // Adjust size as needed
  iconAnchor: [16, 32], // Center bottom of the icon
  popupAnchor: [0, -32], // Position of the popup relative to the icon
});

const Geocoder = ({ resultZoomLevel = 14, setShowReset }) => {
  const map = useMap();

  useEffect(() => {
    const getEsriToken = async () => {
      const clientId = import.meta.env.VITE_ESRI_CLIENT_ID;
      const clientSecret = import.meta.env.VITE_ESRI_CLIENT_SECRET;
      const url = 'https://www.arcgis.com/sharing/rest/oauth2/token';

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            grant_type: 'client_credentials',
            client_id: clientId,
            client_secret: clientSecret,
          }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error fetching Esri token:', error);
        return false;
      }
    };

    getEsriToken().then((token) => {
      if (!token) return;

      const geosearchControl = ELG.geosearch({
        position: 'topright',
        allowMultipleResults: true,
        zoomToResult: false,
        useMapBounds: false,
        collapseAfterResult: false,
        expanded: true,
        providers: [
          ELG.arcgisOnlineProvider({
            label: 'Search Results',
            countries: 'USA,ASM,PRI,UMI,VIR,GUM,FSM',
            apikey: token.access_token,
          }),
        ],
      }).addTo(map);

      let currentMarker;

      geosearchControl.on('results', (event) => {
        if (event.results && event.results.length > 0) {
          const resultLatLng = event.results[0].latlng;
          map.setView(resultLatLng, resultZoomLevel);
          const inputElement = document.querySelector('.geocoder-control-input');
          const searchText = event.results[0].text;
          if (inputElement) {
            inputElement.value = searchText; // Set the input value back to the search text
            setShowReset && setShowReset(true);
          }
          // Remove the previous marker if it exists
          if (currentMarker) {
            map.removeLayer(currentMarker);
          }

          // Create and add the new marker
          currentMarker = L.marker(resultLatLng, { icon: customIcon, customId: 'search-marker' }).addTo(map);
          currentMarker.bindTooltip('Search Result', {
            permanent: false,
            direction: 'top',
            offset: [-5, -32],
          });
          currentMarker.on('click', () => {
            map.setView(resultLatLng, 14);
          });
        }
      });
    });

    return () => {
      map.eachLayer((layer) => {
        if (layer instanceof ELG.Geosearch) {
          map.removeControl(layer);
        }
      });
    };
  }, [map, resultZoomLevel, setShowReset]);

  return null;
};

export default Geocoder;
