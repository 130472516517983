import { connect } from 'redux-bundler-react';
import { Accordion, Table } from '@trussworks/react-uswds';
import { SectionHeader } from '../_shared/helper';
import ExternalLink from '@components/external-link/ExternalLink';
import InternalLink from '@components/internal-link/InternalLink';

import LRD from './JointPermitApplications.LRD';
import MVD from './JointPermitApplications.MVD';
import NAD from './JointPermitApplications.NAD';
import NWD from './JointPermitApplications.NWD';
import SAD from './JointPermitApplications.SAD';
import SPD from './JointPermitApplications.SPD';
import React, { useEffect } from 'react';

export const DistrictInfoTable = connect(
  'doGetDistrictsUnAuth',
  'selectUnAuthDivisionsAndDistricts',
  ({ doGetDistrictsUnAuth, unAuthDivisionsAndDistricts, district, orgEmail }) => {
    const districtInfo = unAuthDivisionsAndDistricts?.filter(
      (item) => item.orgShortName === district && item.orgPhone && item.orgAddress && item.orgUrl
    );

    useEffect(() => {
      doGetDistrictsUnAuth();
    }, [doGetDistrictsUnAuth]);

    return (
      districtInfo?.length > 0 && (
        <Table bordered fullWidth>
          <tbody valign='top'>
            {' '}
            {districtInfo?.map((item) => (
              <React.Fragment key={item.orgName}>
                <tr key={1}>
                  <td>Name </td>
                  <td>{item.orgName}</td>
                </tr>
                <tr key={2}>
                  <td>Address </td>
                  <td>{item.orgAddress}</td>
                </tr>
                <tr key={3}>
                  <td>Phone </td>
                  <td>{item.orgPhone}</td>
                </tr>
                <tr key={4}>
                  <td>URL </td>
                  <td>
                    <ExternalLink content={item.orgUrl} title={item.orgUrl} href={item.orgUrl} />
                  </td>
                </tr>
                {orgEmail && (
                  <tr key={5}>
                    <td>JPA Email </td>
                    <td>
                      <InternalLink content={orgEmail} title={`MailTo ${orgEmail}`} href={`mailto:${orgEmail}`} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      )
    );
  }
);

const USACEDivisionAccordion = [
  {
    id: '1',
    headingLevel: 'h3',
    title: 'Great Lakes and Ohio River Division (LRD)',
    content: <LRD />,
  },
  {
    id: '2',
    headingLevel: 'h3',
    title: 'Mississippi Valley Division (MVD)',
    content: <MVD />,
  },
  {
    id: '3',
    headingLevel: 'h3',
    title: 'North Atlantic Division (NAD)',
    content: <NAD />,
  },
  {
    id: '4',
    headingLevel: 'h3',
    title: 'Northwestern Division (NWD)',
    content: <NWD/>,
  },
  {
    id: '5',
    headingLevel: 'h3',
    title: 'South Atlantic Division (SAD)',
    content: <SAD/>,
  },
  {
    id: '6',
    headingLevel: 'h3',
    title: 'South Pacific Division (SPD)',
    content: <SPD/>,
  },
];


const JointPermitApplications = () => (
  <section id='jpa-id' className='margin-top-3'>
    <SectionHeader>Joint Permit Application Process</SectionHeader>
    <p>This section provides guidance on submitting Joint Permit Application (JPA) forms to districts that offer JPAs in partnership with Federal and State agencies. This process serves as an interim measure while RRS capabilities are being expanded over the next year to support joint permit application submissions. Applicants and agents should reach out to their respective <ExternalLink content='District offices' title='District Offices' href='https://regulatory.ops.usace.army.mil/offices/'/> for any questions regarding the joint permit application process. Only districts with JPAs are listed below; if a district is not included in the table, no JPA is available for that location.</p>

    <p>Please select a USACE Regulatory Division, then choose a Regulatory District to view district-specific information.</p>
    <h6>USACE Regulatory Divisions</h6>
    <Accordion bordered items={USACEDivisionAccordion} multiselectable={true}  />
  </section>
);

export default JointPermitApplications;
