import React, { useState, useEffect } from 'react';

import { connect } from 'redux-bundler-react';

const RegulationTypeTableCell = connect(
  'selectLookupTableData',
  ({
    lookupTableData,
    getValue,
    row,
    column,
    table,
    cell,
  }) => {
    const columnMeta = column.columnDef.meta;
    const tableMeta = table.options.meta;
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);
    const latestState = row.getValue('state');
    const fallbackRegulation = columnMeta?.fallbackRegulation;
    const [state, setState] = useState(latestState);

    useEffect(() => {
      if (state) {
        const regulationIndex = lookupTableData?.StateRegulations?.findIndex((statereg) => statereg.state.toUpperCase() === state?.toUpperCase());
        const regulation = lookupTableData?.StateRegulations?.[regulationIndex]?.regulationType;
        setValue(regulation);
        tableMeta?.updateData(row.index, column.id, regulation);
      }
      else {
        setValue(fallbackRegulation);
        tableMeta?.updateData(row.index, column.id, fallbackRegulation);
      }
    }, [state, setValue, tableMeta, column.id, fallbackRegulation, row.index, lookupTableData?.StateRegulations]);

    useEffect(() => {
      latestState && setState(latestState);
    }, [latestState]);

    const handleBlur = (e) => {
      // tableMeta?.updateData(row.index, column.id, e.target.value);
    };

    const handleChange = (e) => {
      setValue(e.target.value);
      tableMeta?.updateData(row.index, column.id, e.target.value);
    };

    return (
      <input
        aria-label={'Regulation Type'}
        id={cell.id}
        onBlur={handleBlur}
        onChange={handleChange}
        readOnly={columnMeta?.readOnly}
        required={columnMeta?.required}
        style={{ width: '100%' }}
        type={columnMeta?.type || 'text'}
        value={value ?? ''}
      />
    );
  }
);

export default RegulationTypeTableCell;