import React, { useState } from 'react';
import { DistrictInfoTable } from '../../JointPermitApplications';
import SAAContent from './SAAContent';
import LinkButton from '@components/link/linkButton';

const ReadMore = ({ children, elementLimit = 2 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const content = children.props.children;

  const isTruncated = content.length > elementLimit;

  const toggleExpansion = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div>
      {isExpanded || !isTruncated ? (
        children
      ) : (
        <div>
          {content.slice(0, elementLimit)}
          <LinkButton title='show more' content='show more' onClick={toggleExpansion}  />
        </div>
      )}
      {isTruncated && isExpanded && (
        <LinkButton title='show less' content='show less' onClick={toggleExpansion} />
      )}
    </div>
  );
};

const SAA_CARIBBEAN = () => (
  <>
    <section id='saa-caribbean'>
      <DistrictInfoTable district='SAA'/>
      <ReadMore>{SAAContent}</ReadMore>
    </section>
  </>
);

export default SAA_CARIBBEAN;
