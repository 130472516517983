import React from 'react';
import { connect } from 'redux-bundler-react';

import ModuleButton from './components/_shared/ModuleButton';
import isAnonymousModal from './components/modals/isAnonymous';
import NewApplicationModal from '../Home/components/modals/NewApplication';

import jurisdictionIcon from '@styles/images/Jurisdiction.png';
import mitigationIcon from '@styles/images/Mitigation.png';
import permittingIcon from '@styles/images/Permitting.png';
import programOverviewIcon from '@styles/images/Regulatory-101.png';
import publicNoticesIcon from '@styles/images/Public-Notices.png';
import violationIcon from '@styles/images/Report-Violations.png';
import applyPermitIcon from '@styles/images/Apply-for-Permit.png';
import rrsIcon from '@styles/images/RRS.png';

import './resources.scss';
import { ProfileRoles } from '../../utils/enums';
import { Button, Grid, GridContainer } from '@trussworks/react-uswds';

const rowProps = {
  row: true,
  gap: 'md',
};

const cellProps = {
  desktop: { col: 3 },
  tablet: { col: 6 },
  style: { marginBottom: '1rem' },
};

const Resources = connect(
  'doModalOpen',
  'doUpdateRelativeUrl',
  'doResetReduxFormData',
  'doAuthLogin',
  'selectAuthIsLoggedIn',
  'selectUserProfileData',
  'doAuthLogin',
  ({ doModalOpen, doUpdateRelativeUrl, doResetReduxFormData, doAuthLogin, authIsLoggedIn, userProfileData }) => {
    const permitButtonDisabled = [ProfileRoles.USACERegulator, ProfileRoles.ExternalAgency].includes(
      userProfileData?.role
    );

    const handleViolationComplaintClick = () => {
      doResetReduxFormData();
      doModalOpen(isAnonymousModal);
    };

    const handlePermitsClick = () => {
      sessionStorage.setItem('PermitButtonSession', true);

      if (authIsLoggedIn) {
        const PermitButtonSession = sessionStorage.getItem('PermitButtonSession');

        doUpdateRelativeUrl('/home');
        doResetReduxFormData();
        PermitButtonSession && doModalOpen(NewApplicationModal);
        sessionStorage.removeItem('PermitButtonSession');
      } else {
        doAuthLogin();
      }
    };

    const handleStartClick = () => {
      authIsLoggedIn ? doUpdateRelativeUrl('/') : doAuthLogin();
    };

    return (
      <div className='card-menu-container'>
        <div className='hero-container resources'>
          <div className='hero-image' />
          <div className='hero-content'>
            <h1>Welcome to the Regulatory Request System</h1>
            <div className='hero-description'>
              Apply Online • Learn about the Regulatory Program • Track Request Status
            </div>
            <Button onClick={handleStartClick} tabIndex={0}>
              Get Started
            </Button>
          </div>
        </div>
        <GridContainer>
          <Grid {...rowProps}>
            <Grid {...cellProps}>
              <ModuleButton
                title='Regulatory Program Information'
                imageAlt='regulatory program information icon'
                text='Learn the basics about the Regulatory Program, including recent announcements.'
                image={programOverviewIcon}
                onClick={() => doUpdateRelativeUrl('/home/regulatory-program')}
              />
            </Grid>
            <Grid {...cellProps}>
              <ModuleButton
                title='Jurisdiction'
                imageAlt='jurisdiction icon'
                text='Does the property in question contain wetlands/waters? If so, does the USACE have jurisdiction?'
                image={jurisdictionIcon}
                onClick={() => doUpdateRelativeUrl('/home/jurisdiction')}
              />
            </Grid>
            <Grid {...cellProps}>
              <ModuleButton
                title='Permitting'
                imageAlt='permitting icon'
                text='Do you need a permit? Need to schedule a pre-application meeting?'
                image={permittingIcon}
                onClick={() => doUpdateRelativeUrl('/home/permitting')}
              />
            </Grid>
            <Grid {...cellProps}>
              <ModuleButton
                image={mitigationIcon}
                imageAlt='mitigation icon'
                onClick={() => doUpdateRelativeUrl('/home/mitigation')}
                text='Discover how we help avoid and minimize impacts to aquatic resources.'
                title='Mitigation'
              />
            </Grid>
            <Grid {...cellProps}>
              <ModuleButton
                title='Report Violations'
                imageAlt='report violations icon'
                text='Submit a report of unauthorized activity or permit non-compliance.'
                image={violationIcon}
                onClick={handleViolationComplaintClick}
              />
            </Grid>
            <Grid {...cellProps}>
              <ModuleButton
                title='RRS Support'
                imageAlt='rrs help icon'
                text='Get general system support, district contact information, submit feedback, and login.gov help.'
                image={rrsIcon}
                onClick={() => doUpdateRelativeUrl('/help')}
              />
            </Grid>
            <Grid {...cellProps}>
              <ModuleButton
                title='Public Notices'
                imageAlt='public notices icon'
                text='Search, Review, and Comment on USACE Regulatory Public Notices'
                image={publicNoticesIcon}
                onClick={() => doUpdateRelativeUrl('/public-notices')}
              />
            </Grid>
            <Grid {...cellProps}>
              <ModuleButton
                title='Apply for a Permit'
                imageAlt='permit application icon'
                text="If you're ready to apply for a permit, click here to login or start a new request"
                isDisabled={permitButtonDisabled}
                image={applyPermitIcon}
                onClick={handlePermitsClick}
              />
            </Grid>
          </Grid>
        </GridContainer>
      </div>
    );
  }
);

export default Resources;
