import { useMemo } from 'react';
import PNMap from '@components/map/PNMap';
import { Accordion } from '@trussworks/react-uswds';
import { generateMapUseTipsAccordionList } from '@src/app-pages/Forms/input-forms/_helper';

const PublicNoticesMap = ({ publicNotices }) => {
  const points = useMemo(
    () =>
      publicNotices?.map((notice) => ({
        type: 'Point',
        coordinates: [notice.longitude, notice.latitude],
        properties: {
          ...notice,
        },
      })),
    [publicNotices]
  );

  return (
    <>
      <Accordion bordered items={generateMapUseTipsAccordionList('PN')} className='margin-bottom-1' />
      <div className='pn-map-container'>
        <PNMap geometry={points} />
      </div>
    </>
  );
};

export default PublicNoticesMap;
