const complexStateBundle = {
  name: 'complexstate',
  getReducer: () => {
    const initialData = {
      jdtype: null,
      arstate: null,
      contactschanged: false,
      aquaticresourcechanged: false,
      isnewrequest: false,
      isreadonly: false,
      projectBounds: null,
      projectGeometry: {},
      arCsvData: null,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'COMPLEX_STATE_RESET') {
        return initialData;
      }

      if (type === 'COMPLEX_STATE_FIELD_UPDATED') {
        return { ...state, [payload.name]: payload.value };
      }

      if (type === 'COMPLEX_STATE_FIELD_RESET') {
        return { ...state, [payload.name]: null };
      }

      return state;
    };
  },
  doResetComplexState:
    () =>
      ({ dispatch }) => {
        dispatch({
          type: 'COMPLEX_STATE_RESET',
        });
      },
  doResetComplexStateField:
    (props) =>
      ({ dispatch }) => {
        dispatch({
          type: 'COMPLEX_STATE_FIELD_RESET',
          payload: { name: props.name },
        });
      },
  doUpdateComplexStateField:
    (props) =>
      ({ dispatch }) => {
        dispatch({
          type: 'COMPLEX_STATE_FIELD_UPDATED',
          payload: { name: props.name, value: props.value },
        });
      },
  selectComplexState: (state) => state.complexstate,
  selectJDType: (state) => state.complexstate.jdtype,
  selectARState: (state) => state.complexstate.arstate,
  selectContactsChanged: (state) => state.complexstate.contactschanged,
  selectAquaticResourceChanged: (state) => state.complexstate.aquaticresourcechanged,
  selectIsNewRequest: (state) => state.complexstate.isnewrequest,
  selectIsReadOnly: (state) => state.complexstate.isreadonly,
  selectProjectBounds: (state) => state.complexstate.projectBounds,
  selectProjectGeometry: (state) => state.complexstate.projectGeometry,
  selectArCsvData: (state) => state.complexstate.arCsvData,
};

export default complexStateBundle;
