import { createRouteBundle } from 'redux-bundler';
import ContentLayout from '@pages/Resources/components/ContentLayout';
import Dashboard from '@pages/Dashboard';
import DeveloperDashboard from '@pages/Developer/DeveloperDashboard';
import Forms from '@pages/Forms';
import Help from '@pages/Help';
import Home from '@pages/Home';
import NotFound from '@pages/NotFound';
import PublicNotices from '@pages/PublicNotices/PublicNotices';
import Resources from '@pages/Resources';
import Updates from '@pages/Updates';

// const base = import.meta.env.VITE_URL_BASE_PATH ?? '';
const base = '';

export default createRouteBundle(
  {
    [`${base}`]: Home,
    [`${base}/`]: Home,
    [`${base}/dashboard`]: Dashboard,
    [`${base}/developer`]: DeveloperDashboard,
    [`${base}/forms`]: Forms,
    [`${base}/forms/:id`]: Forms,
    [`${base}/help`]: Help,
    [`${base}/home`]: Resources,
    [`${base}/home/:resource`]: ContentLayout,
    [`${base}/public-notices`]: PublicNotices,
    [`${base}/updates`]: Updates,
    '*': NotFound,
  },
  {
    routeInfoSelector: 'selectRelativePathname',
  }
);
