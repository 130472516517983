const statusCss = {
  Acknowledged: 'acknowledged',
  Assigned: 'assigned',
  Cancelled: 'cancelled',
  Draft: 'draft',
  'Lead Agency Changed': 'lead-agency-changed',
  'Not Approved': 'not-approved',
  'Re-Routed': 're-routed',
  Submitted: 'submit',
  Withdrawn: 'withdrawn',
};

const RequestStatusRenderer = ({ value }) => {
  if (!value) return null;

  return <span className={`request-status-tag ${statusCss[value]}`}>{value}</span>;
};

export default RequestStatusRenderer;
