import { useEffect, useState, useMemo } from 'react';
import { connect } from 'redux-bundler-react';
import { createColumnHelper } from '@tanstack/react-table';
import { Button } from '@trussworks/react-uswds';
import Icon from '@components/icon/Icon';
import FieldHeader from '@forms/components/Form/FieldHeader';
import AddSupportingDocumentModal from '@forms/components/modals/AddSupportDocumentModal';
import EditSupportingDocsTableCell from '@src/app-components/table/tableCellComponents/EditSupportingDocsTableCell';
import SelectInput from '@components/select/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { mdiCloseOctagon, mdiDownload, mdiPlusBox } from '@mdi/js';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import useErrorFocus from '@hooks/useErrorFocus';
import { ErrorMessages } from '@src/utils/enums';
import TanStackTableBasic from '@src/app-components/table/TanStackTable/TanStackTableBasic';

export const SupportingInformationMetadata = {
  sectionName: 'Supporting Information',
  isSection: true,
  lastSection: false,
  firstSection: false,
};

const schema = yup.object().shape({
  hasSupportingDocuments: yup.string().required('Please select an option'),
});

const SupportingInformation = connect(
  'doDeleteFile',
  'doDownloadFile',
  'doDownloadZip',
  'doModalOpen',
  'doResetFileSection',
  'doUpdateSectionValidity',
  'selectSupportingDocFiles',
  'selectSteps',
  'selectActiveStep',
  'selectIsReadOnly',
  ({
    doDeleteFile,
    doDownloadFile,
    doDownloadZip,
    doModalOpen,
    doResetFileSection,
    doUpdateSectionValidity,
    supportingDocFiles,
    steps,
    activeStep,
    isReadOnly,
    componentID,
    subtext,
    stepNo,
    requestData,
    updateRequestData,
  }) => {
    const defaultValues = {
      hasSupportingDocuments: requestData?.hasSupportingDocuments !== null ? requestData?.hasSupportingDocuments : null,
    };

    const methods = useForm({ resolver: yupResolver(schema), mode: 'onBlur', defaultValues: defaultValues });
    const {
      formState: { errors, isValid },
      watch,
      setError,
      clearErrors,
      trigger,
    } = methods;
    const [tableError, setTableError] = useState(false);
    const supportingDocFileCount = supportingDocFiles?.length;

    const hasSupportingDocuments = watch('hasSupportingDocuments');

    const thisSectionStepStatus = useMemo(() => steps.find((step) => step.id === stepNo)?.touched, [steps, stepNo]);
    const columnHelper = createColumnHelper();

    const columnDefs = useMemo(
      () => [
        columnHelper.display({
          header: 'Actions',
          id: 'actions',
          cell: ({ row }) => (
            <EditSupportingDocsTableCell
              row={row}
              doDownloadFile={doDownloadFile}
              doDeleteFile={doDeleteFile}
              isReadOnly={isReadOnly}
            />
          ),
          size: 60,
          enableSorting: false,
          meta: {
            centerText: true,
          },
        }),
        columnHelper.accessor('fileName', {
          header: 'File Name',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('doclabel', {
          header: 'Document Label',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 400,
        }),
        columnHelper.accessor('doctype', {
          header: 'Document Type',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('doccreateddate', {
          header: 'Document Created Date',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 100,
        }),
        columnHelper.accessor('doccitation', {
          header: 'Information Source/Citation',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 400,
        }),
      ],
      [columnHelper, doDeleteFile, doDownloadFile, isReadOnly]
    );

    const placeholder = (
      <div className='d-flex flex-column align-items-center margin-top-1 margin-bottom-1'>
        <p>No supporting documents found, please click below to add a document:</p>
        <div className='margin-top-1'>
          <Button
            className='add-document-button'
            title='Add a Document'
            size='small'
            onClick={() => doModalOpen(AddSupportingDocumentModal, { componentID })}
            disabled={isReadOnly}
          >
            <Icon focusable={false} className='mr-1' path={mdiPlusBox} size='16px' />
            Add a Document
          </Button>
        </div>
      </div>
    );

    useEffect(() => {
      const valid = isValid && !tableError;
      doUpdateSectionValidity(SupportingInformationMetadata.sectionName, valid ? true : false, stepNo, isReadOnly);
    }, [isValid, tableError, doUpdateSectionValidity, stepNo, isReadOnly]);

    useEffect(() => {
      updateRequestData({ hasSupportingDocuments: hasSupportingDocuments });
      if (
        thisSectionStepStatus === 'true' &&
        (hasSupportingDocuments === 'true' || hasSupportingDocuments === true) &&
        supportingDocFileCount < 1
      ) {
        setTableError(true);
        setError('supportingDocuments', {
          type: 'custom',
          message: 'Supporting Documents: At least one supporting document is required',
        });
      } else if (
        thisSectionStepStatus === 'true' &&
        hasSupportingDocuments !== 'true' &&
        hasSupportingDocuments !== true &&
        hasSupportingDocuments !== 'false' &&
        hasSupportingDocuments !== false
      ) {
        setTableError(true);
        trigger();
      } else {
        setTableError(false);
        trigger();
        clearErrors('supportingDocuments');
      }
    }, [
      thisSectionStepStatus,
      hasSupportingDocuments,
      supportingDocFileCount,
      updateRequestData,
      setError,
      clearErrors,
      trigger,
    ]);

    useEffect(() => {
      if (supportingDocFileCount > 0) {
        (hasSupportingDocuments === 'false' || hasSupportingDocuments === false) &&
          doResetFileSection('Supporting Documents', true);
      }
    }, [hasSupportingDocuments, doResetFileSection, supportingDocFileCount]);

    useErrorFocus({ steps, stepNo, activeStep, trigger, noTrigger: true, isReadOnly });

    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly && (
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        )}
        <FieldHeader
          text='Supporting Information'
          subtext={
            <>
              {subtext}
              <p>
                Please ensure the values for uploaded files only include letters, digits, white-space, and the
                characters, '_', '.', '/', '=', '+', '-', ':', '@'. If any characters outside of these mentioned are
                included in the File name or Document Label fields you will receive an error message. To resolve the
                error, remove the uploaded documents with the "Clear Staged Documents" button, and then reupload the
                files again ensuring the file name and document labels only include the aforementioned characters.
              </p>
              <p className='text-bold'>{ErrorMessages.UploadNotice}</p>
            </>
          }
        />
        <div className='ml-2'>
          <SelectInput
            name='hasSupportingDocuments'
            label='Are there any supporting documents you will be uploading?'
            required
            className='w-50 mb-3'
            readOnly={isReadOnly}
          >
            <option key='1' value='true'>
              Yes
            </option>
            <option key='2' value='false'>
              No
            </option>
          </SelectInput>
        </div>
        {(hasSupportingDocuments === 'true' || hasSupportingDocuments === true) && (
          <>
            <div className='d-flex width-full margin-top-2 padding-bottom-2 justify-content-end'>
              {supportingDocFiles.length !== 0 && (
                <Button
                  className='add-document-button'
                  title='Add a Document'
                  size='small'
                  onClick={() => doModalOpen(AddSupportingDocumentModal, { componentID: componentID })}
                  disabled={isReadOnly}
                >
                  <Icon focusable={false} className='margin-right-1' path={mdiPlusBox} size={'16px'} />
                  Add a Document
                </Button>
              )}
              {supportingDocFiles.filter((item) => item.key).length !== 0 && (
                <Button
                  className='all-documents-button'
                  title='Download All Documents'
                  size='small'
                  onClick={() =>
                    doDownloadZip(
                      supportingDocFiles?.map((item) => {
                        const parsedArr = item?.key?.split('/');
                        return parsedArr[parsedArr.length - 1];
                      })
                    )
                  }
                  disabled={isReadOnly}
                >
                  <Icon focusable={false} className='mr-1' path={mdiDownload} size={'16px'} />
                  Download All Documents
                </Button>
              )}
              {supportingDocFiles.filter((item) => !item.key).length !== 0 && !isReadOnly && (
                <Button
                  className={`clear-table-button ${supportingDocFiles.filter((item) => !item.key).length === 0 || isReadOnly ? 'disabled' : 'hover'}`}
                  title='Clear Staged Documents'
                  size='small'
                  onClick={() => doResetFileSection('Supporting Documents')}
                  disabled={supportingDocFiles.filter((item) => !item.key).length === 0 || isReadOnly}
                >
                  <Icon focusable={false} path={mdiCloseOctagon} size={'16px'} />
                  Clear Staged Documents
                </Button>
              )}
            </div>
            <div className='width-full margin-bottom-2'>
              <TanStackTableBasic data={supportingDocFiles} columns={columnDefs} placeholder={placeholder} />
            </div>
          </>
        )}
      </FormProvider>
    );
  }
);
SupportingInformation.metadata = SupportingInformationMetadata;

export default SupportingInformation;
