import { queryFromObject } from '../utils/helpers.jsx';
import statusModal from '../app-pages/Forms/components/modals/statusModal';
import { toast } from 'react-toastify';
import { tSuccess, tError } from '../utils/toast-helpers';

const dashboardBundle = {
  name: 'dashboard',

  getReducer: () => {
    const initialData = {
      submittedRequests: [],
      assignedRequests: [],
      requestsUnderAreaOfInterest: [],
      projectData: [],
      requestList: [],
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case 'UPDATE_DASHBOARD_PROJECT_DATA':
          return {
            ...state,
            projectData: payload.data,
          };
        case 'UPDATE_USER_REQUESTS':
          const vcRequests = payload.filter((project) => project.requestType === 'Violation Complaint');
          const newVcRequests = vcRequests.map((item) => ({
            ...item,
            id: item.requestID,
          }));

          const otherRequests = payload.filter((project) => project.requestType !== 'Violation Complaint');

          const requests = [];
          otherRequests.forEach((project) => {
            if (project?.preApplications?.length > 0) {
              project.preApplications.forEach((preapp) => {
                requests.push({
                  id: preapp.preAppID,
                  projectName: project.projectName,
                  requestType: project.requestType,
                  requestDate: project.requestDate,
                  requestStatus: project.requestStatus,
                  district: project.district,
                  pmName: project.pmName,
                  pmContactInfo: project.pmContactInfo,
                  pmEmail: project.pmEmail,
                  createdDate: project.createdDate,
                  modifiedDate: project.modifiedDate,
                  projectID: project.projectID,
                  requestID: project.requestID,
                  daNumber: project.daNumber,
                });
              });
            }

            if (project?.jdRequests?.length > 0) {
              project.jdRequests.forEach((jd) => {
                requests.push({
                  id: jd.jdRequestID,
                  projectName: project.projectName,
                  requestType: project.requestType,
                  requestDate: project.requestDate,
                  requestStatus: project.requestStatus,
                  district: project.district,
                  pmName: project.pmName,
                  pmContactInfo: project.pmContactInfo,
                  pmEmail: project.pmEmail,
                  createdDate: project.createdDate,
                  modifiedDate: project.modifiedDate,
                  projectID: project.projectID,
                  requestID: project.requestID,
                  daNumber: project.daNumber,
                });
              });
            }

            if (project?.permits?.length > 0) {
              project.permits.forEach((permit) => {
                requests.push({
                  id: permit.permitID,
                  projectName: project.projectName,
                  requestType: project.requestType,
                  requestDate: project.requestDate,
                  requestStatus: project.requestStatus,
                  district: project.district,
                  pmName: project.pmName,
                  pmContactInfo: project.pmContactInfo,
                  pmEmail: project.pmEmail,
                  createdDate: project.createdDate,
                  modifiedDate: project.modifiedDate,
                  projectID: project.projectID,
                  requestID: project.requestID,
                  daNumber: project.daNumber,
                });
              });
            }
          });

          return {
            ...state,
            requestList: [...requests, ...newVcRequests],
          };

        case 'UPDATE_DASHBOARD_SUBMITTED_REQUESTS':
          return {
            ...state,
            submittedRequests: payload,
          };
        case 'UPDATE_DASHBOARD_ASSIGNED_REQUESTS':
          return {
            ...state,
            assignedRequests: payload,
          };
        case 'UPDATE_DASHBOARD_REQUESTS_UNDER_AOI':
          return {
            ...state,
            requestsUnderAreaOfInterest: payload,
          };
        case 'CLEAR_DASHBOARD_PROJECT_DATA':
          return {
            ...state,
            projectData: [],
          };
        default:
          return state;
      }
    };
  },

  selectDashboard: (state) => state.dashboard,
  selectDashboardProjectData: (state) => state.dashboard.projectData,
  selectDashboardSubmittedRequests: (state) => state.dashboard.submittedRequests,
  selectDashboardAssignedRequests: (state) => state.dashboard.assignedRequests,
  selectDashboardRequestsUnderAreaOfInterest: (state) => state.dashboard.requestsUnderAreaOfInterest,
  selectUserRequestList: (state) => state.dashboard.requestList,

  doFetchProjectData:
    (params) =>
    ({ dispatch, apiGet, store }) => {
      dispatch({ type: 'CLEAR_DASHBOARD_PROJECT_DATA' });

      store.doSetLoadingMessage('Loading Projects...');
      store.doSetLoadingState(true);

      // Expecting: params = { id: val }
      const uri = `/api/Dashboard/getProject${queryFromObject(params)}`;

      apiGet(uri, (err, body) => {
        if (!err && body.status === 'Success') {
          store.doSetLoadingState(false);
          dispatch({ type: 'UPDATE_DASHBOARD_PROJECT_DATA', payload: body });
          if (body.data === null) {
            store.doSecondaryModalOpen(statusModal, {
              msg: 'No data could be found for the selected project!',
              status: 'Failed',
            });
          } else {
            store.doUpdateRelativeUrl(`/forms/${params.id}`);
          }
        } else {
          store.doSetLoadingState(false);

          dispatch({ type: 'DASHBOARD_PROJECT_DATA_FETCH_ERROR', payload: err });
          store.doSecondaryModalOpen(statusModal, {
            msg: body?.msg ?? err?.msg ?? 'An error occurred while retrieving user requests',
            status: body?.status ?? err?.status ?? 'Request Retrieval Error',
          });
        }
      });
    },

  doClearProjectData:
    () =>
    ({ dispatch }) => {
      dispatch({ type: 'CLEAR_DASHBOARD_PROJECT_DATA' });
    },

  doFetchUserRequests:
    () =>
    ({ dispatch, apiGet, store }) => {
      store.doSetLoadingMessage('Loading Projects...');
      store.doSetLoadingState(true);

      const url = '/api/Dashboard/getUserProjects';

      apiGet(url, (err, body) => {
        if (!err && body.status === 'Success') {
          store.doSetLoadingState(false);
          dispatch({ type: 'UPDATE_USER_REQUESTS', payload: body.data });
        } else if (body.status === 'Does Not Exist') {
          store.doSetLoadingState(false);
          console.warn('No Requests found for user.');
        } else {
          store.doSetLoadingState(false);
          dispatch({ type: 'USER_REQUESTS_FETCH_ERROR', payload: err });
          console.error('Error retrieving user requests');
          store.doSecondaryModalOpen(statusModal, {
            msg: body?.msg ?? err?.msg ?? 'An error occurred while retrieving user requests',
            status: body?.status ?? err?.status ?? 'Request Retrieval Error',
          });
        }
      });
    },

  doCancelProject:
    (projectID, requestID) =>
    ({ apiPut, dispatch, store }) => {
      const toastId = toast.loading('Cancelling Request...');
      const uri =
        '/api/Dashboard/cancelProject?' +
        new URLSearchParams({
          projectID: projectID,
          requestID: requestID,
        });

      return apiPut(uri, {}, (err, body) => {
        if (!err && body.status === 'Success') {
          tSuccess(toastId, 'Request successfully cancelled');
          dispatch({ type: 'PROJECT_CANCELLED_SUCCESSFULLY' });
          store.doFetchUserRequests();
        } else {
          dispatch({ type: 'PROJECT_CANCELLED_FAILED', payload: err });
          tError(toastId, `There was an error cancelling the draft request! error: ${err ? err : body.msg}`);
        }
      });
    },

  doWithdrawProject:
    (projectID, requestID) =>
    ({ apiPut, dispatch, store }) => {
      const toastId = toast.loading('Withdrawing Request...');
      const uri =
        '/api/Dashboard/withdrawProject?' +
        new URLSearchParams({
          projectId: projectID,
          requestId: requestID,
        });

      return apiPut(uri, {}, (err, body) => {
        if (!err && body.status === 'Success') {
          tSuccess(toastId, 'Request successfully withdrawn');
          dispatch({ type: 'PROJECT_WITHDRAWN_SUCCESSFULLY' });
          store.doFetchUserRequests();
        } else {
          dispatch({ type: 'PROJECT_WITHDRAW_FAILED', payload: err });
          tError(toastId, `There was an error withdrawing the request! error: ${err ? err : body.msg}`);
        }
      });
    },
};

export default dashboardBundle;
