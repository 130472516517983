import statusModal from '@src/app-pages/Forms/components/modals/statusModal';
import { getStateNameFromCode } from '@src/utils/helpers';
import { toast } from 'react-toastify';

const bundleRoot = '/api/PublicNotice/';

const filterSort = (a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

const openErrorModal = ({ body, err, store, message, status }) => {
  store.doSecondaryModalOpen(statusModal, {
    msg: body?.msg ?? err?.msg ?? message,
    status: body?.status ?? err?.status ?? status,
  });
};

const publicNoticesBundle = {
  name: 'publicNotices',

  getReducer: () => {
    const initialData = {
      attachments: [],
      comments: {},
      data: [],
      filterCounts: {
        states: [],
        districts: [],
      },
      itemsPerPage: 10,
      keyword: '',
      pageNumber: 1,
      publicNoticeById: {},
      totalCount: 0,
      view: 'table',
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case 'UPDATE_PUBLIC_NOTICES':
          return { ...state, data: payload };
        case 'UPDATE_PUBLIC_NOTICES_TOTAL_COUNT':
          return { ...state, totalCount: payload || 0 };
        case 'UPDATE_PUBLIC_NOTICES_COMMENTS':
          return { ...state, comments: payload };
        case 'UPDATE_PUBLIC_NOTICES_ATTACHMENTS':
          return { ...state, attachments: payload };
        case 'UPDATE_NOTICE_FILTER_COUNTS':
          return { ...state, filterCounts: payload };
        case 'UPDATE_PUBLIC_NOTICE_BY_ID':
          return { ...state, publicNoticeById: payload };
        case 'UPDATE_PUBLIC_NOTICES_PAGE_NUMBER':
          return { ...state, pageNumber: payload };
        case 'UPDATE_PUBLIC_NOTICES_ITEMS_PER_PAGE':
          return { ...state, itemsPerPage: payload };
        case 'UPDATE_PUBLIC_NOTICES_STATES':
          return { ...state, selectedStates: payload.sort() };
        case 'UPDATE_PUBLIC_NOTICES_DISTRICTS':
          return { ...state, selectedDistricts: payload.sort() };
        case 'UPDATE_PUBLIC_NOTICES_SEARCH_TEXT':
          return { ...state, searchText: payload };
        case 'UPDATE_PUBLIC_NOTICES_VIEW':
          return { ...state, view: payload };
        default:
          return state;
      }
    };
  },

  selectPublicNotices: (state) => state.publicNotices.data,
  selectPublicNoticesTotalCount: (state) => state.publicNotices.totalCount,
  selectPublicNoticesItemsPerPage: (state) => state.publicNotices.itemsPerPage,
  selectPublicNoticesAttachments: (state) => state.publicNotices.attachments,
  selectPublicNoticesFilterCounts: (state) => state.publicNotices.filterCounts,
  selectPublicNoticeById: (state) => state.publicNotices.publicNoticeById,
  selectPublicNoticesPageNumber: (state) => state.publicNotices.pageNumber || 10,
  selectPublicNoticesStates: (state) => state.publicNotices.selectedStates || [],
  selectPublicNoticesDistricts: (state) => state.publicNotices.selectedDistricts || [],
  selectPublicNoticesSearchText: (state) => state.publicNotices.searchText || '',
  selectPublicNoticesView: (state) => state.publicNotices.view,

  doFetchPublicNotices:
    (data) =>
    ({ dispatch, apiPost, store }) => {
      store.doSetLoadingState(true);
      store.doSetLoadingMessage('Fetching Public Notices...');

      const uri = `${bundleRoot}getPublicNotices`;

      apiPost(
        uri,
        data,
        (err, body) => {
          store.doSetLoadingState(false);
          store.doSetLoadingMessage('Loading...');

          if (!err && body?.status === 'Success') {
            dispatch({ type: 'UPDATE_PUBLIC_NOTICES', payload: body?.data?.publicNotices });
            dispatch({ type: 'UPDATE_PUBLIC_NOTICES_TOTAL_COUNT', payload: body?.data?.totalFilteredCount });
          } else {
            openErrorModal({
              body,
              err,
              store,
              message: 'An error occurred while retrieving public notices, please try again',
              status: 'Public Notices Retrieval Error',
            });
          }
        },
        true
      );
    },

  doClearPublicNotices:
    () =>
    ({ dispatch }) => {
      dispatch({ type: 'UPDATE_PUBLIC_NOTICES', payload: [] });
    },

  doFetchPublicNoticeById:
    (id) =>
    ({ dispatch, apiGet, store }) => {
      store.doSetLoadingState(true);
      store.doSetLoadingMessage('Fetching Public Notice...');

      const uri = `${bundleRoot}getPublicNotice?id=${id}`;

      apiGet(
        uri,
        (err, body) => {
          store.doSetLoadingState(false);
          store.doSetLoadingMessage('Loading...');

          if (!err && body?.status === 'Success') {
            dispatch({ type: 'UPDATE_PUBLIC_NOTICE_BY_ID', payload: body?.data });
          } else {
            openErrorModal({
              body,
              err,
              store,
              message: 'An error occurred while retrieving public notices, please try again',
              status: 'Public Notices Retrieval Error',
            });
          }
        },
        true
      );
    },

  doGetPublicNoticesFilterCounts:
    () =>
    ({ dispatch, apiPost, store }) => {
      const uri = `${bundleRoot}getPublicNoticeFilterCounts`;

      const filters = {
        states: store.selectPublicNoticesStates(),
        districtCodes: store.selectPublicNoticesDistricts(),
        searchText: store.selectPublicNoticesSearchText(),
      };

      store.doSetLoadingState(true);
      store.doGetDistrictsUnAuth();

      apiPost(
        uri,
        filters,
        (err, body) => {
          store.doSetLoadingState(false);

          if (!err && body.status === 'Success') {
            const payload = body.data;
            const districtsData = store.selectDistrictsByCode();
            const states = Object.entries(payload.stateCounts)
              .map(([code, count]) => ({ code, count, name: getStateNameFromCode(code) }))
              .sort(filterSort);
            const districts = Object.entries(payload.districtCodeCounts)
              .map(([code, count]) => ({ code, count, name: districtsData[code]?.orgName }))
              .sort(filterSort);

            dispatch({
              type: 'UPDATE_NOTICE_FILTER_COUNTS',
              payload: {
                states,
                districts,
              },
            });
          } else {
            openErrorModal({
              body,
              err,
              store,
              message: 'An error occurred while retrieving the public notice filters, please try again',
              status: 'Public Notices Retrieval Error',
            });
          }
        },
        true
      );
    },

  doSubmitPublicNoticeComments:
    (data) =>
    ({ dispatch, apiPost, store }) => {
      store.doSetLoadingState(true);
      store.doSetLoadingMessage('Submitting Public Notice Comments...');

      const uri = '/api/PublicNotice/AddPublicNoticeComment';

      apiPost(
        uri,
        data,
        (err, body) => {
          if (!err && body?.status === 'Success') {
            if (store.selectPublicNoticesAttachments()?.length > 0) {
              // Format to FormData
              const formData = new FormData();
              formData.append('publicNoticeId', data?.publicNoticeID);
              formData.append('daNumber', data?.daNumber);
              formData.append('commentId', body?.data?.publicNoticeCommentID);

              store.selectPublicNoticesAttachments()?.forEach((item) => {
                formData.append('files', item);
              });

              // Upload Files and Save Feedback
              Promise.all([store.doUploadPublicNoticeAttachments(formData)])
                .then((results) => {
                  store.doSetLoadingState(false);
                  store.doSetLoadingMessage('Loading...');

                  // if all files upload successfully
                  if (results?.[0]?.status === 'Success') {
                    dispatch({ type: 'UPDATE_PUBLIC_NOTICES_COMMENTS', payload: body?.data });
                  } else {
                    // if any of the files failed to upload
                    console.error(results);
                  }
                })
                .catch((e) => {
                  console.error(e);
                });
            } else {
              store.doSetLoadingState(false);
              store.doSetLoadingMessage('Loading...');
            }
          } else {
            store.doSetLoadingState(false);
            store.doSetLoadingMessage('Loading...');
            store.doSecondaryModalOpen(statusModal, {
              msg:
                body?.msg ??
                err?.msg ??
                'An error occurred while submitting a public notices comment, please try again',
              status: body?.status ?? err?.status ?? 'Public Notices Comments Submission Error',
            });
          }
        },
        true
      );
    },

  doSavePublicNoticesAttachments:
    (files) =>
    ({ dispatch }) => {
      dispatch({ type: 'UPDATE_PUBLIC_NOTICES_ATTACHMENTS', payload: files });
    },

  doSetPublicNoticesPageNumber:
    (pageNumber) =>
    ({ dispatch }) => {
      dispatch({ type: 'UPDATE_PUBLIC_NOTICES_PAGE_NUMBER', payload: pageNumber });
    },

  doSetPublicNoticesItemsPerPage:
    (itemsPerPage) =>
    ({ dispatch }) => {
      dispatch({ type: 'UPDATE_PUBLIC_NOTICES_ITEMS_PER_PAGE', payload: itemsPerPage });
    },

  doSetPublicNoticesStates:
    (states) =>
    ({ dispatch }) => {
      dispatch({ type: 'UPDATE_PUBLIC_NOTICES_STATES', payload: states });
    },

  doSetPublicNoticesDistricts:
    (districts) =>
    ({ dispatch }) => {
      dispatch({ type: 'UPDATE_PUBLIC_NOTICES_DISTRICTS', payload: districts });
    },

  doSetPublicNoticesView:
    (view) =>
    ({ dispatch }) => {
      dispatch({ type: 'UPDATE_PUBLIC_NOTICES_VIEW', payload: view });
    },

  doSetPublicNoticesSearchText:
    (text) =>
    ({ dispatch }) => {
      dispatch({ type: 'UPDATE_PUBLIC_NOTICES_SEARCH_TEXT', payload: text });
    },

  doExportPublicNoticesCSV:
    (fetchData) =>
    ({ dispatch, apiPost, store }) => {
      const uri = `${bundleRoot}exportPublicNotices`;

      store.doSetLoadingState(true);
      store.doSetLoadingMessage('Exporting CSV...');

      apiPost(
        uri,
        fetchData,
        (err, body) => {
          store.doSetLoadingState(false);
          store.doSetLoadingMessage('Loading...');

          if (!err && body?.status === 'Success') {
            const { fileContents, fileDownloadName } = body.data;

            const decodedContent = atob(fileContents);

            const blob = new Blob([decodedContent], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = fileDownloadName || 'PublicNotices.csv';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            URL.revokeObjectURL(url);

            toast.success('Successfully exported the CSV');
          }
        },
        true
      );
    },
};

export default publicNoticesBundle;
