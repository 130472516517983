import { useEffect, useMemo } from 'react';
import { connect } from 'redux-bundler-react';
import { toast } from 'react-toastify';

import { ModalContent, ModalFooter } from '@components/modal';
import ContactFields from '@forms/components/Form/contact-fields/ContactFields';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import { ContactTypes } from '@src/utils/enums';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import { contactInputValidation } from '@src/utils/validation/inputValidation.contact';
import { formatUSPhoneNumber } from '@src/utils/helpers';

const schema = yup.object().shape({
  ...contactInputValidation.nameRequired,
  ...contactInputValidation.addressOptional,
  ...contactInputValidation.phoneOneOptional,
  ...contactInputValidation.phoneTwoOptional,
  ...contactInputValidation.faxOptional,
  ...contactInputValidation.emailOptional,
}, [
  contactInputValidation.faxOptionalDependencies,
  contactInputValidation.zipCodeOptionalDependencies,
  ...contactInputValidation.phoneOneOptionalDependencies, // some dependencies need to be spread since they are an array of arrays
  ...contactInputValidation.phoneTwoOptionalDependencies,
  ...contactInputValidation.addressOptionalDependencies,
]);

const nameLabels = ['First Name', 'Last Name'];
const addressLabels = ['Address One', 'City', 'State', 'Zip Code', 'Country'];

const addressFieldNames = ['address', 'addressTwo', 'city', 'state', 'zipcode', 'country'];
const phoneOneFieldNames = ['phoneOneType', 'phoneOneCountryCode', 'phoneOne'];
const phoneTwoFieldNames = ['phoneTwoType', 'phoneTwoCountryCode', 'phoneTwo'];

const AddOtherPersonsModal = connect(
  'doModalClose',
  ({ doModalClose, addRowData, edit, data, setRowData, rowData, id, isReadOnly }) => {
    const defaultValues = edit ? {
      address: data?.address ?? null,
      addressTwo: data?.addressTwo ?? null,
      city: data?.city ?? null,
      company: data?.company ?? null,
      contactType: ContactTypes.Witness,
      country: data?.country ?? null,
      emailAddress: data?.emailAddress ?? null,
      faxCountryCode: data?.faxCountryCode ?? '1',
      faxPhone: data?.faxCountryCode=== '1' && data?.faxPhone ? formatUSPhoneNumber(data?.faxPhone) : data?.faxPhone ?? null,
      firstName: data?.firstName ?? null,
      lastName: data?.lastName ?? null,
      middleName: data?.middleName ?? null,
      phoneOne: data?.phoneOneCountryCode === '1' && data?.phoneOne ? formatUSPhoneNumber(data?.phoneOne) : data?.phoneOne ?? null,
      phoneOneCountryCode: data?.phoneOneCountryCode ?? '1',
      phoneOneExtension: data?.phoneOneExtension ?? null,
      phoneOneType: data?.phoneOneType ?? null,
      phoneTwo: data?.phoneTwoCountryCode === '1' && data?.phoneTwo ? formatUSPhoneNumber(data?.phoneTwo) : data?.phoneTwo ?? null,
      phoneTwoCountryCode: data?.phoneTwoCountryCode ?? null,
      phoneTwoExtension: data?.phoneTwoExtension ?? null,
      phoneTwoType: data?.phoneTwoType ?? null,
      salutation: data?.salutation ?? null,
      state: data?.state ?? null,
      zipcode: data?.zipcode ?? null,
    } : { contactType: ContactTypes.Witness, country: null };

    const methods = useForm({ resolver: yupResolver(schema), mode: 'onBlur', defaultValues: defaultValues });
    const {
      formState: { errors, isValid },
      watch,
      setFocus,
      trigger,
      getValues,
    } = methods;
   
    const addressFields = watch(addressFieldNames);
    const phoneOneFields = watch(phoneOneFieldNames);
    const phoneTwoFields = watch(phoneTwoFieldNames);

    const addressFilled = useMemo(() => addressFields.some((field) => !!field?.trim()), [addressFields]);
    const phoneOneFilled = useMemo(() => phoneOneFields.some((field) => !!field?.trim()), [phoneOneFields]);
    const phoneTwoFilled = useMemo(() => phoneTwoFields.some((field) => !!field?.trim()), [phoneTwoFields]);
    const requiredFields = useMemo(() => {
      const address = addressFilled ? addressLabels : [];
      return [...nameLabels, ...address];
    }, [addressFilled]);

    const onSave = () => {
      if (isValid) {
        const contactFields = getValues();
        const contactFieldsWithIDs = {
          ...contactFields,
          contactID: data?.contactID ?? undefined,
          contactType: ContactTypes.Witness,
          createdBy: data?.createdBy ?? undefined,
          faxPhone: contactFields?.faxPhone?.replace(/\D/g, '') ?? undefined,
          phoneOne: contactFields?.phoneOne?.replace(/\D/g, '') ?? undefined,
          phoneTwo: contactFields?.phoneTwo?.replace(/\D/g, '') ?? undefined,
          requestID: data?.requestID ?? undefined,
          version: data?.version ?? undefined,
        };
        if (edit) {
          // if contact data obj already exists in database, identify contactID, otherwise use row index
          let dataArr = [...rowData];
          const index = data.contactID ? dataArr.findIndex((el) => el.contactID === id) : id;
          dataArr[index] = contactFieldsWithIDs;
          setRowData(dataArr);
        } else {
          addRowData(contactFieldsWithIDs, setRowData);
        }
        doModalClose();
      } else {
        trigger();
        toast.error('Please fill out all required fields!');
      }
    };

    useEffect(() => {
      !addressFilled && trigger(addressFieldNames);
    }, [addressFilled, trigger]);

    useEffect(() => {
      !phoneOneFilled && trigger(phoneOneFieldNames);
    }, [phoneOneFilled, trigger]);

    useEffect(() => {
      !phoneTwoFilled && trigger(phoneTwoFieldNames);
    }, [phoneTwoFilled, trigger]);

    useEffect(() => {
      if (errors?.[Object.keys(errors)[0]]?.['ref']?.focus) {
        errors?.[Object.keys(errors)[0]]?.['ref']?.focus();
      }

      setFocus(errors?.[Object.keys(errors)[0]]?.['ref']?.['id']);
    }, [errors, setFocus]);

    return (
      <FormProvider {...methods}>
        <ModalContent hasCloseButton={isReadOnly} title='Add Contact - Additional Contacts or Witnesses'>
          {errors && <ErrorSummary errors={errors} modalID='otherContactsModal' type='modal' />}
          <section className='modal-body' id='otherContactsModal'>
            <div className='container-fluid'>
              <ContactFields
                defaultCountry={null}
                isOptional
                isReadOnly={isReadOnly}
                label='Additional Contact or Witness'
                requiredFields={requiredFields}
              />
            </div>
          </section>
          <ModalFooter
            customClosingLogic
            onSave={onSave}
            saveText={edit ? 'Apply Changes' : 'Add'}
            showCancelButton={!isReadOnly}
            showSaveButton={!isReadOnly}
          />
        </ModalContent>
      </FormProvider>
    );
  }
);

export default AddOtherPersonsModal;
