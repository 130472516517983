import React from 'react';
import { connect } from 'redux-bundler-react';
import { toast } from 'react-toastify';

import { Button } from '@trussworks/react-uswds';
import TextInput from '@components/text-input/TextInput';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

const schema = yup.object().shape({
  version: yup.string().required('Field is required'),
});

const ManageVersion = connect('doUpdateVersion', 'selectBannerData', ({ doUpdateVersion, bannerData }) => {
  const defaultValues = {
    version: bannerData?.version ?? null,
  };

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
    mode: 'onBlur',
    stateOptions: [],
  });
  const {
    formState: { isValid },
    watch,
    trigger,
  } = methods;

  const version = watch('version');

  const handleSubmit = () => {
    if (isValid) {
      doUpdateVersion(version);
    } else {
      trigger();
      toast.error('Please fill out all required fields!');
    }
  };

  return (
    <FormProvider {...methods}>
      <div>
        <TextInput name='version' label='Version' placeholder={version} required style={{ width: '200px' }} />
        <Button title='Update Version' size='small' onClick={handleSubmit} style={{ marginTop: '1rem' }}>
          Update Version
        </Button>
      </div>
    </FormProvider>
  );
});

export default ManageVersion;
