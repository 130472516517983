import { connect } from 'redux-bundler-react';

import { Grid, GridContainer } from '@trussworks/react-uswds';
import './home.scss';
import ModuleButton from '@pages/Resources/components/_shared/ModuleButton';
import NewApplicationModal from './components/modals/NewApplication';
import NotSureModal from './components/modals/NotSure';
import { ProfileRoles } from '@src/utils/enums';
import { mdiFileEditOutline, mdiFileQuestionOutline, mdiViewDashboardVariantOutline } from '@mdi/js';

const rowProps = {
  row: true,
  gap: 'md',
};

const cellProps = {
  desktop: { col: 4 },
  tablet: { col: 6 },
  style: { marginBottom: '1rem' },
};

const Home = connect(
  'doModalOpen',
  'doUpdateRelativeUrl',
  'doResetReduxFormData',
  'selectUserProfileData',
  ({ doModalOpen, doUpdateRelativeUrl, doResetReduxFormData, userProfileData }) => {
    const handleButtonClick = (modal) => {
      doResetReduxFormData();
      doModalOpen(modal);
    };

    return (
      <div className='card-menu-container'>
        <div className='hero-container requests'>
          <div className='hero-image' />
          <h1>Regulatory Request System (RRS)</h1>
          <div className='hero-description'>
            The purpose of this system to accept electronic submittals of information, Department of the Army permit
            applications, requests for jurisdictional determinations, and comments on projects from the regulated public
            and resource agencies in one portal. Applicants or agents can also check on the status of their requests.
            Currently you can only submit requests for preapplication meetings, Jurisdictional determinations,
            Nationwide Permits and view general information about the Regulatory Program.
          </div>
          <div className='hero-content'>
            <h3>Select an option below to get started: </h3>
          </div>
        </div>
        <GridContainer>
          <Grid {...rowProps}>
            <Grid {...cellProps}>
              <ModuleButton
                title='Start a New Request'
                imageAlt='Start a New Request'
                text='Create a new request for Pre-application Scoping, Jurisdictional Determination, or Nationwide Permit'
                icon={mdiFileEditOutline}
                onClick={() => handleButtonClick(NewApplicationModal)}
                isDisabled={
                  userProfileData?.role === ProfileRoles.USACERegulator ||
                  userProfileData?.role === ProfileRoles.ExternalAgency
                }
              />
            </Grid>
            <Grid {...cellProps}>
              <ModuleButton
                title={"I'm Not Sure"}
                imageAlt={"I'm Not Sure"}
                text='Answer a few quick questions to help determine which type of request to submit'
                icon={mdiFileQuestionOutline}
                onClick={() => handleButtonClick(NotSureModal)}
                isDisabled={
                  userProfileData?.role === ProfileRoles.USACERegulator ||
                  userProfileData?.role === ProfileRoles.ExternalAgency
                }
              />
            </Grid>
            <Grid {...cellProps}>
              <ModuleButton
                title='My Dashboard'
                imageAlt='My Dashboard'
                text='View Submitted/Edit Draft Requests'
                icon={mdiViewDashboardVariantOutline}
                onClick={() => doUpdateRelativeUrl('/dashboard')}
                isDisabled={
                  userProfileData?.role === ProfileRoles.USACERegulator ||
                  userProfileData?.role === ProfileRoles.ExternalAgency
                }
              />
            </Grid>
          </Grid>
        </GridContainer>
      </div>
    );
  }
);

export default Home;
