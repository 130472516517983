import { mdiChat, mdiEye } from '@mdi/js';
import { connect } from 'redux-bundler-react';

import Icon from '@components/icon/Icon';
import PublicNoticeInfoModal from '../public-notice-info/PublicNoticeInfoModal';
import PublicNoticeCommentModal from '../public-notice-comment/PublicNoticeCommentModal';
import NewTooltip from '@src/app-components/tooltip/NewTooltip';

import './actionsTableCell.scss';

const ActionsTableCell = connect('doModalOpen', ({ doModalOpen, row }) => {
  const publicNoticeID = row.original.publicNoticeID;

  const handleButtonClick = (action) => {
    if (action === 'comment') {
      doModalOpen(PublicNoticeCommentModal, {
        publicNoticeId: publicNoticeID,
      });
    } else {
      doModalOpen(PublicNoticeInfoModal, { publicNoticeId: publicNoticeID });
    }
  };

  return (
    <div className='button-container'>
      <NewTooltip
        label='View Full Public Notice'
        title='View Full Public Notice'
        className='view-notice'
        onClick={() => handleButtonClick('view')}
      >
        <Icon focusable={false} path={mdiEye} size={'16px'} />
      </NewTooltip>
      <NewTooltip
        label='Submit Comments'
        title='Submit Comments'
        className='submit-comments'
        onClick={() => handleButtonClick('comment')}
      >
        <Icon focusable={false} path={mdiChat} size={'16px'} />
      </NewTooltip>
    </div>
  );
});

export default ActionsTableCell;
