import Icon from '@components/icon/Icon';
import { mdiFileDocumentEdit } from '@mdi/js';
import { Button } from '@trussworks/react-uswds';

import '@pages/PublicNotices/public-notices-table/actionsTableCell.scss';
import { connect } from 'redux-bundler-react';
import { isUserProfileAgent } from '@src/app-pages/Forms/components/modals/_shared/UserProfileAgent';

const AgentsActionsTableCell = connect('doModalOpen','selectUserProfileData', ({ doModalOpen, userProfileData, modalComponent, row, isReadOnly, rowData, rowIndex, setRowData }) => {
  const id = row?.original?.contactID ? row.original.contactID : rowData.indexOf(row.original);

  const modalProps = {
    data: row.original,
    edit: true,
    id,
    isReadOnly,
    rowData,
    rowIndex: row.index,
    setRowData,
  };

  const handleButtonClick = () => doModalOpen(modalComponent, modalProps);

  return (
    <div className='button-container' hidden={isUserProfileAgent(row.original, userProfileData?.firstName, userProfileData?.lastName, userProfileData?.emailAddress)}>
      <Button className='edit-agent' title={'Edit Agent'} onClick={() => handleButtonClick()}>
        <Icon aria-label={'Edit Agent'} focusable={false} path={mdiFileDocumentEdit} size={'24px'}/>
      </Button>
    </div>
  );
});

export default AgentsActionsTableCell;
