import React, { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import { useFormContext } from 'react-hook-form';

import { Row } from '@pages/_shared/helper';
import Tooltip from '@components/tooltip/tooltip';
import { ContactTypes } from '@src/utils/enums';
import { Alert } from '@trussworks/react-uswds';
import Checkbox from '@components/checkbox/Checkbox';

import { aquaticResourceInventoryTooltipContent, jurisdictionalDeterminationTooltipContent, wotusDelineationReportTooltipContent } from '@pages/Resources/components/_shared/helper';

const RequestTypes = connect(
  'selectUserProfileData',
  ({ userProfileData }) => {

    const [isJDDisabled, setIsJDDisabled] = useState(false);
    const [isPreAppDisabled, setIsPreAppDisabled] = useState(false);
    const [isGPDisabled, setIsGPDisabled] = useState(false);
    const [isIPDisabled, setIsIPDisabled] = useState(false);
    const { watch } = useFormContext();

    const requestType = watch('requestType');

    useEffect(() => {
      if (Array.isArray(requestType)) {
        setIsPreAppDisabled(requestType?.filter((type) => type === '1' || type === '2' || type === '3' || type === '4')?.length > 0 ? true : false); // 5
        setIsGPDisabled(requestType?.filter((type) => type === '1' || type === '2' || type === '3' || type === '5')?.length > 0 ? true : false); // 4
        setIsJDDisabled(requestType?.filter((type) => type === '3' || type === '4' || type === '5')?.length > 0 ? true : false); // 1 and 2
        setIsIPDisabled(requestType?.filter((type) => type === '1' || type === '2' || type === '4' || type === '5')?.length > 0 ? true : false); // 3
      }
    }, [requestType]);

    //@TODO WHEN MULTIPLE SELECTIONS ALLOWS, MAKE EACH CHECKBOX NAME UNIQUE
    return (
      <>
        <div className='container-fluid'>
          <Row>
            <h4 className='border-bottom width-full padding-bottom-1 padding-top-1'>Select a Request Type</h4>
          </Row>
          {userProfileData.role === ContactTypes.Agent &&
            <Alert className='margin-top-1' type='info'>Agents - If you are selecting the "Jurisdictional Determination" option, below, and you have completed a delineation and Aquatic Resource Inventory, you must also select the "Submit a WOTUS Delineation Report and Aquatic Resource Inventory".  Note that you will be prompted to upload the Aquatic Resource Inventory at the end of this section.</Alert>
          }
          <fieldset className='margin-bottom-2 margin-left-1' id='requestType'>
            <p className='h5 border-bottom width-full margin-top-3 margin-bottom-3'>Delineation/Jurisdictional Determination</p>
            <Checkbox
              disabled={isJDDisabled}
              id='1a'
              label={<>Submit a WOTUS Delineation Report<Tooltip name='delineation-report' content={wotusDelineationReportTooltipContent} /> and Aquatic Resource Inventory<Tooltip name='ar-inventory' content={aquaticResourceInventoryTooltipContent} /></>}
              name='requestType'
              tile
              value='1'
            />
            <Checkbox
              disabled={isJDDisabled}
              id='1b'
              label={<>Request a Jurisdictional Determination<Tooltip name={'jurisdictional-determination'} content={jurisdictionalDeterminationTooltipContent} /></>}
              name='requestType'
              tile
              value='2'
            />
            <p className='h5 border-bottom width-full margin-top-3 margin-bottom-3'>Permits</p>
            <Checkbox
              disabled={isGPDisabled}
              id='2b'
              label='Nationwide Permit (ENG 6082)'
              name='requestType'
              tile
              value='4'
            />
            <Checkbox
              disabled={isIPDisabled}
              id='2a'
              label='Individual Permit (ENG 4345)'
              name='requestType'
              tile
              value='3'
            />
            <p className='h5 border-bottom width-full margin-top-3 margin-bottom-3'>Pre-application / Project Scoping</p>
            <Checkbox
              disabled={isPreAppDisabled}
              id='5'
              label='Pre-application/Project Scoping'
              name='requestType'
              tile
              value='5'
            />
          </fieldset>
        </div>
      </>);
  });

export default RequestTypes;
