import React, { useState, useEffect, useMemo } from 'react';

import { connect } from 'redux-bundler-react';

const ARTypeTableCell = connect(
  'selectLookupTableData',
  ({
    lookupTableData,
    getValue,
    row,
    column,
    table,
    cell,
  }) => {
    const columnMeta = column.columnDef.meta;
    const tableMeta = table.options.meta;
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);
    const [AROptions, setAROptions] = useState([]);
    const [jdType, setJDType] = useState();
    const [regulationType, setRegulationType] = useState();
    const rowRegulationType = useMemo(() => row.getValue('regulationType'), [row]);
    const rowJDType = useMemo(() => row.getValue('jurisdictionalDeterminationType'), [row]);

    useEffect(() => {
      if (regulationType || jdType) {
        switch (true) {
          case (regulationType === 'PRE2015_POSTSACKETT' && jdType === 'Preliminary'):
            setAROptions(() => lookupTableData.ARTypes.filter((water) => (water.regulationType === 'PRE2015_POSTSACKETT' && water.jdFormSubtype === 'PJD')));
            break;
          case (regulationType === 'PRE2015_POSTSACKETT' && jdType === 'Approved'):
            setAROptions(() => lookupTableData.ARTypes.filter((water) => (water.regulationType === 'PRE2015_POSTSACKETT' && water.jdFormSubtype === 'AJD')));
            break;
          case (regulationType === 'PRE2015_POSTSACKETT' && jdType === 'No JD Requested'):
            setAROptions(() => lookupTableData.ARTypes.filter((water) => (water.regulationType === 'PRE2015_POSTSACKETT' && water.jdFormSubtype === 'NJD')));
            break;
          case (regulationType === 'PRE2015_POSTSACKETT'):
            setAROptions(() => lookupTableData.ARTypes.filter((water) => water.regulationType === 'PRE2015_POSTSACKETT'));
            break;
          case (regulationType === 'AMENDED_2023RULE' && jdType === 'Preliminary'):
            setAROptions(() => lookupTableData.ARTypes.filter((water) => (water.regulationType === 'AMENDED_2023RULE' && water.jdFormSubtype === 'PJD')));
            break;
          case (regulationType === 'AMENDED_2023RULE' && jdType === 'Approved'):
            setAROptions(() => lookupTableData.ARTypes.filter((water) => (water.regulationType === 'AMENDED_2023RULE' && water.jdFormSubtype === 'AJD')));
            break;
          case (regulationType === 'AMENDED_2023RULE' && jdType === 'No JD Requested'):
            setAROptions(() => lookupTableData.ARTypes.filter((water) => (water.regulationType === 'AMENDED_2023RULE' && water.jdFormSubtype === 'NJD')));
            break;
          case (regulationType === 'AMENDED_2023RULE'):
            setAROptions(() => lookupTableData.ARTypes.filter((water) => water.regulationType === 'AMENDED_2023RULE'));
            break;
          default:
            break;
        }
      }
    }, [regulationType, jdType, lookupTableData.ARTypes]);

    useEffect(() => {
      rowRegulationType && setRegulationType(rowRegulationType);
    }, [rowRegulationType]);

    useEffect(() => {
      rowJDType && setJDType(rowJDType);
    }, [rowJDType]);

    useEffect(() => {
      if (AROptions.length > 1) {
        const currentOptions = AROptions.map((option) => option.shortCode);
        !currentOptions.includes(initialValue) && setValue(null);
        (initialValue !== null && !currentOptions.includes(initialValue)) && tableMeta?.updateData(row.index, column.id, null);
      }
    }, [AROptions, column.id, row.index, initialValue, tableMeta]);

    const handleBlur = (e) => {
    };

    const handleChange = (e) => {
      setValue(e.target.value);
      tableMeta?.updateData(row.index, column.id, e.target.value);
    };

    return (
      <select
        aria-label={'Aquatic Resource Type'}
        disabled={columnMeta?.readOnly}
        id={cell.id}
        onBlur={handleBlur}
        onChange={handleChange}
        required={columnMeta?.required}
        style={{ width: '100%' }}
        value={value ?? ''}>
        <option key={0} value='' style={{ display: 'none' }}>-- Select a value --</option>
        {AROptions.map((option) => (
          <option key={option.shortCode} value={option.shortCode} title={option.description}>{option.shortCode}</option>
        ))}
      </select>
    );
  }
);

export default ARTypeTableCell;