import { useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Icon from '@components/icon/Icon';
import { mdiArrowUp, mdiArrowDown, mdiPlusBox } from '@mdi/js';
import { Label, Select, Table, Pagination, Checkbox, Button } from '@trussworks/react-uswds';

import HeaderCell from '../tableCellComponents/HeaderCell/AquaticResourcesHeaderCell';
import Tooltip from '@components/tooltip/tooltip';

import './TanStackTable.scss';

const pageBreakdown = [10, 20, 30, 50];

const AquaticResourcesTanStackTable = ({
  data,
  columns,
  validationSchema,
  initialTableState,
  isReadOnly,
  updateSourceData,
  addFeature,
  removeMultipleFeatures,
  addFeaturesFromRows,
  rowErrorCallback,
  placeholder = 'NO ROWS TO DISPLAY',
}) => {
  const [rowErrors, setRowErrors] = useState();

  useEffect(() => {
    const hasErrors = (rowErrors && Object?.keys(rowErrors)?.length > 0) ?? false;
    rowErrorCallback(() => hasErrors);
  }, [rowErrorCallback, rowErrors]);

  const debounce = useCallback((func, wait) => {
    let timeout;

    const executedFunction = function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };

    executedFunction.cancel = function () {
      clearTimeout(timeout);
    };

    return executedFunction;
  }, []);

  const debouncedValidation = useCallback(
    () =>
      debounce((rows) => {
        rows.forEach(({ rowIndex, updatedRowData }) => {
          validationSchema
            .validate(updatedRowData, { abortEarly: false })
            .then(() => {
              setRowErrors((currentErrors) => {
                const newErrors = { ...currentErrors };
                delete newErrors[rowIndex];
                return newErrors;
              });
            })
            .catch((err) => {
              if (err.inner && Array.isArray(err.inner)) {
                const newRowErrors = err.inner.reduce((acc, currError) => {
                  acc[currError.path] = currError.message;
                  return acc;
                }, {});

                setRowErrors((currentErrors) => ({
                  ...currentErrors,
                  [rowIndex]: newRowErrors,
                }));
              } else {
                console.error('Validation error:', err.message);
              }
            });
        });
      }, 500),
    [validationSchema, debounce]
  );

  useEffect(() => {
    if (data) {
      const rowsToValidate = data.map((feature, index) => ({
        rowIndex: index,
        updatedRowData: feature,
      }));

      const validateRows = debouncedValidation();
      validateRows(rowsToValidate);
    }
  }, [JSON.stringify(data), debouncedValidation]); // eslint-disable-line react-hooks/exhaustive-deps

  const [sorting, setSorting] = useState([]);
  const [editedRows, setEditedRows] = useState({});

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    manualPagination: false,
    enableRowSelection: true,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    autoResetPageIndex: false,
    initialState: {
      ...initialTableState,
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
      sorting,
    },
    meta: {
      editedRows,
      setEditedRows,
      updateData: (rowIndex, columnId, value) => {
        updateSourceData(rowIndex, columnId, value);
      },
      addRow: () => {
        addFeature();
      },
      removeSelectedRows: (selectedRows) => {
        // eslint-disable-next-line no-restricted-globals
        const confirmed = confirm(
          `Are you sure you want to delete ${selectedRows?.length} rows? This action is permanent and cannot be undone!`
        );
        confirmed && removeMultipleFeatures(selectedRows);
        confirmed && table.resetRowSelection();
      },
    },
  });

  return (
    <>
      <HeaderCell table={table} addFeaturesFromRows={addFeaturesFromRows} isReadOnly={isReadOnly} isAquaticResource />
      <div style={{ width: '100%', overflowX: 'auto' }}>
        <div className='pn-table-container' style={{ width: table.getTotalSize() }}>
          <Table bordered>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={isReadOnly ? header.column.getToggleSortingHandler() : null}
                      colSpan={header.colSpan}
                      style={{
                        position: 'relative',
                        width: header.getSize() + 'px',
                        cursor: 'col-resize',
                        userSelect: header.column.getIsResizing() ? 'none' : 'auto',
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            style={{
                              cursor: header.column.getIsResizing() ? 'col-resize' : isReadOnly ? 'pointer' : 'auto',
                              position: 'relative',
                            }}
                          >
                            {/* Render "Select All" toggle for selection column */}
                            {header.id === 'edit' ? (
                              <div
                                style={{
                                  paddingBottom: '30px',
                                  paddingLeft: '8px',
                                  backgroundColor: '#eeeeee',
                                  width: header.getSize() + 'px',
                                }}
                              >
                                <Checkbox
                                  className='box-shadow-1px'
                                  name='edit'
                                  title='Select All Rows'
                                  label=''
                                  id={`${uuidv4()}_edit`}
                                  checked={table.getIsAllRowsSelected()}
                                  onChange={(e) => table.toggleAllRowsSelected(e.target.checked)}
                                />
                              </div>
                            ) : (
                              flexRender(header.column.columnDef.header, header.getContext())
                            )}

                            {/* Tooltip and other meta elements */}
                            {header.column.columnDef.meta?.tooltip && (
                              <Tooltip
                                name={header.column.columnDef.header}
                                content={header.column.columnDef.meta?.tooltip}
                              />
                            )}
                            {header.column.columnDef.meta?.required && <span className='red-asterisk'>*</span>}

                            {/* Sorting Icons */}
                            {
                              {
                                asc: <Icon path={mdiArrowUp} size={'16px'} />,
                                desc: <Icon path={mdiArrowDown} size={'16px'} />,
                              }[header.column.getIsSorted() ?? null]
                            }
                          </div>

                          {/* Add resizer element */}
                          <div
                            className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                            onMouseDown={header.getResizeHandler()}
                            onTouchStart={header.getResizeHandler()}
                            style={{ padding: '0 5px', cursor: 'col-resize' }}
                          />
                        </>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel()?.rows?.length === 0 && (
                <tr>
                  <td colSpan='100%'>
                    <div
                      className={`d-flex flex-column ${table.getTotalSize() > 1500 ? 'align-items-start' : 'align-items-center'} margin-top-1 margin-bottom-1`}
                      style={{ marginLeft: table.getTotalSize() > 1500 && '400px' }}
                    >
                      <div class='display-flex flex-column align-items-center'>
                        <p>No aquatic resources found, please click below to add an aquatic resource:</p>
                        <div className='margin-top-1'>
                          <Button className='add-button' onClick={() => addFeature()} size='small' title='Add New Row'>
                            <Icon focusable={false} className='margin-right-1' path={mdiPlusBox} size={'16px'} />
                            Add Aquatic Resource
                          </Button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              {table.getRowModel()?.rows?.map((row) => (
                <tr
                  key={row.id}
                  className={`${row.getIsSelected() ? 'selected-row' : ''} ${rowErrors && rowErrors?.[row.id] && Object.keys(rowErrors[row.id])?.length !== 0 ? 'row-error' : ''}`}
                >
                  {row.getVisibleCells().map((cell) => {
                    const cellError = rowErrors?.[row.id]?.[cell.column.id];
                    const isCellError = cellError !== undefined;
                    const cellClasses = isCellError ? 'cell-error' : '';
                    return (
                      <td className={cellClasses} key={cell.id} style={{ width: cell.column.getSize() + 'px' }}>
                        <div className='d-flex align-items-center'>
                          {cellError && (
                            <Tooltip
                              iconSize='large'
                              place='bottom'
                              header={cell.column.columnDef.header}
                              name={cell.column.id}
                              content={cellError}
                              iconStyle={{ color: 'red', marginRight: '5px' }}
                              isError
                              noDelay
                              border
                            />
                          )}
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      {table.getCoreRowModel().rows.length >= 11 && (
        <div className='pn-table-container'>
          <div className='pagination-container'>
            <div className='d-flex justify-content-center usa-prose'>
              <div className='d-flex flex-align-center'>
                <Pagination
                  className='margin-right-3 pagination-styles'
                  currentPage={table.getState().pagination.pageIndex + 1}
                  maxSlots={7}
                  totalPages={table.getPageCount()}
                  onClickNext={() => table.nextPage()}
                  onClickPageNumber={(e) => table.setPageIndex(Number(e.target.innerText) - 1)}
                  onClickPrevious={() => table.previousPage()}
                  pathname=''
                />

                <span
                  className='d-flex flex-align-center margin-right-3 rows-per-page'
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <Label htmlFor='rows_per_page' className='margin-right-1'>
                    rows per page:
                  </Label>
                  <div className='footer-select-container'>
                    <Select
                      name='rows_per_page'
                      id='rows_per_page'
                      defaultValue={10}
                      onChange={(e) => table.setPageSize(Number(e.target.value))}
                    >
                      {pageBreakdown.map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </Select>
                  </div>
                </span>

                <span className='d-flex flex-align-center'>
                  {`${table.getState().pagination.pageIndex + 1} of ${table.getPageCount()} page${table.getPageCount() !== 1 ? 's' : ''}`}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AquaticResourcesTanStackTable;
