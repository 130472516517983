import React from 'react';
import InternalLink from '@components/internal-link/InternalLink';

const NotFound = () => (
  <div className='content-container' style={{ paddingTop: '2rem' }}>
    <h2>Page Not Found</h2>
    <p>
      The page you are trying to reach has moved or does not exist.
      <br />
      <InternalLink href='/' title='Click here' content='Click here' /> to navigate back to the home page.
    </p>
  </div>
);

export default NotFound;
