import { useEffect, useState, useMemo, useCallback } from 'react';
import { connect } from 'redux-bundler-react';
import Icon from '@components/icon/Icon';
import { mdiAccountPlus, mdiCloseOctagon } from '@mdi/js';
import { createColumnHelper } from '@tanstack/react-table';
import { Button } from '@trussworks/react-uswds';

import FieldHeader from '@forms/components/Form/FieldHeader';
import ContactFields from '@forms/components/Form/contact-fields/ContactFields';
import AgentsActionsTableCell from '@src/app-components/table/tableCellComponents/AgentsActionsTableCell';
import AddAgentModal from '@forms/components/modals/AddAgentModal';
import SelectInput from '@components/select/Select';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import AgentAuthorizationUpload from '@forms/components/Form/AgentAuthorizationUpload';
import RightOfEntryUpload from '@forms/components/Form/RightOfEntryUpload';
import LinkButton from '@components/link/linkButton';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import useErrorFocus from '@hooks/useErrorFocus';
import {
  ContactTypes,
  ContactsFormNames,
  ErrorMessages,
  FileTypes,
  ProfileRoles,
  ResourceFiles,
} from '@src/utils/enums';
import {
  addUserProfileAgent,
  updateUserProfileAgent,
  userProfileAgentUsed,
  updateGPAgentsValue,
} from '@forms/components/modals/_shared/UserProfileAgent';
import { stripUncompletedContacts, formatUSPhoneNumber } from '@src/utils/helpers';
import TanStackTableBasic from '@src/app-components/table/TanStackTable/TanStackTableBasic';

import '@styles/index.scss';
import { contactInputValidation } from '@src/utils/validation/inputValidation.contact';

export const PermitsFormContactsMetadata = {
  sectionName: 'Contact Information',
  isSection: true,
  lastSection: false,
  firstSection: false,
};

const PermitsFormContacts = connect(
  'doDownloadFile',
  'doResetFileSection',
  'doClearContactType',
  'doUpdateSectionValidity',
  'doModalOpen',
  'doUpdateRequestContacts',
  'doUpdatePermitRequest',
  'doSaveTable',
  'selectTableData',
  'selectRequestAPIData',
  'selectAgentAuthorizationFile',
  'selectUserProfileData',
  'selectSteps',
  'selectActiveStep',
  'selectIsReadOnly',
  ({
    doDownloadFile,
    doResetFileSection,
    doClearContactType,
    doUpdateSectionValidity,
    doModalOpen,
    doUpdateRequestContacts,
    doUpdatePermitRequest,
    doSaveTable,
    tableData,
    requestAPIData,
    agentAuthorizationFile,
    userProfileData,
    steps,
    activeStep,
    isReadOnly,
    componentID,
    edit,
    stepNo,
  }) => {
    const [rowData, setRowData] = useState([]);
    const [isUserProfileAgentUsed, setIsUserProfileAgentUsed] = useState(false);
    const applicantContact = useMemo(() => {
      const contacts = requestAPIData?.request?.contacts || [];
      return contacts.find((contact) => contact.contactType === ContactTypes.Applicant);
    }, [requestAPIData?.request?.contacts]);
    const hasHiredAgent = requestAPIData?.request?.permits?.[0]?.hasHiredAgent
      ? 'true'
      : userProfileData.role === ContactTypes.Agent
        ? 'true'
        : 'false';
    const agentsTableLength = rowData?.length;

    const schema = (agentsTableLength) =>
      yup.object().shape(
        {
          ...contactInputValidation.nameRequired,
          ...contactInputValidation.addressRequired,
          ...contactInputValidation.phoneRequired,
          ...contactInputValidation.faxOptional,
          ...contactInputValidation.emailRequired,
          agents: yup.string().required(ErrorMessages.SelectOption),
          agentsTable: yup.boolean().when('agents', {
            is: (agents) => agents === 'true' && agentsTableLength < 1,
            then: (schema) => schema.required('Agents: At least one agent is required'),
          }),
          agentAuth: yup
            .string()
            .when('agents', {
              is: 'true',
              then: (schema) =>
                schema
                  .required('Please provide an Agent Authorization Form')
                  .test('file-invalid-test', 'File selected is invalid', function (value) {
                    return value !== 'error';
                  }),
            })
            .nullable(),
          agentAuthComments: yup.string().when('agents', { is: 'true', then: () => yup.string().nullable() }),
          rightOfEntryFile: yup
            .string()
            .test('right-of-entry-file-invalid-test', 'Right-of-Entry: File selected is invalid', function (value) {
              return value !== 'error';
            }),
          ownerEntryComments: yup.string().nullable(),
        },
        [contactInputValidation.faxOptionalDependencies]
      );

    const defaultValues = {
      salutation: applicantContact?.salutation ?? '',
      firstName: applicantContact?.firstName ?? '',
      middleName: applicantContact?.middleName ?? '',
      lastName: applicantContact?.lastName ?? '',
      address: applicantContact?.address ?? '',
      addressTwo: applicantContact?.addressTwo ?? '',
      city: applicantContact?.city ?? '',
      state: applicantContact?.state ?? '',
      zipcode: applicantContact?.zipcode ?? '',
      country: applicantContact?.country ?? 'US',
      phoneOneType: applicantContact?.phoneOneType ?? '',
      phoneOneCountryCode: applicantContact?.phoneOneCountryCode ?? '1',
      phoneOne: applicantContact?.phoneOne
        ? applicantContact?.phoneOneCountryCode === '1'
          ? formatUSPhoneNumber(applicantContact.phoneOne)
          : applicantContact?.phoneOne
        : '',
      phoneOneExtension: applicantContact?.phoneOneExtension ?? '',
      phoneTwoType: applicantContact?.phoneTwoType ?? '',
      phoneTwoCountryCode: applicantContact?.phoneTwoCountryCode ?? '1',
      phoneTwo: applicantContact?.phoneTwo
        ? applicantContact?.phoneTwoCountryCode === '1'
          ? formatUSPhoneNumber(applicantContact.phoneTwo)
          : applicantContact?.phoneTwo
        : '',
      phoneTwoExtension: applicantContact?.phoneTwoExtension ?? '',
      faxCountryCode: applicantContact?.faxCountryCode ?? '1',
      faxPhone: applicantContact?.faxPhone ?? '',
      emailAddress: applicantContact?.emailAddress ?? '',
      company: applicantContact?.company ?? '',
      agents: hasHiredAgent,
      agentAuthComments: requestAPIData?.request?.agentAuthComments ?? '',
      ownerEntryComments: requestAPIData?.request?.ownerEntryComments ?? '',
    };

    const methods = useForm({
      resolver: yupResolver(schema(agentsTableLength)),
      mode: 'onBlur',
      defaultValues: defaultValues,
    });
    const {
      formState: { isValid, errors },
      watch,
      trigger,
      getValues,
      setValue,
      setError,
    } = methods;

    const agents = watch('agents');
    const latestvalues = watch();
    const columnHelper = createColumnHelper();
    const thisSectionStepStatus = useMemo(() => steps.find((step) => step.id === stepNo)?.touched, [steps, stepNo]);

    const columnDefs = useMemo(
      () => [
        columnHelper.display({
          header: 'Actions',
          id: 'actions',
          cell: ({ row }) => (
            <AgentsActionsTableCell
              row={row}
              rowData={rowData}
              setRowData={setRowData}
              modalComponent={AddAgentModal}
              isReadOnly={isReadOnly}
            />
          ),
          size: 60,
          enableSorting: false,
          meta: {
            centerText: true,
          },
        }),
        columnHelper.accessor('firstName', {
          header: 'First Name',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('middleName', {
          header: 'Middle Name',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('lastName', {
          header: 'Last Name',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('address', {
          header: 'Address',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 500,
        }),
        columnHelper.accessor('city', {
          header: 'City',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('state', {
          header: 'State',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 75,
        }),
        columnHelper.accessor('country', {
          header: 'Country',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 75,
        }),
      ],
      [columnHelper, rowData, isReadOnly]
    );

    const clearRowData = useCallback(() => {
      setRowData([]);
      doClearContactType(ContactTypes.Agent);
      setIsUserProfileAgentUsed(false);
    }, [doClearContactType]);

    const handleAgentChange = (e) => {
      const stringToBool = e?.target?.value === 'true' ? true : false;
      doUpdatePermitRequest({ hasHiredAgent: stringToBool });
    };

    useEffect(() => {
      doUpdatePermitRequest({ hasHiredAgent: hasHiredAgent });
    }, [doUpdatePermitRequest, hasHiredAgent]);

    useEffect(() => {
      setIsUserProfileAgentUsed(
        userProfileAgentUsed(
          rowData,
          userProfileData?.firstName,
          userProfileData?.lastName,
          userProfileData?.emailAddress
        )
      );
    }, [rowData, userProfileData]);

    useEffect(() => {
      // Load tables from database
      let initialRowData = requestAPIData?.request?.contacts?.filter(
        (contact) => contact.contactType === ContactTypes.Agent
      );
      // If country is null, default to US
      initialRowData = initialRowData?.map((contact) =>
        contact.country === null ? { ...contact, country: 'US' } : contact
      );
      if (initialRowData) {
        //Update agents array if user profile agent exists
        if (
          userProfileAgentUsed(
            initialRowData,
            userProfileData?.firstName,
            userProfileData?.lastName,
            userProfileData?.emailAddress
          )
        ) {
          initialRowData = updateUserProfileAgent(initialRowData, userProfileData);
        }
        updateGPAgentsValue(userProfileData?.role, hasHiredAgent, setValue, doUpdatePermitRequest);
        setRowData(initialRowData);
      }
    }, [requestAPIData, userProfileData, setValue, setRowData, hasHiredAgent, doUpdatePermitRequest]);

    useEffect(() => {
      if (agents === 'true' && rowData?.length < 1) {
        setError('agentsTable', { type: 'custom', message: 'Agents: At least one agent is required' });
      } else {
        trigger('agentsTable');
      }
    }, [agents, trigger, rowData?.length, setError]);

    useEffect(() => {
      const agentAuthValid = agents === 'false' || (agentAuthorizationFile?.length > 0 && rowData?.length > 0);
      const valid = isValid && agentAuthValid;
      doUpdateSectionValidity(PermitsFormContactsMetadata.sectionName, valid ? true : false, stepNo, isReadOnly);
    }, [isValid, agentAuthorizationFile, agents, rowData, doUpdateSectionValidity, stepNo, isReadOnly]);

    useEffect(() => {
      const { agents, agentAuth, agentAuthComments, propertyowners, showPhoneTwo, ...applicantFields } = latestvalues;
      const applicant = {
        ...applicantFields,
        contactID: applicantContact?.contactID ?? undefined,
        requestID: applicantContact?.requestID ?? undefined,
        version: applicantContact?.version ?? undefined,
        contactType: ContactTypes.Applicant,
        createdBy: applicantContact?.createdBy ?? undefined,
        phoneOne: applicantFields?.phoneOne?.replace(/\D/g, '') ?? undefined,
        phoneTwo: applicantFields?.phoneTwo?.replace(/\D/g, '') ?? undefined,
        faxPhone: applicantFields?.faxPhone?.replace(/\D/g, '') ?? undefined,
      };
      const contacts = [applicant, ...rowData, ...tableData?.adjoiningPropOwner];
      doUpdateRequestContacts(stripUncompletedContacts(contacts));
    }, [rowData, getValues, latestvalues, doUpdateRequestContacts, applicantContact, tableData?.adjoiningPropOwner]);

    // Reset Files if section is hidden
    useEffect(() => {
      if (agents === 'false') {
        clearRowData();
        doResetFileSection(ContactsFormNames.AgentAuthorization, true);
        doResetFileSection(ContactsFormNames.RightOfEntry, true);
      }
    }, [agents, clearRowData, doResetFileSection]);

    useEffect(() => {
      rowData && doSaveTable('agents', rowData);
    }, [rowData, doSaveTable]);

    useErrorFocus({ steps, stepNo, activeStep, trigger, isReadOnly });

    const placeholder = (
      <div className='d-flex flex-column align-items-center margin-top-1 margin-bottom-1'>
        <p>No agents found, please click below to add an agent:</p>
        <div className='margin-top-1'>
          <Button
            className='add-agent-button'
            title='Add an Agent'
            size='small'
            onClick={() =>
              doModalOpen(AddAgentModal, { setRowData: setRowData, rowData: rowData, isReadOnly: isReadOnly })
            }
            disabled={isReadOnly}
          >
            <Icon focusable={false} className='margin-right-1' path={mdiAccountPlus} size={'16px'} />
            Add an Agent
          </Button>
        </div>
      </div>
    );

    return (
      <FormProvider {...methods}>
        <>
          {errors && thisSectionStepStatus === 'true' && !isReadOnly && (
            <ErrorSummary errors={errors} isValid={isValid} sectionNo={stepNo} />
          )}
          <FieldHeader
            text='Contact Information'
            subtext='Provide contact information for the applicant and the agent if applicable. The applicant is the individual
            or entity submitting the delineation report and/or requesting the
            jurisdictional determination. The agent is a third-party that has
            been retained by the applicant to act on their behalf in submitting
            this request to the U.S. Army Corps of Engineers. If you are an agent you must provide an Agent Authorization Form.
            Download an Agent Authorization form and upload the completed document in the file upload box below.'
          />
          {/* Contact Section comes first when role is not agent  */}
          {userProfileData?.role !== ProfileRoles.Agent && (
            <ContactFields
              type='applicant'
              label='Applicant'
              edit={edit}
              isReadOnly={isReadOnly}
              showButton={!isReadOnly}
            />
          )}

          <FieldHeader text='Agents' />
          <div className='ml-2 mb-2' id='agentsTable'>
            <SelectInput
              name='agents'
              label='Has the applicant hired an agent to complete the application process?'
              required
              className='w-50'
              readOnly={isReadOnly || userProfileData?.role === ContactTypes.Agent}
              onChange={handleAgentChange}
            >
              <option key='2' value={true}>
                Yes
              </option>
              <option key='3' value={false}>
                No
              </option>
            </SelectInput>
          </div>
        </>

        {agents === 'true' && (
          <>
            <div className='d-flex width-full margin-top-2 padding-bottom-2 justify-content-end'>
              {rowData.length > 0 && (
                <Button
                  className='add-agent-button'
                  title='Add an Agent'
                  size='small'
                  onClick={() =>
                    doModalOpen(AddAgentModal, { setRowData: setRowData, rowData: rowData, isReadOnly: isReadOnly })
                  }
                  disabled={isReadOnly}
                >
                  <Icon focusable={false} className='margin-right-1' path={mdiAccountPlus} size={'16px'} />
                  Add an Agent
                </Button>
              )}
              {userProfileData.role === ContactTypes.Agent && (
                <Button
                  className={`table-btn-profile ${isUserProfileAgentUsed || isReadOnly ? 'disabled' : 'hover'}`}
                  title='Use Profile Data'
                  size='small'
                  onClick={() =>
                    addUserProfileAgent(
                      userProfileData,
                      rowData,
                      setRowData,
                      isUserProfileAgentUsed,
                      setIsUserProfileAgentUsed
                    )
                  }
                  disabled={isReadOnly || isUserProfileAgentUsed}
                >
                  Use Profile Data
                </Button>
              )}
              {rowData.length !== 0 && (
                <Button
                  className={`clear-table-button ${rowData.length === 0 || isReadOnly ? 'disabled' : 'hover'}`}
                  title='Clear Agents'
                  size='small'
                  onClick={() => clearRowData('agent')}
                  disabled={rowData.length > 0 ? isReadOnly : true}
                >
                  <Icon focusable={false} className='margin-right-1' path={mdiCloseOctagon} size={'16px'} />
                  Clear Agents
                </Button>
              )}
            </div>
            <div className='width-full margin-bottom-2'>
              <TanStackTableBasic data={rowData} columns={columnDefs} placeholder={placeholder} />
            </div>
            <AgentAuthorizationUpload componentID={componentID} />
            <RightOfEntryUpload
              componentID={componentID}
              subText={
                <p>
                  Note: If a site visit is needed, you, as the agent must also provide a{' '}
                  <LinkButton
                    onClick={() => doDownloadFile(ResourceFiles.Eng6294, FileTypes.Resource)}
                    title='Right of Entry form'
                    content='Right of Entry form'
                  />
                  .
                </p>
              }
            />

            {/* Contact Section comes after Agent Section when role is agent */}
            {userProfileData.role === ProfileRoles.Agent && (
              <div className='ml-2 mb-2'>
                <ContactFields type='applicant' label='Applicant' edit={edit} isReadOnly={isReadOnly} showButton />
              </div>
            )}
          </>
        )}
      </FormProvider>
    );
  }
);
PermitsFormContacts.metadata = PermitsFormContactsMetadata;

export default PermitsFormContacts;
