import { useEffect } from 'react';
import { connect } from 'redux-bundler-react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import { ModalContent, ModalFooter } from '@components/modal';
import ContactFields from '@forms/components/Form/contact-fields/ContactFields';

import { ContactTypes, ProfileRoles } from '@src/utils/enums';
import { hasDuplicateAgent, isCurrentUser } from './_shared/UserProfileAgent';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import { contactInputValidation } from '@src/utils/validation/inputValidation.contact';
import { formatUSPhoneNumber } from '@src/utils/helpers';

const schema = yup.object().shape({
  ...contactInputValidation.nameRequired,
  ...contactInputValidation.addressRequired,
  ...contactInputValidation.phoneRequired,
  ...contactInputValidation.faxOptional,
  ...contactInputValidation.emailRequired,
}, [contactInputValidation.faxOptionalDependencies]);

const AddAgentModal = connect(
  'selectUserProfileData',
  'doModalClose',
  ({ data, doModalClose, edit, id, isReadOnly, rowData, rowIndex, setRowData, userProfileData }) => {
    const defaultValues = {
      address: data?.address ?? null,
      addressTwo: data?.addressTwo ?? null,
      city: data?.city ?? null,
      company: data?.company ?? null,
      contactType: ContactTypes.Agent,
      country: data?.country ?? 'US',
      emailAddress: data?.emailAddress ?? null,
      faxCountryCode: data?.faxCountryCode ?? '1',
      faxPhone: data?.faxCountryCode=== '1' && data?.faxPhone ? formatUSPhoneNumber(data?.faxPhone) : data?.faxPhone ?? null,
      firstName: data?.firstName ?? null,
      lastName: data?.lastName ?? null,
      middleName: data?.middleName ?? null,
      phoneOneCountryCode: data?.phoneOneCountryCode ?? '1',
      phoneOne: data?.phoneOneCountryCode === '1' && data?.phoneOne ? formatUSPhoneNumber(data?.phoneOne) : data?.phoneOne ?? '',
      phoneOneExtension: data?.phoneOneExtension ?? null,
      phoneOneType: data?.phoneOneType ?? null,
      phoneTwoCountryCode: data?.phoneTwoCountryCode ?? null,
      phoneTwo: data?.phoneTwoCountryCode === '1' && data?.phoneTwo ? formatUSPhoneNumber(data?.phoneTwo) : data?.phoneTwo ?? null,
      phoneTwoExtension: data?.phoneTwoExtension ?? null,
      phoneTwoType: data?.phoneTwoType ?? null,
      salutation: data?.salutation ?? null,
      state: data?.state ?? null,
      zipcode: data?.zipcode ?? '',
    };
    const methods = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema), mode: 'onBlur' });
    const {
      formState: { errors, isValid },
      setError,
      setFocus,
      getValues,
      trigger,
    } = methods;

    const onSave = () => {
      const contactFields = getValues();
      if (userProfileData?.role !== ProfileRoles.Agent && isCurrentUser(userProfileData, contactFields)) {
        setError('firstName', { type: 'manual', message: 'You cannot add yourself as an agent' });
        return;
      }

      if (isValid) {
        if (hasDuplicateAgent(rowData, contactFields, rowIndex)) {
          setError('firstName', { type: 'manual', message: 'Duplicate Agents are not allowed' });
          return;
        }

        const contactFieldsWithIDs = {
          ...contactFields,
          contactID: data?.contactID ?? undefined,
          contactType: ContactTypes.Agent,
          createdBy: data?.createdBy ?? undefined,
          phoneOne: contactFields?.phoneOne?.replace(/\D/g, '') ?? undefined,
          phoneTwo: contactFields?.phoneTwo?.replace(/\D/g, '') ?? undefined,
          faxPhone: contactFields?.faxPhone?.replace(/\D/g, '') ?? undefined,
          requestID: data?.requestID ?? undefined,
          version: data?.version ?? undefined,
        };

        if (edit) {
          let dataArr = [...rowData];
          const index = data.contactID ? dataArr.findIndex((el) => el.contactID === id) : id;
          dataArr[index] = contactFieldsWithIDs;
          setRowData(dataArr);
        } else {
          setRowData((rowData) => [...rowData, contactFieldsWithIDs]);
        }
        doModalClose();
      } else {
        trigger();
      }
    };

    useEffect(() => {
      errors?.[Object.keys(errors)[0]]?.['ref']?.focus?.();

      setFocus(errors?.[Object.keys(errors)[0]]?.['ref']?.['id']);
    }, [errors, setFocus]);

    return (
      <FormProvider {...methods}>
        <ModalContent hasCloseButton={isReadOnly} title={`${edit ? 'Edit' : 'Add'} Agent`}>
          {errors && <ErrorSummary errors={errors} modalID='addAgentModal' type='modal' />}
          <section className='modal-body' id='addAgentModal'>
            <div className='container-fluid'>
              <ContactFields
                edit={edit}
                isReadOnly={isReadOnly}
                label={ContactTypes.Agent}
                showButton={userProfileData.role === ContactTypes.Agent}
                type={ContactTypes.Agent}
              />
            </div>
          </section>
          <ModalFooter
            customClosingLogic
            onSave={onSave}
            saveText={edit ? 'Apply Changes' : 'Add'}
            showCancelButton={!isReadOnly}
            showSaveButton={!isReadOnly}
          />
        </ModalContent>
      </FormProvider>
    );
  }
);

export default AddAgentModal;
