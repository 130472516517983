import { toast } from 'react-toastify';
import statusModal from '@forms/components/modals/statusModal';

const bundleRoot = '/api/DeveloperAccess/';

const developerBundle = {
  name: 'developer',

  getReducer: () => {
    const initialData = {
      banner: {
        banner: '',
        isBannerOn: false,
        version: '',
        isURLReachable: '',
      },
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case 'UPDATE_GENERATED_PDF':
          return {
            ...state,
            data: payload,
          };
        case 'UPDATE_BANNER':
          return {
            ...state,
            banner: {
              ...state.banner,
              ...payload,
            },
          };
        case 'UPDATE_IS_URL_REACHABLE':
          return {
            ...state,
            isURLReachable: payload,
          };
        case 'RESET_IS_URL_REACHABLE':
          return {
            ...state,
            isURLReachable: '',
          };
        default:
          return state;
      }
    };
  },

  selectBannerData: (state) => state.developer.banner,
  selectIsURLReachable: (state) => state.developer.isURLReachable,

  doGeneratePDF:
    (projectID, requestID, version = 0) =>
    ({ apiPost, store }) => {
      store.doSetLoadingState(true);
      store.doSetLoadingMessage(
        `Generating and Uploading PDF for Project ID: ${projectID}, Request ID: ${requestID}, Version: ${version}...`
      );

      const url =
        `${bundleRoot}generateSubmittedPDF?` +
        new URLSearchParams({
          projectID,
          requestID,
          version,
        });

      return apiPost(url, {}, (err, body) => {
        if (!err && body?.status === 'Success') {
          store.doSetLoadingState(false);
          store.doSetLoadingMessage('Loading...');
          toast.success(
            `Successfully generated and uploaded PDF for Project ID: ${projectID}, Request ID: ${requestID}, Version: ${version}`
          );
        } else {
          store.doSetLoadingState(false);
          store.doSetLoadingMessage('Loading...');
          store.doSecondaryModalOpen(statusModal, {
            msg: body?.msg ?? err?.msg ?? 'An error occurred while generating and/or uploaded the Generated PDF',
            status: body?.status ?? err?.status ?? 'PDF Generation Error',
          });
        }
      });
    },

  doFetchBannerInfo:
    () =>
    ({ apiGet, dispatch }) => {
      // Returns the current text and banner current state
      const url = `${bundleRoot}getBannerInfo`;

      apiGet(
        url,
        (err, body) => {
          if (!err && body?.status === 'Success') {
            dispatch({ type: 'UPDATE_BANNER', payload: body?.data });
          } else {
            dispatch({ type: 'UPDATE_BANNER_ERROR', payload: err });
            const errorMsg = body?.msg ?? err?.msg ?? 'An error occurred while retrieving banner information';
            console.error(errorMsg);
          }
        },
        true
      );
    },

  doUpdateBannerText:
    (banner) =>
    ({ apiPut, dispatch, store }) => {
      // Called when text is updated and the user click on Save
      const url = `${bundleRoot}updateBannerText`;

      apiPut(url, banner, (err, body) => {
        if (!err && body?.status === 'Success') {
          dispatch({ type: 'UPDATE_BANNER', payload: body?.data });
          toast.success('Successfully updated banner text');
          store.doFetchBannerInfo();
        } else {
          dispatch({ type: 'UPDATE_BANNER_ERROR', payload: err });
          console.error('Error updating banner text!');
          store.doSecondaryModalOpen(statusModal, {
            msg: body?.msg ?? err?.msg ?? 'An error occurred while updating banner text',
            status: body?.status ?? err?.status ?? 'Banner Text Update Error',
          });
        }
      });
    },

  doUpdateBannerStatus:
    (isBannerOn) =>
    ({ apiPut, dispatch, store }) => {
      // Called to turn on or off the banner
      const url =
        `${bundleRoot}updateBannerStatus?` +
        new URLSearchParams({
          isBannerOn,
        });

      apiPut(url, {}, (err, body) => {
        if (!err && body?.status === 'Success') {
          dispatch({ type: 'UPDATE_BANNER', payload: body?.data });
          toast.success('Successfully updated banner status');
          store.doFetchBannerInfo();
        } else {
          dispatch({ type: 'UPDATE_BANNER_ERROR', payload: err });
          console.error('Error updating banner status!');
          store.doSecondaryModalOpen(statusModal, {
            msg: body?.msg ?? err?.msg ?? 'An error occurred while updating banner status',
            status: body?.status ?? err?.status ?? 'Banner Status Update Error',
          });
        }
      });
    },

  doUpdateVersion:
    (version) =>
    ({ apiPut, dispatch, store }) => {
      // Called to update Version number
      const url = `${bundleRoot}updateVersion`;

      apiPut(url, version, (err, body) => {
        if (!err && body?.status === 'Success') {
          dispatch({ type: 'UPDATE_BANNER', payload: body?.data });
          toast.success('Successfully updated version');
          store.doFetchBannerInfo();
        } else {
          dispatch({ type: 'UPDATE_BANNER_ERROR', payload: err });
          console.error('Error updating version!');
          store.doSecondaryModalOpen(statusModal, {
            msg: body?.msg ?? err?.msg ?? 'An error occurred while updating version',
            status: body?.status ?? err?.status ?? 'Version Update Error',
          });
        }
      });
    },

  doCheckAPI:
    (endpoint) =>
    ({ apiGet, dispatch, store }) => {
      // Called to check if endpoint is reachable.
      const url =
        `${bundleRoot}isURLReachable?` +
        new URLSearchParams({
          endpoint,
        });

      store.doSetLoadingState(true);
      store.doSetLoadingMessage('Checking API Endpoint...');

      apiGet(url, (err, body) => {
        store.doSetLoadingState(false);
        store.doSetLoadingMessage('Loading...');
        if (!err && body?.status === 'Success') {
          dispatch({ type: 'UPDATE_IS_URL_REACHABLE', payload: body?.msg });
        } else {
          dispatch({ type: 'UPDATE_IS_URL_REACHABLE_ERROR', payload: err });
          store.doSecondaryModalOpen(statusModal, {
            msg: `An error occurred while checking ${endpoint} endpoint URL`,
            status: 'Endpoint Availability Check Error',
          });
        }
      });
    },

  doResetIsURLReachable:
    () =>
    ({ dispatch }) => {
      dispatch({ type: 'RESET_IS_URL_REACHABLE' });
    },
};

export default developerBundle;
