import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import { Button , Alert } from '@trussworks/react-uswds';
import FieldHeader from '@forms/components/Form/FieldHeader';
import DatePicker from '@components/date-picker/DatePicker';
import TextInput from '@components/text-input/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { filterNullEmptyObjects } from '@src/utils/helpers';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import useErrorFocus from '@hooks/useErrorFocus';

export const ViolationsFormCertifySignSubmitMetadata = {
  sectionName: 'Certify, Sign, and Submit',
  isSection: true,
  lastSection: true,
  firstSection: false,
};

const doClearIncompletePhoneInfoVCOnly = (payload) => {
  const contacts = payload?.request?.violationComplaints?.[0]?.contacts;

  const filteredContacts = contacts?.map((contact) => {
    let updatedContact = { ...contact };
    
    // Check phoneNumberOne and remove country code if empty
    if (!contact?.phoneOne || contact?.phoneOne?.trim() === '') {
      updatedContact.phoneOneCountryCode = '';
    }
    
    // Check phoneNumberTwo and remove country code if empty
    if (!contact?.phoneTwo || contact?.phoneTwo?.trim() === '') {
      updatedContact.phoneTwoCountryCode = '';
    }
    
    // Check faxPhone and remove country code if empty
    if (!contact?.faxPhone || contact?.faxPhone?.trim() === '') {
      updatedContact.faxCountryCode = '';
    }
    
    return updatedContact;
  });

  return {
    ...payload,
    request: {
      ...payload?.request,
      violationComplaints: [
        {
          ...payload?.request?.violationComplaints?.[0],
          contacts: filteredContacts
        }
      ]
    }
  };
};

const ViolationsFormCertifySignSubmit = connect(
  'doSubmitAnonymous',
  'doUpdateSectionValidity',
  'doUpdateRequestObject',
  'doUploadAndUpdateViolationComplaint',
  'selectIsAnonymous',
  'selectRequestAPIData',
  'selectRequestFormData',
  'selectFormValidation',
  'selectUserProfileData',
  'selectSteps',
  'selectActiveStep',
  'selectViolationComplaintErrors',
  'selectIsReadOnly',
  ({
    doSubmitAnonymous,
    doUpdateSectionValidity,
    doUpdateRequestObject,
    doUploadAndUpdateViolationComplaint,
    isAnonymous,
    requestAPIData,
    requestFormData,
    formValidation,
    userProfileData,
    steps,
    activeStep,
    violationComplaintErrors,
    isReadOnly,
    stepNo
  }) => {
    // Signature always validated against user profile name
    const getName = useCallback(() => userProfileData.middleName ? `${userProfileData.firstName} ${userProfileData.middleName} ${userProfileData.lastName}` : `${userProfileData?.firstName} ${userProfileData.lastName}`, [userProfileData?.firstName, userProfileData?.middleName, userProfileData?.lastName]);

    const defaultValues = {
      anonymous: isAnonymous,
      signature: requestAPIData?.request?.signature ?? (isAnonymous ? 'Anonymous' : null),
      signatureDate: requestAPIData?.request?.signatureDate !== null ? requestAPIData?.request?.signatureDate?.split('T')[0] : '' ?? '',
    };

    const [isSigned, setIsSigned] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const schema = yup.object().shape({
      anonymous: yup.boolean(),
      signature: yup.string().required('Field is required')
        .when('anonymous', { is: true || null, then: () => yup.string().test('isAnon', 'Signature must be "Anonymous"', (val) => val === 'Anonymous') })
        .when('anonymous', { is: false, then: () => yup.string().test('isSigned', `Signature must be ${getName()}`, (val) => val.trim() === getName()) }),
      signatureDate: yup.string().required('Please select a date'),
    });

    const methods = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema), mode: 'onBlur' });
    const { formState: { errors }, setValue, watch, handleSubmit, trigger } = methods;
    const errorCount = Object.values(errors)?.length;
    const anonymous = watch('anonymous');
    const signature = watch('signature');
    const signatureDate = watch('signatureDate');
    const dateNow = new Date().toISOString().slice(0, 10);

    const handleBlur = (e) => {
      setValue('signature', e.target.value.trim());
    };

    useEffect(() => {
      if ((anonymous && signature === 'Anonymous') || (!anonymous && signature?.trim() === getName())) {
        !signatureDate && setValue('signatureDate', new Date().toISOString().slice(0, 10));
        setIsSigned(true);
        trigger();
      } else {
        setIsSigned(false);
      }
    }, [signature, anonymous, getName, setValue, signatureDate, trigger]);

    const submitForm = (e) => {
      const requestFormDataSubmit = { ...requestFormData, request: { ...requestFormData.request, isSubmit: true }, location: { ...requestFormData.location, projectArea: !requestFormData?.location?.projectArea ? 0 : requestFormData.location.projectArea ?? 0 } };
      const cleanedPayload = doClearIncompletePhoneInfoVCOnly(requestFormDataSubmit);
      const filteredRequest = filterNullEmptyObjects(cleanedPayload);
      anonymous ? doSubmitAnonymous(filteredRequest) : doUploadAndUpdateViolationComplaint(filteredRequest, 'Submit');
    };

    useEffect(() => {
      doUpdateSectionValidity(ViolationsFormCertifySignSubmitMetadata.sectionName, (isFormValid && isSigned), stepNo, isReadOnly);
      isFormValid && isSigned && signature && signatureDate && doUpdateRequestObject({ signature: signature, signatureDate: signatureDate });
    }, [isFormValid, isSigned, signature, signatureDate, doUpdateRequestObject, doUpdateSectionValidity, stepNo, isReadOnly]);

    useEffect(() => {
      setIsFormValid(Object.values(formValidation).find((section) => section.validity === false && section.section !== 'Certify, Sign, and Submit') ? false : true);
    }, [formValidation]);


    useErrorFocus({ steps, stepNo, activeStep, trigger, isReadOnly });

    return (
      <FormProvider {...methods}>
        {/* Error Summary list for section errors */}
        {(!isFormValid || errorCount > 0) && !isReadOnly && <ErrorSummary errors={errors} type='form' />}
        {/* Error Summary list for validation errors */}
        {(Object.keys(violationComplaintErrors)?.length > 0) && <ErrorSummary errors={violationComplaintErrors} type='summary' />}
        <FieldHeader text='Certify, Sign, Submit' />
        <div className='text-bold'>
          <div className='row ml-2'>
            <p className='h6 w-100 pb-2 text-bold'>BY SUBMITTING THIS APPLICATION, I AM AGREEING TO ALL OF THE FOLLOWING:</p>
          </div>
          <ul>
            <li>I consent to conduct business electronically and understand this consent can be withdrawn at any time in writing to the local USACE Regulatory office.</li>
            <li>I certify that the information in this report is complete and accurate to the best of my knowledge.</li>
            <li>I understand that, whoever, in any matter within the jurisdiction of any department of agency of the United States, knowingly and
            willfully (1) falsifies, conceals, or covers up by any trick, scheme, or device a material fact; (2) makes any materially false, fictitious,
            or fraudulent statement or representation; or (3) makes or uses any false writing or document knowing the same to contain any
            materially false, fictitious, or fraudulent statement or entry; shall be fined not more than $10,000 or imprisoned not more than 5
            years or both. (18 USC Section 1001)</li>
            <li>I understand that by typing my name or Anonymous in the text field below that I am e-signing this application and I understand that this e-signature has the same legal status as handwritten signatures.</li>
          </ul>
        </div>
        {isFormValid || isReadOnly ?
          <>
            <p className='h6 border-bottom w-100 pb-2 b'>Signature</p>
            <div className='row'>
              <div className='col-6'>
                <TextInput name='signature' label='Signature' signature={isAnonymous ? 'Anonymous' : getName()} required readOnly={isReadOnly} onBlur={handleBlur} />
              </div>
              <div className='col-6'>
                <DatePicker min={null} max={dateNow} name='signatureDate' label='Signature Date' readOnly={isReadOnly || isAnonymous} />
              </div>
            </div>
            <p className='h6 border-bottom w-100 pb-2 b'></p>
            <div className='row'>
              <div className='col-4 mb-3'>
                <Button className='submit-request-button' title='Submit Request' disabled={!isSigned || isReadOnly} onClick={handleSubmit(submitForm)}> Submit Request </Button>
              </div>
            </div></> : <Alert slim className='mb-3' type='error' >In order to submit this form, please revisit the section(s) marked with errors, and correct any missing or invalid fields.</Alert>}
      </FormProvider>
    );
  }
);
ViolationsFormCertifySignSubmit.metadata = ViolationsFormCertifySignSubmitMetadata;

export default ViolationsFormCertifySignSubmit;
