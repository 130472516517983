import { createSelector } from 'redux-bundler';

const urlBasePath = ({ base }) => ({
  name: 'urlBasePath',

  selectRelativePathname: createSelector('selectPathname', (pathname) => pathname.replace(base, '')),

  doUpdateRelativeUrl: (url, opts) => ({ store }) => {
    store.doUpdateUrl(`${base}${url}`, opts);
  },
});

export default urlBasePath;
