const contactInfoBundle = {
  name: 'contactinfo',
  getReducer: () => {
    const getInitialState = () => ({
      value: null,
      touched: false,
      valid: false,
      error: false
    });

    const initialData = {
      applicant_salutation: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_firstName: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_middleName: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_lastName: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_company: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_address: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_addressTwo: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_city: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_state: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_zipcode: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_phoneTypeOne: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_phoneOneCountryCode: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_phoneOne: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_phoneOneExtension: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_phoneTypeTwo: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_phoneTwoCountryCode: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_phoneTwo: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_phoneTwoExtension: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_faxPhone: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_emailAddress: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      applicant_parcels: {
        value: [],
        touched: false,
        valid: false,
        error: false
      },
      agent_salutation: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      agent_firstName: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      agent_middleName: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      agent_lastName: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      agent_company: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      agent_address: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      agent_addressTwo: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      agent_city: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      agent_state: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      agent_zipcode: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      agent_phoneTypeOne: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      agent_phoneOneCountryCode: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      agent_phoneOne: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      agent_phoneOneExtension: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      agent_phoneTypeTwo: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      agent_phoneTwoCountryCode: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      agent_phoneTwo: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      agent_phoneTwoExtension: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      agent_faxPhone: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      agent_emailAddress: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_salutation: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_firstName: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_middleName: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_lastName: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_company: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_address: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_addressTwo: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_city: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_state: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_zipcode: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_phoneTypeOne: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_phoneOneCountryCode: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_phoneOne: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_phoneOneExtension: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_phoneTypeTwo: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_phoneTwoCountryCode: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_phoneTwo: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_phoneTwoExtension: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_faxPhone: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_emailAddress: {
        value: null,
        touched: false,
        valid: false,
        error: false
      },
      propertyowner_parcels: {
        value: [],
        touched: false,
        valid: false,
        error: false
      },
    };

    return (state = initialData, action) => {

      switch (action.type) {
        case 'SET_STATE':
          return Object.assign(...state, action.state);

        case 'RESET_CONTACT_INFO_STATE': { return initialData; }

        case 'RESET_PROP_OWNER_CONTACT_INFO_STATE': {
          const propertyOwnerKeys = Object.keys(state).filter((key) => key.startsWith('propertyowner_'));
          const newPropertyOwnerState = propertyOwnerKeys.reduce((result, key) => {
            result[key] = getInitialState();
            return result;
          }, {});
          return {
            ...state,
            ...newPropertyOwnerState
          };
        }

        case 'RESET_ADJOINING_PROP_OWNER_CONTACT_INFO_STATE': {
          const propertyOwnerKeys = Object.keys(state).filter((key) => key.startsWith('adjoiningpropertyowner_'));
          const newPropertyOwnerState = propertyOwnerKeys.reduce((result, key) => {
            result[key] = getInitialState();
            return result;
          }, {});
          return {
            ...state,
            ...newPropertyOwnerState
          };
        }

        case 'UPDATE_FIELD':
          return {
            ...state,
            [action.field]: {
              ...state[action.field],
              value: action.value,
              touched: true,
            }
          };
        case 'SET_TOUCHED':
          return {
            ...state,
            [action.field]: {
              ...state[action.field],
              touched: true
            }
          };
        case 'SET_VALIDITY':
          return {
            ...state,
            [action.field]: {
              ...state[action.field],
              valid: action.valid
            }
          };
        case 'SET_ERROR':
          return {
            ...state,
            [action.field]: {
              ...state[action.field],
              error: action.error
            }
          };
        default:
          return state;
      }

    };
  },
  doSetField: (field, value) => ({ dispatch }) => {
    dispatch({
      type: 'UPDATE_FIELD',
      field,
      value
    });
  },
  doSetFieldTouched: (field) => ({ dispatch }) => {
    dispatch({
      type: 'SET_TOUCHED',
      field
    });
  },
  doSetFieldValidity: (field, valid) => ({ dispatch }) => {
    dispatch({
      type: 'SET_VALIDITY',
      field,
      valid
    });
  },
  doSetFieldError: (field, error) => ({ dispatch }) => {
    dispatch({
      type: 'SET_ERROR',
      field,
      error
    });
  },
  doSetContactInfoState: (state) => ({ dispatch }) => {
    dispatch({
      type: 'SET_STATE',
      state
    });
  },
  doResetContactInfoState: () => ({ dispatch }) => {
    dispatch({
      type: 'RESET_CONTACT_INFO_STATE'
    });
  },
  doResetPropOwnerContactInfoState: () => ({ dispatch }) => {
    dispatch({
      type: 'RESET_PROP_OWNER_CONTACT_INFO_STATE'
    });
  },
  doResetAdjoiningPropOwnerContactInfoState: () => ({ dispatch }) => {
    dispatch({
      type: 'RESET_ADJOINING_PROP_OWNER_CONTACT_INFO_STATE'
    });
  },
  selectApplicantInfo: (state) => Object.keys(state.contactinfo).filter((key) => key.includes('applicant')).reduce((field, key) => { field[key] = state.contactinfo[key]; return field; }, {}),
  selectAgentInfo: (state) => Object.keys(state.contactinfo).filter((key) => key.includes('agent')).reduce((field, key) => { field[key] = state.contactinfo[key]; return field; }, {}),
  selectPropOwnerInfo: (state) => Object.keys(state.contactinfo).filter((key) => key.includes('propertyowner')).reduce((field, key) => { field[key] = state.contactinfo[key]; return field; }, {}),
  selectAdjoiningPropOwnerInfo: (state) => Object.keys(state.contactinfo).filter((key) => key.includes('adjoiningpropertyowner')).reduce((field, key) => { field[key] = state.contactinfo[key]; return field; }, {}),
  selectContactInfo: (state) => state.contactinfo,
  selectContactInfoData: (state) => state.contactinfo.value

};

export default contactInfoBundle;
