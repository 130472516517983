import Card from '@components/card/card';

import './FieldHeader.scss';

const FieldHeader = ({ text, subtext, children }) => (
  <div className='field-header-container'>
    <div className='field-header'>
      <h3>{text}</h3>
    </div>
    {(subtext || children) && (
      <Card>
        <Card.Body>
          {subtext}
          {children}
        </Card.Body>
      </Card>
    )}
  </div>
);

export default FieldHeader;
