import { convertGeoJsonToEsri, fetchWithTimeout } from '@src/utils/helpers';

const currentEnv = import.meta.env.VITE_ENVIRONMENT || 'development';

const aorApiBundle = {
  name: 'aorapi',
  getReducer: () => {
    const initialData = [];
    return (state = initialData) => state;
  },
  doValidateGeometryInValidDistrict:
    (geometry) =>
    async ({ store }) => {
      store.doSetLoadingMessage('Validating Geometry Location...');
      store.doSetLoadingState(true);
      const esriGeometry = convertGeoJsonToEsri(geometry);

      const type = (() => {
        switch (geometry?.type?.toUpperCase()) {
          case 'POINT':
            return 'esriGeometryPoint';
          case 'MULTIPOINT':
            return 'esriGeometryMultipoint';
          case 'LINESTRING':
            return 'esriGeometryPolyline';
          case 'POLYGON':
            return 'esriGeometryPolygon';
          default:
            return undefined;
        }
      })();

      const url =
        currentEnv !== 'local'
          ? 'https://geospatial.sec.usace.army.mil/server/rest/services/Regulatory/USACE_Regulatory_Areas_of_Responsibility_Public/FeatureServer/1/query'
          : '/AORService';

      try {
        const response = await fetchWithTimeout(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            f: 'json',
            where: '1=1',
            geometry: JSON.stringify(esriGeometry),
            geometryType: type,
            spatialRel: 'esriSpatialRelWithin',
            outFields: 'OBJECTID',
            returnGeometry: false,
            returnDistinctValues: true,
            inSR: 4326,
          }).toString(),
        });

        if (!response.ok) {
          throw new Error(`Service returned status ${response.status}`);
        }

        const data = await response.json();

        store.doSetLoadingState(false);

        if (data?.error) {
          console.error('Error validating geometry location, trying backup endpoint:', data?.error);
          return store.doValidateGeometryInValidDistrictBackup(geometry);
        } else {
          return data?.fields?.length > 0;
        }
      } catch (error) {
        console.error('Error validating geometry location, trying backup endpoint:', error);
        return store.doValidateGeometryInValidDistrictBackup(geometry);
      }
    },
  doValidateGeometryInValidDistrictBackup:
    (geometry) =>
    async ({ store }) => {
      const esriGeometry = convertGeoJsonToEsri(geometry);

      const type = (() => {
        switch (geometry?.type?.toUpperCase()) {
          case 'POINT':
            return 'esriGeometryPoint';
          case 'MULTIPOINT':
            return 'esriGeometryMultipoint';
          case 'LINESTRING':
            return 'esriGeometryPolyline';
          case 'POLYGON':
            return 'esriGeometryPolygon';
          default:
            return undefined;
        }
      })();

      const url =
        'https://services9.arcgis.com/TwG74uxbzhyKmMpS/arcgis/rest/services/USACE_Regulatory_Areas_of_Responsibility_Public/FeatureServer/1';

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            f: 'json',
            where: '1=1',
            geometry: JSON?.stringify(esriGeometry),
            geometryType: type,
            spatialRel: 'esriSpatialRelWithin',
            outFields: 'OBJECTID',
            returnGeometry: false,
            returnDistinctValues: true,
            inSR: 4326,
          }).toString(),
        });
        const data = await response.json();

        // Assume the ArcGIS query returns features; if any feature is found, the point is in a valid USACE AOR
        store.doSetLoadingState(false);
        return data?.fields?.length > 0;
      } catch (error) {
        store.doSetLoadingState(false);
        console.error('Error validating geometry location:', error);
        return false;
      }
    },
};
export default aorApiBundle;
