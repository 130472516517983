import { Link } from '@trussworks/react-uswds';

/**
 * Reusable link component with many options to style and transform.
 *
 * @param {string} content - link display text
 * @param {string} title - screen reader and hover text
 * @param {string} href - destination for link click
 */

const ExternalLink = ({ children, content, href, title, ...customProps }) => (
  <Link
    href={href}
    rel='noreferrer'
    tabIndex={0}
    target='_blank'
    title={title ?? undefined}
    variant='external'
    {...customProps}
  >
    {content ?? children ?? href}
  </Link>
);

export default ExternalLink;
