import { useState } from 'react';
import classnames from 'classnames';
import './toggle.scss';

const Toggle = ({ checked, onChange, onLabel = '', offLabel = '' }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleClick = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };

  const containerStyles = classnames('toggle-container', {
    'toggle-checked': isChecked,
  });

  return (
    <div className={containerStyles} onClick={handleClick}>
      {offLabel && <div className='toggle-label off-label'>{offLabel}</div>}
      <div className='toggle-bg'>
        <div className='toggle-slider' />
      </div>
      {onLabel && <div className='toggle-label on-label'>{onLabel}</div>}
    </div>
  );
};

export default Toggle;
