import { useEffect, useState, useCallback } from 'react';
import { connect } from 'redux-bundler-react';
import { Alert, Button } from '@trussworks/react-uswds';

import { ModalContent, ModalFooter } from '@components/modal';
import AreasOfInterestSelect from '../_shared/AreasOfInterest';
import AddressValidator from '../../Form/AddressValidator';
import { salutations, states, countries } from '@forms/input-forms/_helper';

import SelectInput from '@components/select/Select';
import TextInput from '@components/text-input/TextInput';
import MultiSelectInput from '@components/multi-select/multiSelectInput';
import Tooltip from '@components/tooltip/tooltip';
import PhoneInput from '@components/phone-input/PhoneInput';
import LinkButton from '@components/link/linkButton';
import AddressSuggestInput from '@components/address-suggestion/AddressSuggestInput';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import Checkbox from '@components/checkbox/Checkbox';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import countryRegionData from 'country-region-data/dist/data-umd';
import { ErrorMessages, ProfileRoles } from '@src/utils/enums';
import { emailRegex } from '@src/utils/regex';
import { formatUSPhoneNumber, mapCountryAlpha3toAlpha2 } from '@src/utils/helpers';
import { cleanProfileData, getAgencyType, getDistricts, getDivisions, rolesHelperContent } from './profileHelper';
import { profileValidation } from './inputValidation.profile';
import { districtsArr, divisions } from '../_shared/helper';
import { contactInputValidation } from '@src/utils/validation/inputValidation.contact';

const schema = yup.object().shape({
  role: yup.string().required(ErrorMessages.SelectOption).test('isExternalAgency', ErrorMessages.SelectOption, (val) => val !== ProfileRoles.ExternalAgency),
  ...profileValidation.nameRequired,
  companyName: yup.string().nullable(),
  agencyName: yup.string().when('role', {
    is: ProfileRoles.ExternalAgency,
    then: (schema) => schema.required(ErrorMessages.Required),
    otherwise: (schema) => schema.nullable()
  }),
  agencyType: yup.array().when('role', {
    is: ProfileRoles.ExternalAgency,
    then: (schema) => schema.min(1, ErrorMessages.SelectOption).required(ErrorMessages.SelectOption),
    otherwise: (schema) => schema.nullable()
  }),
  ...profileValidation.phoneRequired,
  ...contactInputValidation.faxOptional,
  employeeType: yup.string().when('role', {
    is: ProfileRoles.USACERegulator,
    then: (schema) => schema.required(ErrorMessages.SelectOption),
    otherwise: (schema) => schema.nullable()
  }),
  emailAddress: yup.string().when('role', { 
    is: (role) => role !== null && role !== '',
    then: (schema) => schema.required(ErrorMessages.Required).matches(emailRegex, ErrorMessages.Invalid),
    otherwise: (schema) => schema.nullable()
  }),
  ...profileValidation.addressRequired,
  ...profileValidation.areasOfInterestRequired,
}, [contactInputValidation.faxOptionalDependencies]);

const ProfileModal = connect(
  'doAuthLogout',
  'doResetAddressCandidatesResults',
  'doSaveUserProfile',
  'doUpdateUserProfile',
  'selectAuthData',
  'selectIsUserRoleValid',
  'selectUserProfileData',
  ({
    doAuthLogout,
    doResetAddressCandidatesResults,
    doSaveUserProfile,
    doUpdateUserProfile,
    authData,
    edit,
    isUserRoleValid,
    userProfileData,
  }) => {
    const [showPhoneTwo, setShowPhoneTwo] = useState(false);
    const [stateOptions, setStateOptions] = useState([userProfileData?.state]);
    const userEmail = userProfileData?.emailAddress ?? authData.email;
    const isUsaceEmail = /@usace.army.mil\s*$/.test(userEmail) ? true : false;

    const defaultValues = {
      role: userProfileData?.role ? (userProfileData?.role === ProfileRoles.ExternalAgency ? null : userProfileData?.role) : (isUsaceEmail === true ? ProfileRoles.USACERegulator : null),
      salutation: userProfileData?.salutation ?? null,
      firstName: userProfileData?.firstName ? userProfileData.firstName : authData.given_name,
      middleName: userProfileData?.middleName ?? null,
      lastName: userProfileData?.lastName ? userProfileData.lastName : authData.family_name,
      companyName: userProfileData?.companyName ?? null,
      agencyName: userProfileData?.agencyName ?? null,
      phoneOneType: userProfileData?.phoneOneType ?? '',
      phoneOne: userProfileData?.phoneOne ?
        (userProfileData.phoneOneCountryCode === '1' ? formatUSPhoneNumber(userProfileData.phoneOne) : userProfileData.phoneOne)
        : '',
      phoneOneCountryCode: userProfileData?.role === ProfileRoles.USACERegulator ? '' : (userProfileData?.phoneOneCountryCode ?? '1'),
      phoneOneExtension: userProfileData?.phoneOneExtension ?? '',
      phoneTwoType: userProfileData?.phoneTwoType ?? '',
      phoneTwo: userProfileData?.phoneTwo ?
        (userProfileData.phoneTwoCountryCode === '1' ? formatUSPhoneNumber(userProfileData.phoneTwo) : userProfileData.phoneTwo)
        : '',
      phoneTwoCountryCode: userProfileData?.role === ProfileRoles.USACERegulator ? '' : (userProfileData?.phoneTwoCountryCode ?? '1'),
      phoneTwoExtension: userProfileData?.phoneTwoExtension ?? '',
      faxCountryCode: userProfileData?.role === ProfileRoles.USACERegulator ? '' : (userProfileData?.faxCountryCode ?? '1'),
      faxPhone: userProfileData?.faxPhone ?
        (userProfileData.faxCountryCode === '1' ? formatUSPhoneNumber(userProfileData.faxPhone) : userProfileData.faxPhone)
        : '',
      emailAddress: userEmail ?? null,
      agencyType: getAgencyType(userProfileData?.agencyType),
      employeeType: userProfileData?.employeeType ?? null,
      address: userProfileData?.address ?? null,
      addressTwo: userProfileData?.addressTwo ?? null,
      city: userProfileData?.city ?? null,
      state: userProfileData?.state ?? null,
      zipcode: userProfileData?.zipcode ?? null,
      country: userProfileData?.role === ProfileRoles.USACERegulator ? null : (userProfileData?.country ?? 'US'),
      usaceHQ: userProfileData?.areaOfInterest?.length === (districtsArr.length + divisions.length) ? true : false,
      division: getDivisions(userProfileData?.areaOfInterest) ?? null,
      district: getDistricts(userProfileData?.areaOfInterest) ?? null,
    };

    const methods = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema), mode: 'onBlur', stateOptions: [] });
    const { formState: { errors, isValid }, setValue, setFocus, watch, getValues, trigger } = methods;

    const role = watch('role');
    const address = watch('address');
    const address2 = watch('addressTwo');
    const city = watch('city');
    const state = watch('state');
    const zipcode = watch('zipcode');
    const country = watch('country');
    const phoneOne = watch('phoneOne');
    const phoneTwoType = watch('phoneTwoType');
    const phoneTwo = watch('phoneTwo');
    const faxPhone = watch('faxPhone');

    const handleSave = () => {
      if (isValid) {
        const values = getValues();
        const agencyTypes = values?.agencyType?.map((val) => val.value) ?? [];
        const cleanValues = cleanProfileData(values);

        var mappedValues = {};
        var areasOfInterest = [];
        
        if (values.division.length < 1) {
          setValue('usaceHQ', true);
          const allDistricts = districtsArr.map((val) => val.value);
          const allDivisions = divisions.map((val) => val.value);
          areasOfInterest = allDivisions.concat(allDistricts);
        } else {
          const districts = values.district.map((val) => val.value);
          const divisions = values.division.map((val) => val.value);
          areasOfInterest = divisions.concat(districts);
        }

        mappedValues = {
          ...values,
          ...cleanValues,
          areaOfInterest: areasOfInterest,
          agencyType: agencyTypes,
          phoneOne: values?.phoneOne?.replace(/\D/g, '') ?? undefined,
          phoneTwo: values?.phoneTwo?.replace(/\D/g, '') ?? undefined,
          faxPhone: values?.faxPhone?.replace(/\D/g, '') ?? undefined,
        };

        edit ? doUpdateUserProfile(mappedValues) : doSaveUserProfile(mappedValues);
      } else {
        trigger();
      }
    };

    const handleBlur = (e) => {
      const str = e?.target?.value.trim();
      const trimmedStr = str?.replace(/^[^\p{L}\p{N}]+|[^\p{L}\p{N}]+$/gu, '');
      setValue(e?.target?.name, trimmedStr);
    };

    const handleCancel = () => {
      (!edit || userProfileData?.role === ProfileRoles.ExternalAgency) && doAuthLogout();
    };

    const regionHelper = useCallback((country) => {
      const selectedCountryData = countryRegionData?.find((regionCountry) => regionCountry.countryShortCode === country);

      let regions = [];
      if (selectedCountryData) {
        regions = selectedCountryData.regions.map((region) => ({
          value: region.shortCode ?? region.name, // Some countries do not have provided shortcodes
          text: region.name
        }));
      }

      regions.push({ value: 'Other', text: 'Other' });
      regions.push({ value: 'None', text: 'None' });

      return regions;
    }, []);

    const handleCountryChange = useCallback((selectedCountry) => {
      const regions = regionHelper(selectedCountry);
      setStateOptions(regions);

      if (selectedCountry !== country) {
        trigger('address');
        setValue('city', null, {shouldValidate: true});
        setValue('state', null, {shouldValidate: true});
        setValue('zipcode', null, {shouldValidate: true});
      }

      if (selectedCountry === 'US' && !phoneOne) {
        setValue('phoneOneCountryCode', '1', {shouldValidate: true});
      } else if (selectedCountry !== 'US' && !phoneOne) {
        setValue('phoneOneCountryCode', '', {shouldValidate: true});
      }
      
      if (selectedCountry === 'US' && !phoneTwo && showPhoneTwo) {
        setValue('phoneTwoCountryCode', '', {shouldValidate: true});
      } else if (selectedCountry !== 'US' && !phoneTwo && showPhoneTwo) {
        setValue('phoneTwoCountryCode', '', {shouldValidate: true});
      }

      if (selectedCountry === 'US' && !faxPhone) {
        setValue('faxCountryCode', '1', {shouldValidate: true});
      } else if(selectedCountry !== 'US' && !faxPhone) {
        setValue('faxCountryCode', '', {shouldValidate: true});
      }
    }, [country, phoneOne, phoneTwo, showPhoneTwo, faxPhone, regionHelper, setStateOptions, setValue, trigger]);

    const populateAddressFields = (address) => {
      const fields = address?.[0]?.candidates?.[0]?.attributes;
      const addressField = document.getElementById('address');
      const addressFieldFocused = document.activeElement === addressField;

      if (fields) {
        if (addressFieldFocused) {
          addressField.blur();
        }

        setValue('address', fields.StAddr, { shouldValidate: true });
        setValue('addressTwo', fields.SubAddr, { shouldValidate: true });
        setValue('city', fields.City, { shouldValidate: true });
        setValue('state', fields.RegionAbbr, { shouldValidate: true });
        setValue('zipcode', fields.Postal, { shouldValidate: true });
        setValue('country', mapCountryAlpha3toAlpha2(fields.Country), { shouldValidate: true });
        doResetAddressCandidatesResults();

        if (addressFieldFocused) {
          addressField.focus();
        }
      }
    };

    const togglePhone = () => {
      if (showPhoneTwo) {
        setShowPhoneTwo(false);
        setValue('showPhoneTwo', false);
        setValue('phoneTwo','', { shouldValidate: true });
        setValue('phoneTwoCountryCode', '', { shouldValidate: true });
        setValue('phoneTwoType', '', { shouldValidate: true });
        setValue('phoneTwoExtension', '', { shouldValidate: true });
      } else {
        setShowPhoneTwo(true);
        setValue('showPhoneTwo', true, {shouldValidate: true});
        setValue('phoneTwoCountryCode', country === 'US' ? '1' : '', {shouldValidate: true});     
      }
    };

    useEffect(() => {
      setFocus(errors?.[Object.keys(errors)[0]]?.['ref']?.['id']);
    }, [errors, setFocus]);

    useEffect(() => {
      edit && trigger();
    }, [edit, trigger]);

    // Update stateOptions
    useEffect(() => {
      const regions = regionHelper(country);
      setStateOptions(regions);
    }, [country, regionHelper, setStateOptions]);

    // Load state value from DB
    useEffect(() => {
      const matchingOption = stateOptions?.find((option) => option?.value === userProfileData?.state);
      if (matchingOption) {
        setValue('state', matchingOption?.value);
      }
    }, [userProfileData?.state, stateOptions, setValue]);

    // Set showPhoneTwo
    useEffect(() => {
      if ((phoneTwoType || phoneTwo) && !showPhoneTwo) {
        setShowPhoneTwo(true);
        setValue('showPhoneTwo', true);
      }
    }, [phoneTwoType, phoneTwo, showPhoneTwo, setValue]);

    return (
      <ModalContent isLogout={!isUserRoleValid} title='User Profile'>
        <FormProvider {...methods}>
          {errors && <ErrorSummary errors={errors} modalID='profileModal' type='modal' />}
          <section className='modal-body' id='profileModal'>
            <div className='container-fluid'>
              <p>Tell us a little about yourself by completing the fields below.</p>
              <p><span className='text-bold'>Note:</span> You will not be able to access the RRS application until you complete this form.</p>
              <div className='row'>
                <div className='col-4'>
                  <SelectInput name='role' label='Role' required tooltip={rolesHelperContent}>
                    {(isUsaceEmail === true) ?
                      <option key='2' value={ProfileRoles.USACERegulator}>{ProfileRoles.USACERegulator}</option>
                      : <>
                        <option key='2' value={ProfileRoles.Applicant}>{ProfileRoles.Applicant}</option>
                        <option key='3' value={ProfileRoles.Agent}>{ProfileRoles.Agent}</option>
                      </>}
                  </SelectInput>
                </div>
              </div>

              {(role || userProfileData?.role === ProfileRoles.ExternalAgency) && (
                <>
                  <p className='h6 border-bottom width-full padding-bottom-1 margin-top-2'>Name</p>
                  <Alert type='warning' slim>
                    If you wish to update your first name, last name, or email in your profile, please contact the RRS help desk at <LinkButton onClick={(e) => { window.location.href = 'mailto:rrs@usace.army.mil?subject=RRS%20Support%20Ticket'; e?.preventDefault(); }} title='rrs@usace.army.mil' content='rrs@usace.army.mil' /> for assistance.
                  </Alert>
                  <div className='row'>
                    <div className='col-4'>
                      <SelectInput name='salutation' label='Salutation' required>
                        {salutations.map((item, i) => (<option key={i + 2} value={item?.value}>{item?.text}</option>))}
                      </SelectInput>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-4'>
                      <TextInput name='firstName' label='First Name' type='text' required readOnly />
                    </div>
                    <div className='col-4'>
                      <TextInput name='middleName' label='Middle Name' type='text' onBlur={handleBlur} />
                    </div>
                    <div className='col-4'>
                      <TextInput name='lastName' label='Last Name' type='text' required readOnly />
                    </div>
                  </div>
                  <p className='h6 border-bottom width-full padding-bottom-1 margin-top-2'>{role === ProfileRoles.ExternalAgency ? 'Agency' : role === ProfileRoles.USACERegulator ? 'Employee' : 'Company'} Information</p>
                  {role === ProfileRoles.ExternalAgency && (
                    <div className='row'>
                      <div className='col'>
                        <MultiSelectInput name='agencyType' label='Agency Type' className='mb-3' required={role === ProfileRoles.ExternalAgency}
                          options={[{ value: 'Commenting Agency', label: 'Commenting Agency' }, { value: 'Consulting Agency', label: 'Consulting Agency' }, { value: 'Certifying Agency', label: 'Certifying Agency' }]}
                          multi />
                      </div>
                    </div>
                  )}
                  {role !== ProfileRoles.USACERegulator && (
                    <>
                      <div className='row'>
                        <div className='col'>
                          <TextInput name={role === ProfileRoles.ExternalAgency ? 'agencyName' : 'companyName'} label={role === ProfileRoles.ExternalAgency ? 'Agency Name' : 'Company Name'} type='text' required={role === ProfileRoles.ExternalAgency} onBlur={handleBlur} />
                        </div>
                      </div>
                    </>
                  )}
                  {role !== ProfileRoles.USACERegulator && (
                    <>
                      <div className='phone-container phone1 margin-left-1'>
                        <PhoneInput extensionName='phoneOneExtension' label='Phone Number' phoneName='phoneOne' phoneTypeName='phoneOneType' phoneCountryCodeName='phoneOneCountryCode' required showTypeSelect />
                        {!showPhoneTwo && (
                          <div className='phone-button-container'>
                            <Button className='button-small add-phone-button' size='small' title='Add Phone Number' id='phone-toggle-1' name='phoneToggleButton1' onClick={togglePhone} onKeyUp={(e) => { e.key === 'Enter' && togglePhone(); }}>Add</Button>
                          </div>
                        )}
                      </div>
                      {showPhoneTwo && (
                        <div className='phone-container phone2 margin-left-1'>
                          <PhoneInput extensionName='phoneTwoExtension' phoneName='phoneTwo' phoneCountryCodeName='phoneTwoCountryCode' phoneTypeName='phoneTwoType' required showTypeSelect />
                          <div className='phone-button-container'>
                            <Button className='button-small' title='Remove Phone Number' id='phone-toggle-2' name='phoneToggleButton2' onClick={togglePhone} secondary onKeyUp={(e) => { e.key === 'Enter' && togglePhone(); }} >Remove</Button>
                          </div>
                        </div>
                      )}
                      <div className='phone-container margin-left-1'>
                        <PhoneInput phoneName='faxPhone' phoneCountryCodeName='faxCountryCode' label='Fax Number' />
                      </div>
                    </>
                  )}
                  <div className='row'>
                    {role === ProfileRoles.USACERegulator && (
                      <div className='col-6'>
                        <SelectInput name='employeeType' label='Employee Type' required className='mb-3' options={['Request Manager', 'Project Manager', 'Supervisor', 'Peer Reviewer/Team Lead', 'Division', 'Headquarters']} />
                      </div>
                    )}
                    <div className='col-6'>
                      <TextInput name='emailAddress' label='Email' type='email' maxLength={256} required readOnly onBlur={handleBlur} />
                    </div>
                  </div>
                  {role !== ProfileRoles.USACERegulator && (
                    <>
                      <div className='row'>
                        <div className='col-12 margin-top-1'>
                          <AddressSuggestInput id='address' name='address' label='Address' required usOnly={false} onBlur={handleBlur} populateAddressFields={populateAddressFields} />
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-12'>
                          <TextInput name='addressTwo' label='Address Line 2' type='text' onBlur={handleBlur} />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col'>
                          <SelectInput name='country' label='Country' required onChange={(e) => handleCountryChange(e.target.value)}>
                            {countries.map((item, i) => (<option key={i + 2} value={item?.value}>{item?.text}</option>))}
                          </SelectInput>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-4'>
                          <TextInput name='city' label='City' type='text' maxLength={26} required onBlur={handleBlur} />
                        </div>
                        {country === 'US' ? (
                          <div className='col-4'>
                            <SelectInput name='state' label='State' required>
                              {states?.map((item, i) => (<option key={i + 2} value={item?.value}>{item?.text}</option>))}
                            </SelectInput>
                          </div>
                        ) : (
                          <div className='col-4'>
                            <SelectInput name='state' label='Region' required>
                              {stateOptions?.map((item, i) => (<option key={i + 2} value={item?.value}>{item?.text}</option>))}
                            </SelectInput>
                          </div>
                        )}
                        <div className='col-4'>
                          <TextInput type='tel' inputMode={'numeric'} name='zipcode' label={country === 'US' ? 'Zip Code' : 'Postal code'} required={country === 'US'} maxLength={country === 'US' ? 5 : 10} onBlur={handleBlur}/>
                        </div>
                      </div>
                      <div className='margin-top-1'>
                        <AddressValidator source='modal' address={{ address1: address, address2: address2, city: city, state: state, zipcode: zipcode }} />
                      </div>
                    </>
                  )}
                  {(role === ProfileRoles.ExternalAgency || role === ProfileRoles.USACERegulator) && (
                    <>
                      <h6 className='border-bottom width-full padding-bottom-1'>Areas of Interest <Tooltip name='AOI' content={<p><span className='text-bold'>Note:</span> If you do not specify the division/district that you are affiliated with, you will automatically be aligned under USACE HQ as your area of interest.</p>} /> </h6>
                      <div className='row'>
                        <fieldset className='width-full padding-left-4 margin-bottom-0 col-6' id='usaceHQ'>
                          <Checkbox id='usace-hq' label='Are you with USACE Headquarters?' name='usaceHQ' />
                        </fieldset>
                      </div>
                      <AreasOfInterestSelect divisionList={getDivisions(userProfileData?.areaOfInterest)} districtList={getDistricts(userProfileData?.areaOfInterest)} />
                    </>
                  )}
                </>
              )
              }
            </div >
          </section >
        </FormProvider >
        <ModalFooter
          customClosingLogic
          isDisabled={!isValid}
          onCancel={handleCancel}
          onSave={handleSave}
          saveText={edit ? 'Apply Changes' : 'Save'}
        />
      </ModalContent >
    );
  }
);

export default ProfileModal;
