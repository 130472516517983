import React, { useEffect } from 'react';

import MultiSelectInput from '@components/multi-select/multiSelectInput';

import { divisions, districts, districtsArr } from './helper';
import { Row } from '@pages/_shared/helper';

import { useFormContext } from 'react-hook-form';

const AreasOfInterestSelect = ({
  divisionList,
  disabled,
  required,
}) => {
  const { watch, setValue, trigger } = useFormContext();

  const isUSACEHQ = watch('usaceHQ');
  const selectedDivisions = watch('division');
  const selectedDistricts = watch('district');

  useEffect(() => {
    if (selectedDivisions && selectedDivisions.length !== divisionList.length) {
      const districtArray = selectedDivisions.map((div) => districts[div.value]);
      //flatten district array
      const flatDistricts = districtArray.reduce((acc, currentArray) => acc.concat(currentArray), []);
      // Function to compare if two objects are equal
      setValue('district', flatDistricts);
      trigger(['division', 'district']);
    }
  }, [selectedDivisions, divisionList.length, setValue, trigger]);

  useEffect(() => {
    if (selectedDistricts && selectedDistricts.length === 38 && selectedDivisions && selectedDivisions.length === 8) {
      setValue('usaceHQ', true);
    }
    else {
      setValue('usaceHQ', false);
    }
  }, [selectedDistricts, selectedDivisions, setValue]);

  useEffect(() => {
    if (isUSACEHQ) {
      setValue('division', divisions);
      setValue('district', districtsArr);
      trigger(['division', 'district']);
    }
    if (!isUSACEHQ) {
      trigger(['division', 'district']);
    }
  }, [isUSACEHQ, setValue, trigger]);

  const handleDivisionChange = (e) => {
    trigger(['division', 'district']);
  };

  const handleDistrictChange = (e) => {
    trigger(['division', 'district']);
  };

  return (
    <>
      <Row>
        <div className='col-12'>
          <MultiSelectInput name='division' label='Division(s)' className='mb-3' disabled={isUSACEHQ || disabled} required={required} options={divisions} multi handleChange={handleDivisionChange} />
        </div>
      </Row>
      <Row>
        <div className='col-12'>
          <MultiSelectInput name='district' label='District(s)' className='mb-3' disabled={isUSACEHQ || disabled} required={required} options={selectedDivisions.map((division) => districts[division.value]).reduce((a, b) => a.concat(b), [])} multi handleChange={handleDistrictChange} />
        </div>
      </Row>
    </>
  );
};

export default AreasOfInterestSelect;