import { connect } from 'redux-bundler-react';

import Home from './Home';
import Resources from '../Resources';

export default connect('selectIsUserRoleValid', ({ isUserRoleValid }) => {
  if (!isUserRoleValid) {
    return <Resources />;
  }

  return <Home />;
});
