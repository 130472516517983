import Card from '@components/card';
import Icon from '@components/icon/Icon';
import classnames from 'classnames';

import '../../resources.scss';

const ModuleButton = ({
  hideDetails,
  icon,
  image,
  imageAlt = '',
  isDisabled,
  isError,
  isModalButton,
  onClick = () => {},
  popupText,
  selected = false,
  subtext,
  text,
  title,
}) => {
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  const containerCss = classnames({
    'card-container': !isModalButton || !subtext,
    'homepage-disabled': isDisabled,
    'modal-card-container': isModalButton && subtext,
    'modal-card-invalid': isError,
    'module-icon-only': hideDetails,
    'module-selected': selected,
  });

  return (
    <Card className={containerCss} onClick={onClick} onKeyUp={handleKeyPress} tabIndex={0}>
      <Card.Body>
        <div className='center'>
          {image && <img src={image} alt={imageAlt} className='img-icon' />}
          {icon && <Icon focusable={false} title={text} className='img-icon' path={icon} size={3} />}
          {!hideDetails && (
            <div>
              {title && <div className='button-header'>{title}</div>}
              {popupText && <div className='button-text popup'>{popupText}</div>}
              <div>
                {text && <div className='button-text'>{text}</div>}
                {subtext && <div className='button-subtext'>{subtext}</div>}
              </div>
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ModuleButton;
