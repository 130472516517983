import React, { useEffect, useState, useCallback, useMemo } from 'react';
import useErrorFocus from '@hooks/useErrorFocus';
import { connect } from 'redux-bundler-react';
import { createColumnHelper } from '@tanstack/react-table';
import FieldHeader from '@forms/components/Form/FieldHeader';
import ContactFields from '@forms/components/Form/contact-fields/ContactFields';
import { Button } from '@trussworks/react-uswds';
import Icon from '@components/icon/Icon';
import AddOtherPersonsModal from '@forms/components/modals/AddOtherPersonsModal';
import AddAllegedViolatorModal from '@forms/components/modals/AddAllegedViolatorModal';
import SelectInput from '@components/select/Select';
import AgentsActionsTableCell from '@src/app-components/table/tableCellComponents/AgentsActionsTableCell';
import { isObjectNotDirty, formatUSPhoneNumber } from '@src/utils/helpers';
import { ContactTypes, ErrorMessages } from '@src/utils/enums';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import { mdiAccountPlus, mdiCloseOctagon } from '@mdi/js';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import { contactInputValidation } from '@src/utils/validation/inputValidation.contact';
import TanStackTableBasic from '@src/app-components/table/TanStackTable/TanStackTableBasic';

export const ViolationsFormContactsMetadata = {
  sectionName: 'Contact Information',
  isSection: true,
  lastSection: false,
  firstSection: false,
};

const ViolationsFormContacts = connect(
  'doModalOpen',
  'doUpdateSectionValidity',
  'doUpdateViolationComplaintRequestContacts',
  'doUpdateIsAllegedViolatorKnown',
  'selectUserProfileData',
  'selectIsAnonymous',
  'selectRequestAPIData',
  'selectIsNewRequest',
  'selectSteps',
  'selectActiveStep',
  'selectIsReadOnly',
  ({
    doModalOpen,
    doUpdateSectionValidity,
    doUpdateViolationComplaintRequestContacts,
    doUpdateIsAllegedViolatorKnown,
    userProfileData,
    isAnonymous,
    requestAPIData,
    steps,
    activeStep,
    isReadOnly,
    stepNo,
  }) => {
    const [otherPersonsRowData, setOtherPersonsRowData] = useState([]);
    const [allegedViolatorRowData, setAllegeViolatorRowData] = useState([]);
    const thisSectionStepStatus = useMemo(() => steps.find((step) => step.id === stepNo)?.touched, [steps, stepNo]);
    const allegedViolatorTableLength = allegedViolatorRowData?.length;

    const schema = (allegedViolatorTableLength) =>
      yup.object().shape(
        !isAnonymous
          ? {
              ...contactInputValidation.nameRequired,
              ...contactInputValidation.addressRequired,
              ...contactInputValidation.phoneRequired,
              ...contactInputValidation.faxOptional,
              ...contactInputValidation.emailRequired,
              isAllegedViolatorKnown: yup.string().required(ErrorMessages.SelectOption),
              allegedViolatorTable: yup.boolean().when('isAllegedViolatorKnown', {
                is: (val) => val === 'Yes' && allegedViolatorTableLength < 1,
                then: () => yup.boolean().required('Alleged Violator: At least one alleged violator is required'),
              }),
            }
          : {
              isAllegedViolatorKnown: yup.string().required('Is Alleged Violator Known?'),
              allegedViolatorTable: yup.boolean().when('isAllegedViolatorKnown', {
                is: (val) => val === 'Yes' && allegedViolatorTableLength < 1,
                then: () => yup.boolean().required('Alleged Violator: At least one alleged violator is required'),
              }),
            },
        [contactInputValidation.faxOptionalDependencies]
      );

    const reporterContact =
      requestAPIData?.request?.contacts?.length > 0 &&
      requestAPIData?.request?.contacts?.find((contact) => contact.contactType === ContactTypes.Reporter);
    const violationComplaint =
      requestAPIData?.request?.violationComplaints?.length > 0 && requestAPIData?.request?.violationComplaints?.[0];

    const defaultValues = {
      salutation: reporterContact?.salutation ?? null,
      firstName: reporterContact?.firstName ?? null,
      middleName: reporterContact?.middleName ?? null,
      lastName: reporterContact?.lastName ?? null,
      address: reporterContact?.address ?? null,
      addressTwo: reporterContact?.addressTwo ?? null,
      city: reporterContact?.city ?? null,
      state: reporterContact?.state ?? null,
      zipcode: reporterContact?.zipcode ?? null,
      country: reporterContact?.country ?? 'US',
      phoneOneType: reporterContact?.phoneOneType ?? null,
      phoneOneCountryCode: reporterContact?.phoneOneCountryCode ?? '1',
      phoneOne: reporterContact?.phoneOne
        ? reporterContact?.phoneOneCountryCode === '1'
          ? formatUSPhoneNumber(reporterContact.phoneOne)
          : reporterContact?.phoneOne
        : '',
      phoneOneExtension: reporterContact?.phoneOneExtension ?? null,
      phoneTwoType: reporterContact?.phoneTwoType ?? null,
      phoneTwoCountryCode: reporterContact?.phoneTwoCountryCode ?? null,
      phoneTwo: reporterContact?.phoneTwo
        ? reporterContact?.phoneTwoCountryCode === '1'
          ? formatUSPhoneNumber(reporterContact.phoneTwo)
          : reporterContact?.phoneTwo
        : '',
      phoneTwoExtension: reporterContact?.phoneTwoExtension ?? null,
      faxCountryCode: reporterContact?.faxCountryCode ?? '1',
      faxPhone: reporterContact?.faxPhone ?? null,
      emailAddress: reporterContact?.emailAddress ?? null,
      company: reporterContact?.company ?? null,
      isAllegedViolatorKnown:
        violationComplaint?.isAllegedViolatorKnown === true
          ? 'Yes'
          : violationComplaint?.isAllegedViolatorKnown === false
            ? 'No'
            : null,
    };

    const methods = useForm({
      resolver: yupResolver(schema(allegedViolatorTableLength)),
      mode: 'onBlur',
      defaultValues: defaultValues,
    });
    const {
      formState: { isValid, errors },
      watch,
      setError,
      trigger,
      getValues,
      reset,
    } = methods;
    const isAllegedViolatorKnown = watch('isAllegedViolatorKnown');

    const columnHelper = createColumnHelper();

    const OtherPersonsColumnDefs = useMemo(
      () => [
        columnHelper.display({
          header: 'Actions',
          id: 'actions',
          cell: ({ row }) => (
            <AgentsActionsTableCell
              row={row}
              rowData={otherPersonsRowData}
              setRowData={setOtherPersonsRowData}
              modalComponent={AddOtherPersonsModal}
              isReadOnly={isReadOnly}
            />
          ),
          size: 60,
          enableSorting: false,
          meta: {
            centerText: true,
          },
        }),
        columnHelper.accessor('firstName', {
          header: 'First Name',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 200,
        }),
        columnHelper.accessor('middleName', {
          header: 'Middle Name',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 200,
        }),
        columnHelper.accessor('lastName', {
          header: 'Last Name',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 200,
        }),
        columnHelper.accessor('phoneOneType', {
          header: 'Phone One Type',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('phoneOne', {
          header: 'Phone One',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('state', {
          header: 'State',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 75,
        }),
        columnHelper.accessor('country', {
          header: 'Country',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 75,
        }),
      ],
      [columnHelper, otherPersonsRowData, isReadOnly]
    );

    const AllegedViolatorColumnDefs = useMemo(
      () => [
        columnHelper.display({
          header: 'Actions',
          id: 'actions',
          cell: ({ row }) => (
            <AgentsActionsTableCell
              row={row}
              rowData={allegedViolatorRowData}
              setRowData={setAllegeViolatorRowData}
              modalComponent={AddAllegedViolatorModal}
              isReadOnly={isReadOnly}
            />
          ),
          size: 60,
          enableSorting: false,
          meta: {
            centerText: true,
          },
        }),
        columnHelper.accessor('contactType', {
          header: 'Contact Type',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('firstName', {
          header: 'First Name',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('middleName', {
          header: 'Middle Name',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('lastName', {
          header: 'Last Name',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('phoneOneType', {
          header: 'Phone One Type',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('phoneOne', {
          header: 'Phone One',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('state', {
          header: 'State',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 75,
        }),
        columnHelper.accessor('country', {
          header: 'Country',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 75,
        }),
      ],
      [columnHelper, allegedViolatorRowData, isReadOnly]
    );

    const addRowData = (data, fnc) => fnc((rowData) => [...rowData, data]);

    const clearRowData = (fnc) => fnc([]);

    useEffect(() => {
      if (isAllegedViolatorKnown === 'Yes' && allegedViolatorRowData?.length < 1) {
        setError('allegedViolatorTable', {
          type: 'custom',
          message: 'Alleged Violator: At least one alleged violator is required',
        });
      } else {
        trigger('allegedViolatorTable');
      }
    }, [isAllegedViolatorKnown, allegedViolatorRowData?.length, setError, trigger]);

    useEffect(() => {
      doUpdateSectionValidity(ViolationsFormContactsMetadata.sectionName, isValid, stepNo, isReadOnly);
    }, [isValid, isAllegedViolatorKnown, allegedViolatorRowData, doUpdateSectionValidity, stepNo, isReadOnly]);

    //Load tables from database
    useEffect(() => {
      const otherContacts = requestAPIData?.request?.violationComplaints?.[0]?.contacts?.filter(
        (contact) => contact.contactType === ContactTypes.Witness
      );
      const suspects = requestAPIData?.request?.violationComplaints?.[0]?.contacts?.filter(
        (contact) => contact.contactType !== ContactTypes.Witness && contact.contactType !== ContactTypes.Reporter
      );

      otherContacts && setOtherPersonsRowData(otherContacts);
      suspects && setAllegeViolatorRowData(suspects);
    }, [requestAPIData]);

    useEffect(() => {
      //copies of original requestAPIData creation
      const reporterContact =
        requestAPIData?.request?.contacts?.length > 0 &&
        requestAPIData?.request?.contacts?.find((contact) => contact.contactType === ContactTypes.Reporter);
      const violationComplaint =
        requestAPIData?.request?.violationComplaints?.length > 0 && requestAPIData?.request?.violationComplaints?.[0];

      // remap of default values
      const defaultValues = {
        salutation: reporterContact?.salutation ?? null,
        firstName: reporterContact?.firstName ?? null,
        middleName: reporterContact?.middleName ?? null,
        lastName: reporterContact?.lastName ?? null,
        address: reporterContact?.address ?? null,
        addressTwo: reporterContact?.addressTwo ?? null,
        city: reporterContact?.city ?? null,
        state: reporterContact?.state ?? null,
        zipcode: reporterContact?.zipcode ?? null,
        country: reporterContact?.country ?? null,
        phoneOneType: reporterContact?.phoneOneType ?? null,
        phoneOneCountryCode: reporterContact?.phoneOneCountryCode ?? '1',
        phoneOne: reporterContact?.phoneOne
          ? reporterContact.phoneOneCountryCode === '1'
            ? formatUSPhoneNumber(reporterContact?.phoneOne)
            : reporterContact?.phoneOne
          : null,
        phoneOneExtension: reporterContact?.phoneOneExtension ?? null,
        phoneTwoType: reporterContact?.phoneTwoType ?? null,
        phoneTwoCountryCode: reporterContact?.phoneTwoCountryCode ?? null,
        phoneTwo: reporterContact?.phoneTwo
          ? reporterContact.phoneTwoCountryCode === '1'
            ? formatUSPhoneNumber(reporterContact?.phoneTwo)
            : reporterContact?.phoneTwo
          : null,
        phoneTwoExtension: reporterContact?.phoneTwoExtension ?? null,
        faxCountryCode: reporterContact?.faxCountryCode ?? '1',
        faxPhone: reporterContact?.faxPhone
          ? reporterContact.faxCountryCode === '1'
            ? formatUSPhoneNumber(reporterContact?.faxPhone)
            : reporterContact?.faxPhone
          : null,
        emailAddress: reporterContact?.emailAddress ?? null,
        company: reporterContact?.company ?? null,
        isAllegedViolatorKnown:
          violationComplaint?.isAllegedViolatorKnown === true
            ? 'Yes'
            : violationComplaint?.isAllegedViolatorKnown === false
              ? 'No'
              : null,
      };
      reset(defaultValues);
      //
    }, [requestAPIData, reset]);

    useEffect(() => {
      // Format contacts array
      const latestvalues = getValues();
      const { isAllegedViolatorKnown, ...reporterFields } = latestvalues;
      const reporterFieldsWithIDs = {
        ...reporterFields,
        contactID: reporterContact?.contactID ?? undefined,
        requestID: reporterContact?.requestID ?? undefined,
        version: reporterContact?.version ?? undefined,
        contactType: ContactTypes.Reporter,
        createdBy: reporterContact?.createdBy ?? undefined,
        phoneOne: reporterFields?.phoneOne?.replace(/\D/g, '') ?? undefined,
        phoneTwo: reporterFields?.phoneTwo?.replace(/\D/g, '') ?? undefined,
        faxPhone: reporterFields?.faxPhone?.replace(/\D/g, '') ?? undefined,
      };
      const reporterObj = isAnonymous ? [] : [reporterFieldsWithIDs];
      const contactArr = [...reporterObj, ...otherPersonsRowData, ...allegedViolatorRowData];
      // Update Contacts array state value
      isObjectNotDirty(reporterFields) && doUpdateViolationComplaintRequestContacts(contactArr);
    }, [
      otherPersonsRowData,
      allegedViolatorRowData,
      getValues,
      doUpdateViolationComplaintRequestContacts,
      isAnonymous,
      reporterContact,
    ]);

    const handleFieldChange = useCallback(
      (e) => {
        // form field on change handler
        const latestvalues = getValues();
        const { isAllegedViolatorKnown, ...reporterFields } = latestvalues;
        const reporterFieldsWithIDs = {
          ...reporterFields,
          contactID: reporterContact?.contactID ?? undefined,
          requestID: reporterContact?.requestID ?? undefined,
          version: reporterContact?.version ?? undefined,
          contactType: ContactTypes.Reporter,
          createdBy: reporterContact?.createdBy ?? undefined,
          phoneOne: reporterFields?.phoneOne?.replace(/\D/g, '') ?? undefined,
          phoneTwo: reporterFields?.phoneTwo?.replace(/\D/g, '') ?? undefined,
          faxPhone: reporterFields?.faxPhone?.replace(/\D/g, '') ?? undefined,
        };
        const reporterObj = isAnonymous ? [] : [reporterFieldsWithIDs];
        const contactArr = [...reporterObj, ...otherPersonsRowData, ...allegedViolatorRowData];
        doUpdateViolationComplaintRequestContacts(contactArr);
      },
      [
        allegedViolatorRowData,
        doUpdateViolationComplaintRequestContacts,
        getValues,
        isAnonymous,
        otherPersonsRowData,
        reporterContact.contactID,
        reporterContact.createdBy,
        reporterContact.requestID,
        reporterContact.version,
      ]
    );

    useEffect(() => {
      // Update IsAllegedViolatorKnown state value
      doUpdateIsAllegedViolatorKnown(Boolean(isAllegedViolatorKnown === 'Yes'));
    }, [isAllegedViolatorKnown, doUpdateIsAllegedViolatorKnown]);

    useEffect(() => {
      if (allegedViolatorRowData?.length > 0) {
        isAllegedViolatorKnown === 'No' && clearRowData(setAllegeViolatorRowData);
      }
    }, [allegedViolatorRowData, isAllegedViolatorKnown]);

    useErrorFocus({ steps, stepNo, activeStep, trigger, isReadOnly });

    const generatePlaceholder = (table) => {
      let clickEvent;

      if (table === 'violator') {
        clickEvent = () =>
          doModalOpen(AddAllegedViolatorModal, {
            addRowData: addRowData,
            setRowData: setAllegeViolatorRowData,
            isReadOnly: isReadOnly,
          });
      } else {
        clickEvent = () =>
          doModalOpen(AddOtherPersonsModal, {
            addRowData: addRowData,
            setRowData: setOtherPersonsRowData,
            isReadOnly: isReadOnly,
          });
      }

      return (
        <div className='d-flex flex-column align-items-center margin-top-1 margin-bottom-1'>
          <p>No contacts found, please click below to add a contact:</p>
          <div className='margin-top-1'>
            <Button
              className='add-contact-button'
              title='Add a Contact'
              size='small'
              onClick={clickEvent}
              disabled={isReadOnly}
            >
              <Icon focusable={false} className='margin-right-1' path={mdiAccountPlus} size={'16px'} />
              Add a Contact
            </Button>
          </div>
        </div>
      );
    };

    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly && (
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        )}
        <FieldHeader text='Contact Information' />
        {!isAnonymous && (
          <>
            <ContactFields label='Reporter' showButton isReadOnly={isReadOnly} handleFieldChange={handleFieldChange} />
          </>
        )}
        <FieldHeader text='Alleged Violator' />
        <div id='allegedViolatorTable'>
          <SelectInput name='isAllegedViolatorKnown' label='Is Alleged Violator known?' required readOnly={isReadOnly}>
            <option key='2' value='Yes'>
              Yes
            </option>
            <option key='3' value='No'>
              No
            </option>
          </SelectInput>
        </div>
        {isAllegedViolatorKnown === 'Yes' && (
          <>
            <div className='d-flex width-full margin-top-2 padding-bottom-2 justify-content-end'>
              {allegedViolatorRowData.length > 0 && (
                <Button
                  className='add-contact-button'
                  title='Add a Contact'
                  size='small'
                  onClick={() =>
                    doModalOpen(AddAllegedViolatorModal, {
                      addRowData: addRowData,
                      setRowData: setAllegeViolatorRowData,
                      isReadOnly: isReadOnly,
                    })
                  }
                  disabled={isReadOnly}
                >
                  <Icon focusable={false} className='margin-right-1' path={mdiAccountPlus} size={'16px'} />
                  Add a Contact
                </Button>
              )}
              {allegedViolatorRowData.length !== 0 && (
                <Button
                  className='clear-table-button'
                  title='Clear Contacts'
                  size='small'
                  onClick={() => clearRowData(setAllegeViolatorRowData)}
                  disabled={isReadOnly || allegedViolatorRowData?.length === 0}
                >
                  <Icon focusable={false} className='margin-right-1' path={mdiCloseOctagon} size={'16px'} />
                  Clear Contacts
                </Button>
              )}
            </div>
            <div className='width-full margin-bottom-2'>
              <TanStackTableBasic
                data={allegedViolatorRowData}
                columns={AllegedViolatorColumnDefs}
                placeholder={generatePlaceholder('violator')}
              />
            </div>
          </>
        )}
        <FieldHeader text='Additional Contacts or Witnesses' />
        <p className='margin-top-2'>
          Other persons that may have information regarding the alleged violation (provide name and contact information
          if available):
        </p>
        <div className='d-flex width-full margin-top-2 padding-bottom-2 justify-content-end' id='otherPersonsTable'>
          {otherPersonsRowData?.length > 0 && (
            <Button
              className='add-contact-button'
              title='Add a Contact'
              size='small'
              onClick={() =>
                doModalOpen(AddOtherPersonsModal, {
                  addRowData: addRowData,
                  setRowData: setOtherPersonsRowData,
                  isReadOnly: isReadOnly,
                })
              }
              disabled={isReadOnly}
            >
              <Icon focusable={false} className='margin-right-1' path={mdiAccountPlus} size={'16px'} />
              Add a Contact
            </Button>
          )}
          {otherPersonsRowData?.length !== 0 && (
            <Button
              className='clear-table-button'
              title='Clear Contacts'
              size='small'
              onClick={() => clearRowData(setOtherPersonsRowData)}
              disabled={isReadOnly || otherPersonsRowData?.length === 0}
            >
              <Icon focusable={false} className='margin-right-1' path={mdiCloseOctagon} size={'16px'} />
              Clear Contacts
            </Button>
          )}
        </div>
        <div className='width-full margin-bottom-2'>
          <TanStackTableBasic
            data={otherPersonsRowData}
            columns={OtherPersonsColumnDefs}
            placeholder={generatePlaceholder('other')}
          />
        </div>
      </FormProvider>
    );
  }
);
ViolationsFormContacts.metadata = ViolationsFormContactsMetadata;

export default ViolationsFormContacts;
