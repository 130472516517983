import { ErrorMessages, ProfileRoles } from '@src/utils/enums';
import { addressRegex, addressTwoRegex, cityRegex, countryCodeRegex, phoneExtRegex, phoneRegex, usPhoneRegex, zipCodeRegex } from '@src/utils/regex';
import * as yup from 'yup';

const nameRequired = {
  salutation: yup.string().when('role', { 
    is: (role) => role !== null && role !== '',
    then: (schema) => schema.required(ErrorMessages.Required),
    otherwise: (schema) => schema.nullable()
  }),
  firstName: yup.string().when('role', { 
    is: (role) => role !== null && role !== '',
    then: (schema) => schema.required(ErrorMessages.Required),
    otherwise: (schema) => schema.nullable()
  }),
  middleName: yup.string().nullable(),
  lastName: yup.string().when('role', { 
    is: (role) => role !== null && role !== '', 
    then: (schema) => schema.required(ErrorMessages.Required),
    otherwise: (schema) => schema.nullable()
  }),
};

const addressRequired = {
  address: yup.string().when('role', {
    is: (role) => role !== null && role !== '' && role !== ProfileRoles.USACERegulator,
    then: (schema) => schema.required(ErrorMessages.Required).matches(addressRegex, ErrorMessages.RestrictedChars),
    otherwise: (schema) => schema.nullable()
  }),
  addressTwo: yup.string().nullable().matches(addressTwoRegex, ErrorMessages.RestrictedChars),
  city: yup.string().when('role', {
    is: (role) => role !== null && role !== '' && role !== ProfileRoles.USACERegulator,
    then: (schema) => schema.matches(cityRegex, { message: ErrorMessages.Invalid, excludeEmptyString: true }).required(ErrorMessages.Required),
    otherwise: (schema) => schema.nullable()
  }),
  state: yup.string().when('role', {
    is: (role) => role !== null && role !== '' && role !== ProfileRoles.USACERegulator,
    then: (schema) => schema.when('country', {
      is: 'US',
      then: (schema) => schema.required(ErrorMessages.SelectOption),
      otherwise: (schema) => schema.required(ErrorMessages.SelectOption),
    }),
  }).nullable(),
  zipcode: yup.string().when(['role', 'country'], {
    is: (role, country) => role !== null && role !== '' && role !== ProfileRoles.USACERegulator && country === 'US',
    then: (schema) => schema.matches(zipCodeRegex, { message: ErrorMessages.Invalid, excludeEmptyString: true }).required(ErrorMessages.Required),
    otherwise: (schema) => schema.nullable(),
  }),
  country: yup.string().when('role', {
    is: (role) => role !== null && role !== '',
    then: (schema) => schema.required(ErrorMessages.SelectOption),
    otherwise: (schema) => schema.nullable(),
  })
};

const phoneRequired = {
  phoneOneType: yup.string().when('role', {
    is: (role) => role !== null && role !== '' && role !== ProfileRoles.USACERegulator,
    then: (schema) => schema.required(ErrorMessages.Required),
    otherwise: (schema) => schema.nullable()
  }),
  phoneOneCountryCode: yup.string().when('role', {
    is: (role) => role !== null && role !== '' && role !== ProfileRoles.USACERegulator,
    then: (schema) => schema.required(ErrorMessages.Required).matches(countryCodeRegex, ErrorMessages.Invalid),
    otherwise: (schema) => schema.nullable()
  }),
  phoneOne: yup.string().when('role', {
    is: (role) => role !== null && role !== '' && role !== ProfileRoles.USACERegulator,
    then: (schema) => schema.when('phoneOneCountryCode', {
      is: '1',
      then: (schema) => schema.matches(usPhoneRegex, {message: ErrorMessages.Invalid, excludeEmptyString: true}),
      otherwise: (schema) => schema.when('phoneOneCountryCode', {
        is: (countryCode) => countryCode !== '1',
        then: (schema) => schema.matches(phoneRegex, {message: ErrorMessages.Invalid, excludeEmptyString: true}),
      }).nullable()
    }).required(ErrorMessages.Required)
  }),
  phoneOneExtension: yup.string().when('role', {
    is: (role) => role !== null && role !== '' && role !== ProfileRoles.USACERegulator,
    then: (schema) => schema.nullable().matches(phoneExtRegex, { message: ErrorMessages.Invalid, excludeEmptyString: true }),
    otherwise: (schema) => schema.nullable()
  }),
  showPhoneTwo: yup.boolean().nullable(),
  phoneTwoType: yup.string().nullable().when('showPhoneTwo', { 
    is: true, 
    then: (schema) => schema.required(ErrorMessages.Required) 
  }),
  phoneTwoCountryCode: yup.string().nullable().when('showPhoneTwo', {
    is: true,
    then: (schema) => schema.required(ErrorMessages.Required).matches(countryCodeRegex, ErrorMessages.Invalid),
  }),
  phoneTwo: yup.string().nullable().when(['phoneTwoCountryCode', 'showPhoneTwo'], {
    is: (countryCode, showPhoneTwo) => countryCode === '1' && showPhoneTwo === true,
    then: (schema) => schema.required(ErrorMessages.Required).matches(usPhoneRegex, {message: ErrorMessages.Invalid, excludeEmptyString: true}),
    otherwise: (schema) => schema.nullable().when(['phoneTwoCountryCode', 'showPhoneTwo'], {
      is: (countryCode, showPhoneTwo) => countryCode !== '1' && showPhoneTwo === true,
      then: (schema) => schema.required(ErrorMessages.Required).matches(phoneRegex, {message: ErrorMessages.Invalid, excludeEmptyString: true}),
    }),
  }),
  phoneTwoExtension: yup.string().nullable().matches(phoneExtRegex, { message: ErrorMessages.Invalid, excludeEmptyString: true }),
};

const areasOfInterestRequired = {
  usaceHQ: yup.boolean().nullable(),
  division: yup.array().when('role', {
    is: (role) => role === ProfileRoles.USACERegulator || role === ProfileRoles.ExternalAgency,
    then: (schema) => schema.when('usaceHQ', {
      is: (usaceHQ) => usaceHQ === false || usaceHQ === null,
      then: (schema) => schema
    })
  }),
  district: yup.array().when('role', {
    is: (role) => role === ProfileRoles.USACERegulator || role === ProfileRoles.ExternalAgency,
    then: (schema) => schema.when('usaceHQ', {
      is: (usaceHQ) => usaceHQ === false || usaceHQ === null,
      then: (schema) => schema
    })
  })
};

export const profileValidation = {
  addressRequired,
  areasOfInterestRequired,
  nameRequired,
  phoneRequired
};
