import React, { useState, useEffect, useCallback, useMemo } from 'react';
import useErrorFocus from '@hooks/useErrorFocus';
import { connect } from 'redux-bundler-react';
import { createColumnHelper } from '@tanstack/react-table';
import { mdiAccountPlus, mdiCloseOctagon } from '@mdi/js';
import { Alert, Button } from '@trussworks/react-uswds';
import FieldHeader from '@forms/components/Form/FieldHeader';
import Icon from '@components/icon/Icon';
import AddAdjoiningPropertyOwnerModal from '@forms/components/modals/AddAdjoiningPropertyOwnerModal';
import AgentsActionsTableCell from '@src/app-components/table/tableCellComponents/AgentsActionsTableCell';
import { ContactTypes } from '@src/utils/enums';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import TanStackTableBasic from '@src/app-components/table/TanStackTable/TanStackTableBasic';
import '@styles/_buttons.scss';

export const PermitsFormAdjoiningPropertyOwnersMetadata = {
  sectionName: 'Adjoining Property Owners',
  isSection: true,
  lastSection: false,
  firstSection: false,
};

const PermitsFormAdjoiningPropertyOwners = connect(
  'doClearContactType',
  'doModalOpen',
  'doUpdateSectionValidity',
  'doSaveTable',
  'selectRequestAPIData',
  'selectSteps',
  'selectActiveStep',
  'selectPermitData',
  'selectIsReadOnly',
  ({
    doClearContactType,
    doModalOpen,
    doUpdateSectionValidity,
    doSaveTable,
    requestAPIData,
    steps,
    activeStep,
    permitData,
    isReadOnly,
    stepNo,
  }) => {
    const thisSectionStepStatus = useMemo(() => steps.find((step) => step.id === stepNo)?.touched, [steps, stepNo]);
    const [rowData, setRowData] = useState([]);
    const requestedPermitType = permitData?.requestedPermitType;
    const adjPropertyOwnersTableLength = rowData?.length;

    const schema = (adjPropertyOwnersTableLength) =>
      yup.object().shape({
        adjPropertyOwnersTable: yup.boolean().when('propOwners', {
          is: (val) => val === 'true' && adjPropertyOwnersTableLength < 1,
          then: () =>
            yup.boolean().required('Adjoining Property Owners: At least one adjoining property owner is required'),
        }),
      });

    const defaultValues = {};

    const methods = useForm({
      resolver: yupResolver(schema(adjPropertyOwnersTableLength)),
      mode: 'onBlur',
      defaultValues: defaultValues,
    });
    const {
      formState: { errors },
      setError,
      trigger,
    } = methods;
    const errorCount = Object.values(errors).length;
    const columnHelper = createColumnHelper();

    const columnDefs = useMemo(
      () => [
        columnHelper.display({
          header: 'Actions',
          id: 'actions',
          cell: ({ row }) => (
            <AgentsActionsTableCell
              row={row}
              rowData={rowData}
              setRowData={setRowData}
              modalComponent={AddAdjoiningPropertyOwnerModal}
              isReadOnly={isReadOnly}
            />
          ),
          size: 60,
          enableSorting: false,
          meta: {
            centerText: true,
          },
        }),
        columnHelper.accessor('firstName', {
          header: 'First Name',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('middleName', {
          header: 'Middle Name',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('lastName', {
          header: 'Last Name',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('address', {
          header: 'Address',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 500,
        }),
        columnHelper.accessor('city', {
          header: 'City',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('state', {
          header: 'State',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 75,
        }),
        columnHelper.accessor('country', {
          header: 'Country',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 75,
        }),
      ],
      [columnHelper, rowData, isReadOnly]
    );

    const clearRowData = useCallback(() => {
      setRowData([]);
      doClearContactType(ContactTypes.AdjoiningPropertyOwner);
    }, [doClearContactType]);

    useEffect(() => {
      // Load tables from database
      let adjoiningPropertyowners = requestAPIData?.request?.contacts?.filter(
        (contact) => contact.contactType === ContactTypes.AdjoiningPropertyOwner
      );
      // If country is null, default to US
      adjoiningPropertyowners = adjoiningPropertyowners?.map((contact) =>
        contact.country === null ? { ...contact, country: 'US' } : contact
      );
      adjoiningPropertyowners && setRowData(adjoiningPropertyowners);
    }, [requestAPIData]);

    useEffect(() => {
      // Clear Adjoining Prop Owners rowData if requestedPermitType is not "Standard"
      permitData?.requestedPermitType !== 'Standard Permit' && setRowData([]);
    }, [permitData?.requestedPermitType]);

    useEffect(() => {
      rowData && doSaveTable('adjoiningPropOwner', rowData);
    }, [rowData, doSaveTable]);

    useEffect(() => {
      if (requestedPermitType === 'Standard Permit' && rowData?.length < 1) {
        setError('adjPropertyOwnersTable', {
          type: 'custom',
          message: 'Adjoining Property Owners: At least one adjoining property owner is required',
        });
      } else {
        trigger('adjPropertyOwnersTable');
      }
    }, [requestedPermitType, rowData, setError, trigger]);

    useEffect(() => {
      const validity = errorCount === 0 && rowData?.length > 0;
      doUpdateSectionValidity(
        PermitsFormAdjoiningPropertyOwnersMetadata.sectionName,
        requestedPermitType === 'Standard Permit' ? validity : true,
        stepNo,
        isReadOnly
      );
    }, [errorCount, rowData, doUpdateSectionValidity, stepNo, requestedPermitType, isReadOnly]);

    useErrorFocus({ steps, stepNo, activeStep, trigger, noTrigger: true, isReadOnly });

    const placeholder = (
      <div className='d-flex flex-column align-items-center margin-top-1 margin-bottom-1'>
        <p>No adjoining property owners found, please click below to add an adjoining property owner:</p>
        <div className='margin-top-1'>
          <Button
            className='add-property-owner-button'
            title='Add an Adjoining Property Owner'
            size='small'
            onClick={() =>
              doModalOpen(AddAdjoiningPropertyOwnerModal, {
                setRowData: setRowData,
                rowData: rowData,
                isReadOnly: isReadOnly,
              })
            }
            disabled={isReadOnly}
          >
            <Icon focusable={false} className='margin-right-1' path={mdiAccountPlus} size={'16px'} />
            Add an Adjoining Property Owner
          </Button>
        </div>
      </div>
    );

    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly && (
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        )}
        <FieldHeader text='Adjoining Property Owner(s)'>
          {permitData?.requestedPermitType === 'Standard Permit' ? (
            <>
              <p>
                Provide the names and addresses of all property owners, lessees, etc., whose property adjoins the
                project site and properties that adjoin the waterbody or aquatic site where the work is being proposed.
                This information is used to notify the property owners of the project by Public Notice. Information
                regarding adjoining landowners can typically be obtained through the office of the tax assessor in the
                counties/boroughs where the project is located.
              </p>
              <p className='mt-2'>
                Adjoining property owners can be provided in this section by adding them individually.
              </p>
            </>
          ) : (
            <Alert type='warning' heading='IMPORTANT' headingLevel='h3'>
              <p>Adjoining Property Owners Section required for Standard Permits only.</p>
            </Alert>
          )}
        </FieldHeader>

        {permitData?.requestedPermitType === 'Standard Permit' && (
          <>
            <div className='d-flex width-full margin-top-2 padding-bottom-2 justify-content-end'>
              {rowData.length > 0 && (
                <Button
                  className='add-property-owner-button'
                  title='Add an Adjoining Property Owner'
                  size='small'
                  onClick={() =>
                    doModalOpen(AddAdjoiningPropertyOwnerModal, {
                      setRowData: setRowData,
                      rowData: rowData,
                      isReadOnly: isReadOnly,
                    })
                  }
                  disabled={isReadOnly}
                >
                  <Icon focusable={false} className='margin-right-1' path={mdiAccountPlus} size={'16px'} />
                  Add an Adjoining Property Owner
                </Button>
              )}
              {rowData.length !== 0 && (
                <Button
                  className={`clear-table-button ${rowData.length === 0 || isReadOnly ? 'disabled' : 'hover'}`}
                  title='Clear Adjoining Property Owner(s)'
                  size='small'
                  onClick={() => clearRowData()}
                  disabled={isReadOnly || rowData.length < 1}
                >
                  <Icon focusable={false} className='margin-right-1' path={mdiCloseOctagon} size={'16px'} />
                  Clear Adjoining Property Owner(s)
                </Button>
              )}
            </div>
            <div className='width-full margin-bottom-2' id='adjPropertyOwnersTable'>
              <TanStackTableBasic data={rowData} columns={columnDefs} placeholder={placeholder} />
            </div>
          </>
        )}
      </FormProvider>
    );
  }
);

PermitsFormAdjoiningPropertyOwners.metadata = PermitsFormAdjoiningPropertyOwnersMetadata;

export default PermitsFormAdjoiningPropertyOwners;
