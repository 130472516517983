import React, { useState } from 'react';
import { connect } from 'redux-bundler-react';
import Select from 'react-select';

import Tooltip from '../../../../app-components/tooltip/tooltip';
import NewApplicationModal from '../../../Home/components/modals/NewApplication';
import ExternalLink from '@components/external-link/ExternalLink';
import LinkButton from '../../../../app-components/link/linkButton.jsx';

import { Row } from '../../../_shared/helper.jsx';
import { NWPTooltipContent, RGPTooltipContent } from '../_shared/helper.jsx';
import { ProfileRoles } from '@src/utils/enums';

const QuestionSetTwo = connect(
  'doAuthLogin',
  'doModalOpen',
  'doUpdateRelativeUrl',
  'selectAuthIsLoggedIn',
  'selectUserProfileData',
  ({
    doAuthLogin,
    doModalOpen,
    doUpdateRelativeUrl,
    authIsLoggedIn,
    userProfileData
  }) => {
    const [answer, setAnswer] = useState('');

    const QAMap = {
      'A': { text: 'Then, it appears a permit may not be required from the USACE, however; if questions remain, please contact your local USACE Regulatory Office. You can exit out of this tool. Have a great day!', link: false },
      'B': { text: (<>You may need to contact our <ExternalLink href='https://regulatory.ops.usace.army.mil/offices/' title='office' content='office' /> to obtain a written permit verification. Please contact one of our staff to discuss next steps.</>), link: true },
      'C': {
        text: (
          <>
            <div className='mb-2'>You may need to contact our <ExternalLink href='https://regulatory.ops.usace.army.mil/offices/' title='office' content='office' /> to obtain a written permit verification. Some nationwide permits are non-reporting but some require written verification from our office prior to beginning the work. Please contact one of our staff to discuss next steps.</div>
            <div>Or if you know the Nationwide Permit you propose to use requires written verification, complete and submit a Pre-Construction Notification.</div>
          </>
        ), link: true
      },
      'D': {
        text: (
          <><div>Please login and submit a Pre-application meeting request with the USACE to discuss the Individual Permit process.</div>
            {userProfileData?.role?.toLowerCase() !== ProfileRoles.USACERegulator.toString().toLowerCase() && (
              <LinkButton
                title={authIsLoggedIn ? 'Submit a request' : 'Login here'}
                content={authIsLoggedIn ? 'Submit a request' : 'Login here'}
                onClick={() => handleLink()}
              />
            )}
          </>
        ), link: true
      },
    };

    const handleLink = () => {
      sessionStorage.setItem('ResourcesModuleSession', true);
      if (authIsLoggedIn) {
        const resourcesModuleSession = sessionStorage.getItem('ResourcesModuleSession');
        doUpdateRelativeUrl('/home');
        resourcesModuleSession && doModalOpen(NewApplicationModal);
        sessionStorage.removeItem('ResourcesModuleSession');
      } else {
        doAuthLogin();
      }
    };

    return (
      <>
        <div className='container-fluid'>
          <div>
            Okay, next question.
            <div className='header-spacer'>
              <Row>
                <p className='h6 border-bottom w-100 pb-2'>Question</p>
              </Row>
              <p>Does your project qualify for an exemption or can it be made to fit an expedited General Permit (GP):</p>
              <ol>
                <li>Do any of the <ExternalLink href='https://www.epa.gov/cwa-404/exemptions-permit-requirements-under-cwa-section-404' title='Clean Water Act Exemptions' content='Clean Water Act Exemptions' /> seem to apply to your proposed project? If so, your work may be exempt from the need to obtain a permit from the USACE. If you are unsure if your project meets the exemptions please <ExternalLink href='https://regulatory.ops.usace.army.mil/offices/' title='contact us' content='contact us' />.</li>
                <li>If the exemptions do not apply, are you able to design your project to fit any of the more <ExternalLink href='https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/Obtain-a-Permit/#RegProgPermits' title='expedited Regional General Permits (RGPs) or Programmatic General Permits (PGPs)' content='expedited Regional General Permits (RGPs) or Programmatic General Permits (PGPs)' /><Tooltip name='rgp-tooltip' content={RGPTooltipContent} />?</li>
                <li>If the exemptions and RGPs do not apply, are you able to design your project to fit any of the <ExternalLink href='https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/Nationwide-Permits/' title='expedited Nationwide Permits' content='expedited Nationwide Permits' /><Tooltip name='nationwide-permit-tooltip' content={NWPTooltipContent} />?</li>
              </ol>
            </div>
            <div className='header-spacer'>
              <Row>
                <p className='h6 border-bottom w-100 pb-2'>Answer</p>
              </Row>
              <Select
                name='answer'
                value={answer}
                options={[
                  { value: 'A', label: 'Yes. One or more of the exemptions apply to my proposed project.' },
                  { value: 'B', label: 'Yes. Project qualifies for an RGP or PGP.' },
                  { value: 'C', label: 'Yes. Project qualifies for one or more Nationwide permits.' },
                  { value: 'D', label: 'No. My project does not meet any of the exemptions, Regional General Permits, Programmatic General Permits, or Nationwide Permits.' }
                ]}
                onChange={(val) => setAnswer(val)}
                menuPlacement='top'
              />
              <div className='header-spacer'>{answer !== '' ? QAMap[answer.value].text : 'Select an option above to view responses to selected answer.'}</div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default QuestionSetTwo;
