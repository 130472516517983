import { connect } from 'redux-bundler-react';
import { toast } from 'react-toastify';
import { mdiAlertCircle, mdiCheckCircle } from '@mdi/js';
import { Button } from '@trussworks/react-uswds';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import SelectInput from '@components/select/Select';
import Icon from '@components/icon/Icon';

import { ErrorMessages } from '@src/utils/enums';

const availableText = (isURLReachable) => {
  if (isURLReachable === 'True') {
    return (
      <span className='banner-toggle-label enabled selected'>
        OK <Icon focusable={false} path={mdiCheckCircle} size='16px' />
      </span>
    );
  } else if (isURLReachable === 'False') {
    return (
      <span className='banner-toggle-label disabled selected'>
        FAILED <Icon focusable={false} path={mdiAlertCircle} size='16px' />
      </span>
    );
  } else {
    return '';
  }
};

const endpointMapping = {
  PMInfo: 'PM Info',
  PublicNotice: 'Public Notice',
  PublicNoticeURLs: 'Public Notice URLs',
  TokenUrl: 'Token Url',
  ArcGisBackupUrl: 'ArcGis Backup Url',
  ArcGisUrl: 'ArcGis Url',
  BankSite: 'Bank Site',
  IlfProgram: 'Ilf Program',
  Aop: 'Aop Url'
};

const schema = yup.object().shape({
  endpointURL: yup.string().required(ErrorMessages.SelectOption),
});

const EndpointCheck = connect(
  'doCheckAPI',
  'doResetIsURLReachable',
  'selectIsURLReachable',
  ({ doCheckAPI, doResetIsURLReachable, isURLReachable }) => {
    const methods = useForm({
      resolver: yupResolver(schema),
      mode: 'onBlur',
      stateOptions: [],
    });
    const {
      formState: { isValid },
      watch,
      trigger,
    } = methods;

    const endpointURL = watch('endpointURL');

    const handleChange = () => {
      // Reset availability text
      doResetIsURLReachable();
    };

    const handleSubmit = () => {
      if (isValid) {
        doCheckAPI(endpointURL);
      } else {
        trigger();
        toast.error('Select something from the dropdown');
      }
    };

    return (
      <FormProvider {...methods}>
        <div>
          <SelectInput
            name='endpointURL'
            label='Select ORM/External API'
            style={{width:'250px'}}
            onChange={handleChange}
            required
          >
            {Object.keys(endpointMapping).map((item, index) => (
              <option key={index + 1} value={item}>
                {endpointMapping[item]}
              </option>
            ))}
          </SelectInput>
        </div>
        <Button className='dev-dashboard-btn' title='Check API' size='small' onClick={handleSubmit}>
          Check API
        </Button>
        <p className='margin-top-2'>Current Status: {availableText(isURLReachable)}</p>
      </FormProvider>
    );
  }
);

export default EndpointCheck;
