import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-easybutton';

import { mdiClose } from '@mdi/js';

import './resetMap.scss';

const ResetMap = ({ id = 'resetMapButton', position = 'topright', handleReset = () => {} }) => {
  const map = useMap();

  useEffect(() => {
    const handleClick = () => {
      handleReset();
      const inputElement = document.querySelector('.geocoder-control-input');
      inputElement.value = '';
    };
    const easyButton = L.easyButton({
      id: id,
      states: [
        {
          icon: `<svg width='24' height='24' viewBox="0 2 24 24">
        <path d='${mdiClose}' />
      </svg>`,
          title: 'Reset Map',
          onClick: () => {
            handleClick();
          },
        },
      ],
    })
      .setPosition(position)
      .addTo(map);

    // Cleanup function to remove the button when the component is unmounted
    return () => {
      easyButton.removeFrom(map);
    };
  }, [map, id, position, handleReset]); // Dependency array to ensure the effect runs once

  return null;
};

export default ResetMap;
