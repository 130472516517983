import { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import { calculateMarginOffset } from './tooltipHelper';

import './tooltip.scss';

const TRIANGLE_SIZE = 6;

const NewTooltip = ({
  className,
  onClick = () => {},
  position = 'right',
  tooltipClickable = false,
  wrapperClasses,
  ...props
}) => {
  const { label, title, children, ...remainingProps } = props;

  const triggerElementRef = useRef(null);
  const tooltipBodyRef = useRef(null);
  const tooltipID = useRef(`tooltip-${Math.floor(Math.random() * 900000) + 100000}`);

  const [isVisible, setVisible] = useState(false);
  const [effectivePosition, setEffectivePosition] = useState(undefined); // 'top' | 'bottom' | 'left' | 'right' | undefined
  const [wrapTooltip, setWrapTooltip] = useState(false);
  const [positionStyles, setPositionStyles] = useState({});

  const setWrapperClasses = classnames('usa-tooltip', wrapperClasses);

  const triggerClasses = classnames('usa-button', 'usa-tooltip__trigger', className);

  const tooltipBodyClasses = classnames('usa-tooltip__body', position, {
    'usa-tooltip__body--top': effectivePosition === 'top',
    'usa-tooltip__body--bottom': effectivePosition === 'bottom',
    'usa-tooltip__body--right': effectivePosition === 'right',
    'usa-tooltip__body--left': effectivePosition === 'left',
    'usa-tooltip__body--wrap': isVisible && wrapTooltip,
  });

  const showTooltip = () => {
    setVisible(true);
  };
  const hideTooltip = () => {
    setVisible(false);
  };

  const positionTop = useCallback((e, triggerEl) => {
    const topMargin = calculateMarginOffset('top', e.offsetHeight, triggerEl);
    const leftMargin = calculateMarginOffset('left', e.offsetWidth, triggerEl) / 2;

    setEffectivePosition('top');
    setPositionStyles({
      left: '50%',
      top: `-${TRIANGLE_SIZE}px`,
      margin: `-${topMargin}px 0 0 -${leftMargin}px`,
    });
  }, []);

  const positionBottom = useCallback((e, triggerEl) => {
    const leftMargin = calculateMarginOffset('left', e.offsetWidth, triggerEl) / 2;

    setEffectivePosition('bottom');
    setPositionStyles({
      left: '50%',
      margin: `${TRIANGLE_SIZE}px 0 0 -${leftMargin}px`,
    });
  }, []);

  const positionRight = useCallback((e, triggerEl) => {
    const topMargin = calculateMarginOffset('top', e.offsetHeight, triggerEl) / 2;

    setEffectivePosition('right');
    setPositionStyles({
      top: '50%',
      left: `${triggerEl.offsetLeft + triggerEl.offsetWidth + TRIANGLE_SIZE}px`,
      margin: `-${topMargin}px 0 0 0`,
    });
  }, []);

  const positionLeft = useCallback((e, triggerEl) => {
    const topMargin = calculateMarginOffset('top', e.offsetHeight, triggerEl) / 2;
    const leftMargin = calculateMarginOffset(
      'left',
      triggerEl.offsetLeft > e.offsetWidth ? triggerEl.offsetLeft - e.offsetWidth : e.offsetWidth,
      triggerEl
    );

    setEffectivePosition('left');
    setPositionStyles({
      top: '50%',
      left: `-${TRIANGLE_SIZE}px`,
      margin: `-${topMargin}px 0 0 ${triggerEl.offsetLeft > e.offsetWidth ? leftMargin : -leftMargin}px`,
    });
  }, []);

  // Execute dependent on tooltip visibility
  useEffect(() => {
    if (!isVisible) {
      setVisible(false);
      setWrapTooltip(false);
    } else {
      if (triggerElementRef.current && tooltipBodyRef.current) {
        const tooltipTrigger = triggerElementRef.current;
        const tooltipBody = tooltipBodyRef.current;

        switch (position) {
          case 'top':
            positionTop(tooltipBody, tooltipTrigger);
            break;
          case 'bottom':
            positionBottom(tooltipBody, tooltipTrigger);
            break;
          case 'right':
            positionRight(tooltipBody, tooltipTrigger);
            break;
          case 'left':
            positionLeft(tooltipBody, tooltipTrigger);
            break;
          default:
            break;
        }
      }
    }
  }, [isVisible, position, positionBottom, positionLeft, positionRight, positionTop]);

  return (
    <span data-testid='tooltipWrapper' className={setWrapperClasses}>
      <button
        aria-describedby={tooltipID.current}
        className={triggerClasses}
        data-testid='triggerElement'
        onBlur={hideTooltip}
        onClick={onClick}
        onKeyDown={hideTooltip}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        onMouseOver={showTooltip}
        ref={triggerElementRef}
        tabIndex={0}
        title=''
        type='button'
        {...remainingProps}
      >
        {children}
      </button>
      {isVisible === true && (
        <div className='tooltip-container'>
          <span
            aria-hidden={isVisible === false}
            className={tooltipBodyClasses}
            data-testid='tooltipBody'
            id={tooltipID.current}
            ref={tooltipBodyRef}
            role='tooltip'
            style={positionStyles}
            title={title ?? (typeof label === 'string' ? label : undefined)}
          >
            {label}
          </span>
        </div>
      )}
    </span>
  );
};

export default NewTooltip;
