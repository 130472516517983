import { connect } from 'redux-bundler-react';
import NewSectionCard from '../components/_shared/NewSectionCard';
import { SectionHeader } from './_shared/helper';

const PublicNotices = connect(
  'doUpdateUrl',
  ({
    doUpdateUrl,
  }) => {
      
    const handleLink = (e) => {
      doUpdateUrl('/public-notices');
    };

    return (
      <>
        <section id='public-notice-info-id'>
          <SectionHeader>Public Notice Process</SectionHeader>
          <div>
            <p>
              In accordance with its regulations at <span className='text-bold'>33 CFR 325.3</span>, the U.S. Army Corps of Engineers is 
              required to issue a Public Notice within <span className='text-bold'>15 days</span> Standard (or Individual) permit application 
              that it has determined is complete and is ready for processing.  Public Notices can also be 
              issued during the process to develop a Mitigation Bank or In-Lieu-Fee Program or to announce 
              the creation or reissuance of a Regional or Programmatic General Permit.  Public Notices are 
              generally issued for a period of <span className='text-bold'>30-days</span>; however, <span className='text-bold'>15-day</span> Public Notices are allowable 
              depending on the size and complexity of the project. Public notice comment periods may be 
              extended if the project is very large and complex and additional time is needed or other 
              extenuating circumstances occur. This is somewhat rare, but extensions do occur.
            </p>
            <p>
              Public Notices are widely distributed and serve a critical role in the USACE Regulatory 
              Program by being the primary method of advising all interested parties, including local, 
              state, and federal agencies of the proposed activity for which a permit is being sought.  
              They also promote soliciting comments and information necessary to evaluate the probable 
              impact on the Public Interest and allow a meaningful analysis required by the <span className='text-bold'>404(b)(1) Guidelines</span>. The Public Notice is also an important mechanism to gather information 
              concerning the proposal that may otherwise not be available to USACE.  Among other items, Public Notices typically include the following content:</p>
            <span className='text-bold'><span className='text-underline'>Public Notice Content:</span></span>
            <ul>
              <li>
                The applicable statutory authority under which the activity is regulated.
              </li>
              <li>
                The name and address of the applicant.
              </li>
              <li>
                The name or title, address and telephone number of the USACE employee from whom 
                additional information concerning the application may be obtained.
              </li>
              <li>
                The location of the proposed activity.
              </li>
              <li>
                A brief description of the proposed activity.
              </li>
              <li>
                The purpose and intended use, including a description of the type of structures (if any) 
                to be erected on fills or pile or float-supported platforms.
              </li>
              <li>
                A description of the type, composition, and quantity of materials to be discharged 
                or disposed of in the ocean.
              </li>
              <li>
                A plan and elevation drawing showing the general and specific site location and 
                character of all proposed activities. This should include the size relationship of the proposed 
                structures to the size of the impacted waterway and depth of water in the area.
              </li>
            </ul>
            <p>
              After the Public Notice comment period has ended, the District Engineer will review and 
              carefully consider all comments received prior to making his or her Public Interest 
              determination. Substantive comments received in response to the Public Notice will be 
              acknowledged and they will be made a part of the administrative record of the application 
              and will be provided to the applicant for his or her review and rebuttal if objections are 
              received in response to the Public Notice.  
            </p>
          </div>
        </section>
        
        <section id='next-steps-id' className='margin-top-3'>
          <SectionHeader>What are my next steps?</SectionHeader>
          <div style={{ width: '50%' }}>
            <NewSectionCard
              buttonText='Public Notices'
              className='public-notices-card'
              content={'Search, Review, and/or Comment on USACE Regulatory Public Notices.'}
              onClick={() => handleLink()}
              title='Public Notices'
            />
          </div>
        </section>
      </>
    );
  });

export default PublicNotices;
