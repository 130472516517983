import { Checkbox } from '@trussworks/react-uswds';
import { v4 as uuidv4 } from 'uuid';

export const EditCell = ({ row, table }) => {
  const meta = table.options.meta;

  const setEditedRows = (e) => {
    e.preventDefault();
    const elName = e.currentTarget.name;
    meta?.setEditedRows((old) => ({
      ...old,
      [row.id]: !old[row.id],
    }));
    if (elName !== 'edit') {
      meta?.revertData(row.index, e.currentTarget.name === 'cancel');
    }
  };

  return (
    <div className='edit-cell-container'>
      {meta?.editedRows[row.id] ? (
        <div className='edit-cell-action'>
          <button onClick={setEditedRows} name='cancel'>
            ⚊
          </button>{' '}
          <button onClick={setEditedRows} name='done'>
            ✔
          </button>
        </div>
      ) : (
        <div style={{ paddingBottom: '30px', paddingLeft: '5px' }}>
          <Checkbox
            className='box-shadow-1px'
            title='Select Row'
            name='edit'
            label=''
            id={`${uuidv4()}_row`}
            onChange={row.getToggleSelectedHandler()}
            checked={row.getIsSelected()}
            aria-label={`Select row ${row.id}`}
          />
        </div>
      )}
    </div>
  );
};
