import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-easybutton';

import { mdiFolderUploadOutline } from '@mdi/js';

import './fileUploadButton.scss';

const FileUploadButton = ({
  handleUpload = () => alert('NO HANDLEUPLOAD EVENT SET FOR FILE UPLOAD BUTTON'),
  id = 'fileUploadButton',
  position = 'topleft',
  accept,
}) => {
  const map = useMap();

  useEffect(() => {
    const handleClick = () => {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = accept;
      fileInput.onchange = (e) => handleUpload(e);
      fileInput.click();
    };
    const easyButton = L.easyButton({
      id: id,
      position: position,
      states: [
        {
          icon: `<svg width='24' height='24' viewBox="0 2 24 24">
        <path d='${mdiFolderUploadOutline}' />
      </svg>`,
          title: 'Upload GDB or CSV File',
          onClick: () => {
            handleClick();
          },
        },
      ],
    }).addTo(map);

    // Cleanup function to remove the button when the component is unmounted
    return () => {
      easyButton.removeFrom(map);
    };
  }, [map, id, position, accept, handleUpload]); // Dependency array to ensure the effect runs once

  return null;
};

export default FileUploadButton;
