import { useMemo, useState } from 'react';
import { connect } from 'redux-bundler-react';
import { mdiArrowDown, mdiArrowUp, mdiChat, mdiEye, mdiFileExport } from '@mdi/js';
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  createColumnHelper,
} from '@tanstack/react-table';
import { Button, Table, Tooltip as UswdsTooltip } from '@trussworks/react-uswds';

import Icon from '@components/icon/Icon';
import PublicNoticesTableFooter from './PublicNoticesTable.footer';
import ActionsTableCell from './ActionsTableCell';
import ApplicantTableCell from './ApplicantTableCell';
import Tooltip from '@components/tooltip/tooltip';

import { formatDateToMMDDYYYY } from '@src/utils/helpers';

import './publicNoticesTable.scss';

const iconProps = {
  focusable: false,
  size: 1,
};

const typeMapping = {
  LOP: 'Letter of Permission',
  SP: 'Standard Permit',
  NWP: 'Nationwide Permit',
  RGP: 'Regional General Permit',
  PGP: 'Programmatic General Permit',
  DEVINLIEUA: 'Develop In-Lieu Fee Program',
  DEVMBA: 'Develop Mitigation Bank',
  DEVRPSS: 'Develop RGP/PGP/SPGP/Section 404-LOP',
};

const actionsTooltipContent = (
  <Table compact bordered fullWidth className='margin-top-0 padding-0'>
    <thead>
      <tr>
        <th scope='col'>Icon</th>
        <th scope='col'>Description</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className='center'>
          <Icon focusable={false} path={mdiEye} size={'16px'} />
        </td>
        <td>View Full Public Notice</td>
      </tr>
      <tr>
        <td className='center'>
          <Icon focusable={false} path={mdiChat} size={'16px'} />
        </td>
        <td>Submit Comments</td>
      </tr>
    </tbody>
  </Table>
);

const typeTooltipContent = (
  <Table compact bordered fullWidth className='margin-top-0 padding-0'>
    <thead>
      <tr>
        <th scope='col'>Short Code</th>
        <th scope='col'>Name</th>
      </tr>
    </thead>
    <tbody>
      {Object.keys(typeMapping).map((shortCode, index) => (
        <tr key={index}>
          <td>{shortCode}</td>
          <td>{typeMapping[shortCode]}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

const projectNameTooltip = <>Hover over the project names to view a description of each project.</>;

const projectNameWithTooltip = (cell, row) => {
  const projectName = cell.getValue();
  const description = row.original?.description;
  return description ? (
    <UswdsTooltip
      className='project-description-tooltip'
      position='right'
      label={description}
      onMouseDown={(e) => e.preventDefault()}
    >
      <span>{projectName}</span>
    </UswdsTooltip>
  ) : (
    <UswdsTooltip
      className='project-description-tooltip'
      position='right'
      label={'No Description Available'}
      onMouseDown={(e) => e.preventDefault()}
    >
      <span>{projectName}</span>
    </UswdsTooltip>
  );
};

const PublicNoticesTable = connect(
  'doExportPublicNoticesCSV',
  'doFetchPublicNotices',
  'selectPublicNoticesDistricts',
  'selectPublicNoticesItemsPerPage',
  'selectPublicNoticesSearchText',
  'selectPublicNoticesStates',
  'selectPublicNoticesTotalCount',
  'selectPublicNoticesPageNumber',
  ({
    doExportPublicNoticesCSV,
    doFetchPublicNotices,
    publicNoticesDistricts,
    publicNoticesItemsPerPage,
    publicNoticesSearchText,
    publicNoticesStates,
    publicNoticesTotalCount,
    publicNotices,
    publicNoticesPageNumber,
    setSorting,
  }) => {
    const columnHelper = createColumnHelper();
    const [sorting, setSortingLocal] = useState({ column: null, order: 'asc' });

    const columns = useMemo(
      () => [
        columnHelper.display({
          header: 'Actions',
          cell: ActionsTableCell,
          size: 100,
          enableSorting: false,
          meta: {
            centerText: true,
            tooltip: actionsTooltipContent,
          },
        }),
        columnHelper.accessor('daNumber', {
          header: 'DA Number',
          cell: ({ cell }) => <span style={{ whiteSpace: 'nowrap' }}>{cell.getValue()}</span>,
          size: 50,
          enableSorting: true,
        }),
        columnHelper.accessor('projectName', {
          header: 'Project Name',
          cell: ({ cell, row }) => projectNameWithTooltip(cell, row),
          size: 1750,
          enableSorting: true,
          meta: {
            tooltip: projectNameTooltip,
          },
        }),
        columnHelper.accessor('actionType', {
          header: 'Type',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 100,
          enableSorting: true,
          meta: {
            centerText: true,
            tooltip: typeTooltipContent,
          },
        }),
        columnHelper.accessor('startDate', {
          header: () => (
            <>
              Date of
              <br />
              Public Notice
            </>
          ),
          cell: ({ cell }) => <span>{formatDateToMMDDYYYY(cell.getValue())}</span>,
          size: 175,
          enableSorting: true,
          meta: {
            centerText: true,
          },
        }),
        columnHelper.accessor('commentsEndDate', {
          header: () => (
            <>
              Date Comment
              <br />
              Period Ends
            </>
          ),
          cell: ({ cell }) => <span>{formatDateToMMDDYYYY(cell.getValue())}</span>,
          size: 175,
          enableSorting: true,
          meta: {
            centerText: true,
          },
        }),
        columnHelper.accessor('applicantName', {
          header: 'Applicant Name',
          enableHiding: true,
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('applicantCompany', {
          header: 'Applicant Company',
          enableHiding: true,
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.display({
          header: 'Applicant',
          cell: ApplicantTableCell,
          size: 750,
          enableSorting: false,
        }),
      ],
      [columnHelper]
    );

    const handleSort = (column) => {
      const sortColumn = column.id;
      let sortOrder = 'asc';

      if (sorting.column === sortColumn) {
        sortOrder = sorting.order === 'asc' ? 'desc' : 'asc';
      }

      const newSorting = { column: sortColumn, order: sortOrder };
      setSortingLocal(newSorting);
      setSorting(newSorting);

      const fetchData = {
        states: publicNoticesStates,
        districtCodes: publicNoticesDistricts,
        searchText: publicNoticesSearchText,
        pageNumber: publicNoticesPageNumber,
        itemsPerPage: publicNoticesItemsPerPage,
        sortColumn: sortColumn,
        sortOrder: sortOrder,
      };
      doFetchPublicNotices(fetchData);
    };

    const handleExportCSV = () => {
      const fetchData = {
        states: publicNoticesStates,
        districtCodes: publicNoticesDistricts,
        searchText: publicNoticesSearchText,
        sortColumn: sorting.column || 'daNumber',
        sortOrder: sorting.order || 'asc',
      };
      doExportPublicNoticesCSV(fetchData);
    };

    const handleHeaderClick = (e, header) => {
      if (e.target.closest('.info-tooltip-icon')) {
        e.stopPropagation();
        return;
      }
      if (header.column.columnDef.enableSorting) {
        handleSort(header.column);
      }
    };

    // Initialize TanStack table
    const table = useReactTable({
      autoResetPageIndex: false,
      columns,
      data: publicNotices,
      defaultColumn: {
        minSize: 50,
        size: 400,
        maxSize: 750,
      },
      manualPagination: true,
      enableRowSelection: false,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getSortedRowModel: getSortedRowModel(),
      initialState: {
        columnVisibility: {
          applicantName: false,
          applicantCompany: false,
        },
        pagination: {
          pageIndex: 0,
          pageSize: publicNoticesItemsPerPage,
        },
      },
    });

    return (
      <div className='pn-table-container'>
        <Table bordered fullWidth>
          <thead>
            <>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={(e) => handleHeaderClick(e, header)}
                      colSpan={header.colSpan}
                      style={{
                        position: 'relative',
                        userSelect: 'none',
                      }}
                    >
                      <div
                        style={{
                          cursor: header.column.columnDef.enableSorting ? 'pointer' : 'default',
                          position: 'relative',
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.columnDef.meta?.tooltip && (
                          <Tooltip
                            header={header.column.columnDef.header}
                            content={header.column.columnDef.meta?.tooltip}
                          />
                        )}
                        {
                          {
                            asc: <Icon path={mdiArrowUp} size={'16px'} />,
                            desc: <Icon path={mdiArrowDown} size={'16px'} />,
                          }[sorting.column === header.id ? sorting.order : null]
                        }
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </>
          </thead>
          <tbody>
            {table.getRowModel()?.rows?.length === 0 && (
              <tr>
                <td colSpan='100%'>
                  <div>NO ROWS TO DISPLAY</div>
                </td>
              </tr>
            )}
            {table.getRowModel()?.rows?.map((row) => (
              <tr key={row.id} className={row.getIsSelected() ? 'selected-row' : ''}>
                {row.getVisibleCells().map((cell) => (
                  <td key={`${row.id}-${cell.id}`} style={{ width: cell?.column?.columnDef?.size + 'px' }}>
                    <div className={`pn-cell d-flex ${cell?.column?.columnDef?.meta?.centerText ? 'center-text' : ''}`}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
        {/* Pagination */}
        <div className='footer-container'>
          <div className='pagination-container'>
            <PublicNoticesTableFooter table={table} />
          </div>
          <Button className='action-button button-small' title='Export CSV' type='button' onClick={handleExportCSV}>
            <Icon path={mdiFileExport} {...iconProps} />
            Export CSV
          </Button>
        </div>
      </div>
    );
  }
);

export default PublicNoticesTable;
