import { useEffect } from 'react';
import { connect } from 'redux-bundler-react';
import { toast } from 'react-toastify';
import Toggle from '@components/toggle/Toggle';

import { Button } from '@trussworks/react-uswds';
import TextAreaInput from '@components/textarea/TextArea';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

const schema = yup.object().shape({
  banner: yup.string().required('Field is required'),
});

const BannerSettings = connect(
  'doFetchBannerInfo',
  'doUpdateBannerText',
  'doUpdateBannerStatus',
  'selectBannerData',
  ({ doFetchBannerInfo, doUpdateBannerText, doUpdateBannerStatus, bannerData }) => {
    const defaultValues = {
      banner: bannerData?.banner ?? null,
      isBannerOn: bannerData?.isBannerOn ?? false,
    };

    const methods = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(schema),
      mode: 'onBlur',
      stateOptions: [],
    });
    const {
      formState: { isValid },
      watch,
      trigger,
      setValue,
    } = methods;

    const banner = watch('banner');
    const isBannerOn = watch('isBannerOn');

    const handleSubmit = () => {
      // Update banner text
      if (isValid) {
        doUpdateBannerText(banner);
      } else {
        trigger();
        toast.error('Please fill out all required fields!');
      }
    };

    const handleBannerStatus = (val) => {
      setValue('isBannerOn', val);
      doUpdateBannerStatus(val);
    };

    useEffect(() => {
      doFetchBannerInfo();
    }, [isBannerOn, doFetchBannerInfo]);

    const onLabel = <span className={`banner-toggle-label enabled ${isBannerOn ? 'selected' : null}`}>Enabled</span>;
    const offLabel = <span className={`banner-toggle-label disabled ${isBannerOn ? null : 'selected'}`}>Disabled</span>;

    return (
      <FormProvider {...methods}>
        <div>
          <div style={{ width: '740px' }}>
            <TextAreaInput name='banner' label='Banner Message' required />
          </div>
          <Button title='Update Banner' size='small' onClick={() => handleSubmit()} style={{ marginTop: '1rem' }}>
            Update Banner
          </Button>

          <div className='usa-label' style={{ marginTop: '2rem' }}>
            Current Status:
          </div>
          <Toggle onChange={handleBannerStatus} checked={isBannerOn} onLabel={onLabel} offLabel={offLabel} />
        </div>
      </FormProvider>
    );
  }
);

export default BannerSettings;
