import { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';

import GPFormInstructions from '@forms/static-forms/GPForm2bInstructions';
import SupportingInformation from '@forms/input-forms/common/SupportingInformation';
import PermitsFormContacts from '@forms/input-forms/PermitsForm/PermitsFormContacts';
import PermitsFormGeneralProjectInformation from '@src/app-pages/Forms/input-forms/PermitsForm/PermitsFormGeneralProjectInformation/GPFormGeneralProjectInformation';
import PermitsFormOtherLawsRegulations from '@forms/input-forms/PermitsForm/PermitsFormOtherLawsRegulations/PermitsFormOtherLawsRegulations';
import PermitsFormAquaticResources from '@forms/input-forms/PermitsForm/PermitsFormAquaticResources/PermitsFormAquaticResources';
import PermitsFormImpactsMitigation from '@forms/input-forms/PermitsForm/PermitsFormImpactsMitigation';
import PermitsFormCertifySignSubmit from '@forms/input-forms/PermitsForm/PermitsFormCertifySignSubmit';

//List of steps for vertical stepper, these can also be used for form header. index = step ID
export const GPForm2bMetadata = [
  GPFormInstructions.metadata,
  PermitsFormContacts.metadata,
  PermitsFormGeneralProjectInformation.metadata,
  PermitsFormOtherLawsRegulations.metadata,
  PermitsFormAquaticResources.metadata,
  PermitsFormImpactsMitigation.metadata,
  SupportingInformation.metadata,
  PermitsFormCertifySignSubmit.metadata,
];

const GPForm2b = connect(
  'doUpdatePermitRequest',
  'selectActiveStep',
  'selectPermitRequestID',
  'selectPermitData',
  ({ doUpdatePermitRequest, activeStep, permitRequestID, permitData, edit }) => {
    const [showInstructions, setShowInstructions] = useState(false);
    const [showContactInfo, setShowContactInfo] = useState(false);
    const [showGeneralProjectInfo, setShowGeneralProjectInfo] = useState(false);
    const [showOtherLawsAndRegulations, setShowOtherLawsAndRegulations] = useState(false);
    const [showAquaticResources, setShowAquaticResources] = useState(false);
    const [showProjectImpactsAndMitigation, setShowProjectImpactsAndMitigation] = useState(false);
    const [showSupportingInfo, setShowSupportingInfo] = useState(false);
    const [showCertifySignSubmit, setShowCertifySignSubmit] = useState(false);

    //display only active step, hide all other steps
    useEffect(() => {
      if (activeStep) {
        switch (activeStep.id) {
          case 0: {
            setShowInstructions(true);
            setShowContactInfo(false);
            setShowGeneralProjectInfo(false);
            setShowOtherLawsAndRegulations(false);
            setShowAquaticResources(false);
            setShowProjectImpactsAndMitigation(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 1: {
            setShowInstructions(false);
            setShowContactInfo(true);
            setShowGeneralProjectInfo(false);
            setShowOtherLawsAndRegulations(false);
            setShowAquaticResources(false);
            setShowProjectImpactsAndMitigation(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 2: {
            setShowInstructions(false);
            setShowContactInfo(false);
            setShowGeneralProjectInfo(true);
            setShowOtherLawsAndRegulations(false);
            setShowAquaticResources(false);
            setShowProjectImpactsAndMitigation(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 3: {
            setShowInstructions(false);
            setShowContactInfo(false);
            setShowGeneralProjectInfo(false);
            setShowOtherLawsAndRegulations(true);
            setShowAquaticResources(false);
            setShowProjectImpactsAndMitigation(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 4: {
            setShowInstructions(false);
            setShowContactInfo(false);
            setShowGeneralProjectInfo(false);
            setShowOtherLawsAndRegulations(false);
            setShowAquaticResources(true);
            setShowProjectImpactsAndMitigation(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 5: {
            setShowInstructions(false);
            setShowContactInfo(false);
            setShowGeneralProjectInfo(false);
            setShowOtherLawsAndRegulations(false);
            setShowAquaticResources(false);
            setShowProjectImpactsAndMitigation(true);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 6: {
            setShowInstructions(false);
            setShowContactInfo(false);
            setShowGeneralProjectInfo(false);
            setShowOtherLawsAndRegulations(false);
            setShowAquaticResources(false);
            setShowProjectImpactsAndMitigation(false);
            setShowSupportingInfo(true);
            setShowCertifySignSubmit(false);
            break;
          }
          case 7: {
            setShowInstructions(false);
            setShowContactInfo(false);
            setShowGeneralProjectInfo(false);
            setShowOtherLawsAndRegulations(false);
            setShowAquaticResources(false);
            setShowProjectImpactsAndMitigation(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(true);
            break;
          }
          default:
            setShowInstructions(true);
            setShowContactInfo(false);
            setShowGeneralProjectInfo(false);
            setShowOtherLawsAndRegulations(false);
            setShowAquaticResources(false);
            setShowProjectImpactsAndMitigation(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
        }
      }
    }, [activeStep]);

    const instructionStyles = { display: showInstructions ? 'inline' : 'none' };
    const generalProjInfoStyles = { display: showGeneralProjectInfo ? 'inline' : 'none' };
    const contactInfoStyles = { display: showContactInfo ? 'inline' : 'none' };
    const otherLawsAndRegulationsStyles = { display: showOtherLawsAndRegulations ? 'inline' : 'none' };
    const ARStyles = { display: showAquaticResources ? 'inline' : 'none' };
    const projectImpactsAndMitigationStyles = { display: showProjectImpactsAndMitigation ? 'inline' : 'none' };
    const supportingInfoStyles = { display: showSupportingInfo ? 'inline' : 'none' };
    const certifySignSubmitStyles = { display: showCertifySignSubmit ? 'inline' : 'none' };

    return (
      <>
        <section id='GP2bInstructions' name='Instructions' style={instructionStyles}>
          <GPFormInstructions stepNo={0} />
        </section>
        <section id='GPFormContactInformation' name='Contact Information' style={contactInfoStyles}>
          <PermitsFormContacts componentID={permitRequestID} edit={edit} stepNo={1} />
        </section>
        <section id='GPFormGeneralProjectInformation' name='General Project Information' style={generalProjInfoStyles}>
          <PermitsFormGeneralProjectInformation stepNo={2} />
        </section>
        <section id='GPOtherLawsAndRegulations' name='Other Laws And Regulations' style={otherLawsAndRegulationsStyles}>
          <PermitsFormOtherLawsRegulations stepNo={3} />
        </section>
        <section id='GPFormAquaticResources' name='Aquatic Resources' style={ARStyles}>
          <PermitsFormAquaticResources stepNo={4} />
        </section>
        <section
          id='GPProjectImpactsAndMitigation'
          name='Project Impact and Mitigation'
          style={projectImpactsAndMitigationStyles}
        >
          <PermitsFormImpactsMitigation stepNo={5} />
        </section>
        <section id='GP2bSupportingInformation' name='Supporting Information' style={supportingInfoStyles}>
          <SupportingInformation
            componentID={permitRequestID}
            subtext={
              <>
                <p>
                  Please upload supporting documentation including project plans, state water certifications, etc.
                  Multiple documents of the same file type can be combined and uploaded as one document.
                </p>
              </>
            }
            requestData={permitData}
            updateRequestData={doUpdatePermitRequest}
            stepNo={6}
          />
        </section>
        <section id='GP2bCertifySignSubmit' name='Certify Sign Submit' style={certifySignSubmitStyles}>
          <PermitsFormCertifySignSubmit stepNo={7} />
        </section>
      </>
    );
  }
);

export default GPForm2b;
