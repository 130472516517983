import { ImpactsResourceType } from '@src/utils/enums';
import { useState, useEffect, useCallback, useRef, useMemo } from 'react';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const AmountUnitTableCell = ({ getValue, row, column, table, cell }) => {
  const columnMeta = column.columnDef.meta;
  const tableMeta = table.options.meta;
  const initialValue = getValue();
  const initialOptions = columnMeta?.options[0];
  const [value, setValue] = useState(initialValue);
  const [amountOptions, setAmountOptions] = useState(initialOptions);
  const [amountType, setAmountType] = useState();
  const rowAmountType = useMemo(() => row.getValue('amountType'), [row]);
  const rowResourceType = useMemo(() => row.getValue('resourceType'), [row]);
  const debouncedUpdateRef = useRef();
  const isInitialMount = useRef(true);

  useEffect(() => {
    debouncedUpdateRef.current = debounce((newValue) => {
      if (tableMeta?.updateData) {
        tableMeta.updateData(row.index, column.id, newValue);
      }
    }, 500);
  }, [row.index, column.id, tableMeta?.updateData, columnMeta?.type, tableMeta]);

  const updateValue = useCallback((newValue) => {
    debouncedUpdateRef.current(newValue);
  }, []);

  const handleChange = (e) => {
    setValue(e.target.value);
    updateValue(e.target.value);
  };

  useEffect(() => {
    rowAmountType && setAmountType(rowAmountType);
  }, [rowAmountType]);

  useEffect(() => {
    if (amountType?.includes('Volume') && initialValue !== 'Cubic Yards') {
      value !== 'Cubic Yards' && setValue('Cubic Yards');
      value !== 'Cubic Yards' && tableMeta?.updateData(row.index, column.id, 'Cubic Yards');
    }
  }, [amountOptions, amountType, column.id, row.index, tableMeta, initialValue, value]);

  // River/Stream amount unit option
  useEffect(() => {
    if (rowResourceType === ImpactsResourceType.RiverStream && amountType?.includes('Area')) {
      setAmountOptions([{ value: 'Square Feet', label: 'Square Feet' }]);
    } else {
      setAmountOptions(columnMeta?.options[0]);
    }
  }, [rowResourceType, amountType, columnMeta?.options]);

  // Area/Volume amount unit options
  useEffect(() => {
    const options = amountType?.includes('Area') ? columnMeta?.options[0] : columnMeta?.options[1];
    amountOptions?.[0]?.value !== options?.[0]?.value && setAmountOptions(options);
  }, [amountType, columnMeta?.options, amountOptions]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (!amountType?.includes('Volume') && value === 'Cubic Yards') {
      setValue('');
      tableMeta?.updateData(row.index, column.id, '');
      tableMeta?.updateData(row.index, 'proposedAmount', null);
    }
  }, [amountType, value, column.id, row.index, tableMeta]);

  return (
    <select
      aria-label={'Area Units'}
      disabled={columnMeta?.readOnly}
      id={cell.id}
      onChange={handleChange}
      required={columnMeta?.required}
      style={{ width: '100%' }}
      value={value ?? ''}
    >
      <option key={0} value='' className='none' style={{ display: 'none' }}>
        -- Select a value --
      </option>
      {amountOptions?.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default AmountUnitTableCell;
