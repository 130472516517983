import { useCallback, useEffect, useMemo, useState } from 'react';
import useErrorFocus from '@hooks/useErrorFocus';
import { connect } from 'redux-bundler-react';
import { createColumnHelper } from '@tanstack/react-table';
import { Alert, Label } from '@trussworks/react-uswds';

import { EditCell } from '@components/table/tableCellComponents/EditCell';
import { TableCell } from '@components/table/tableCellComponents/TableCell';
import { PermitDateTableCell } from '@components/table/tableCellComponents/PermitDateTableCell';

import FieldHeader from '@forms/components/Form/FieldHeader';
import statusModal from '@forms/components/modals/statusModal';
import TextInput from '@components/text-input/TextInput';
import TextAreaInput from '@components/textarea/TextArea';
import SelectInput from '@components/select/Select';
import CreatableSelectInput from '@components/new-inputs/creatableSelectInput';
import TanStackTableNew from '@components/table/TanStackTable/TanStackTableNew';
import ExternalLink from '@components/external-link/ExternalLink';
import DateRange from '@components/date-range/DateRange';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import Checkbox from '@components/checkbox/Checkbox';

import PermitsDredgeProject from './PermitsFormGeneralProjectInformation.dredgeProject';
import PermitsDredgeTransportation from './PermitsFormGeneralProjectInformation.dredgeTransportation';
import PermitsDischarges from './PermitsFormGeneralProjectInformation.discharges';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import { ormErrorMsg, devTypeTooltipContent, otherCertsContent } from '@forms/input-forms/_helper';
import { decimalNumberRegex, integerRegex, latRegex, lngRegex, setNumberValue } from '@src/utils/regex';
import { ApplicableStatutoryAuthorities } from '@src/utils/enums';
import {
  dateBeforeA,
  dateBeforeB,
  filterNullEmptyObjects,
  formatCoordFlt,
  isDateValid,
  isValidORMNumber,
} from '@src/utils/helpers';

import '@styles/index.scss';

export const IPFormGeneralProjectInformationMetadata = {
  sectionName: 'General Project Information',
  isSection: true,
  lastSection: false,
  firstSection: false,
};

const currentEnv = import.meta.env.VITE_ENVIRONMENT;

const tooltipText = {
  activityBaselineRelationship:
    "The baseline along a state's shoreline is usually established by the mean low water mark (where the shore directly contacts the open sea, the line on the shore reached by the ordinary low tides 33 CFR 329.12(a)(1).  The limit of jurisdiction in the territorial seas refers to the maximum distance from the baseline up to which a coastal state exercises its sovereignty and control; generally 3 miles along the United States coast.",
  doesPilingDischargeFillMaterial:
    'Examples of such activities that have the effect of a discharge of fill material include, but are not limited to, the following: Projects where the pilings are so closely spaced that sedimentation rates would be increased; projects in which the pilings themselves effectively would replace the bottom of a waterbody; projects involving the placement of pilings that would reduce the reach or impair the flow or circulation of waters of the United States; and projects involving the placement of pilings which would result in the adverse alteration or elimination of aquatic functions. 33 CFR 323.3(c)',
  isAnyWorkComplete:
    'Provide any background on any part of the proposed project already completed. Describe the area already developed, structures completed, any dredged or fill material already discharged, the type of material, volume in cubic yards, acres filled, if a wetland or other waterbody (in acres or square feet). If the work was done under an existing USACE permit, identity the authorization (i.e., file number), if possible.',
  natureOfActivity:
    'Describe the overall activity or project. Give appropriate dimensions of structures/fills such as wing walls, dikes, (identify the materials to be used in construction, as well as the methods by which the work is to be done), or excavation (length, width, and depth). If the project would include the construction of a filled or pile or float-supported platform, you must include a description of the use of, and specific structures to be erected on, the fill or platform.',
  previousFileNumbers: 'Enter the file number and press "ENTER" or click on "Create ..."',
  projectDescription:
    'Describe the proposed activity, including the direct and indirect adverse environmental effects the activity would cause, its purpose, and intended use, so as to provide sufficient information to generate meaningful comments from the appropriate agencies as well as the general public. The description of the proposed activity should also include information on how the activity has been designed to avoid and minimize impacts to WOTUS to the maximum extent practicable. Identify the material(s) to be used in construction, as well as the methods by which the work is to be done.',
  projectName:
    'Please provide name identifying the proposed project, e.g., Landmark Plaza, Burned Hills Subdivision, or Edsall Commercial Center',
  projectPurpose:
    'Describe the purpose and need for the proposed project. What will it be used for and why? Also include a brief description of any related activities to be developed as the result of the proposed project.',
};

const IPFormGeneralProjectInformation = connect(
  'doUpdateSectionValidity',
  'doUpdatePermitRequest',
  'doUpdatePermitRequestSub',
  'doClearPileDataByUse',
  'doUpdateRequestData',
  'doSecondaryModalOpen',
  'selectRequestFormData',
  'selectPermitData',
  'selectSteps',
  'selectActiveStep',
  'selectRequestAPIData',
  'selectIsReadOnly',
  'selectLookupTableDivisionsAndDistricts',
  ({
    doUpdateSectionValidity,
    doUpdatePermitRequest,
    doUpdatePermitRequestSub,
    doClearPileDataByUse,
    doUpdateRequestData,
    doSecondaryModalOpen,
    requestFormData,
    permitData,
    steps,
    activeStep,
    requestAPIData,
    isReadOnly,
    lookupTableDivisionsAndDistricts,
    stepNo,
  }) => {
    const [relatedAppsData, setRelatedAppsData] = useState([]);
    const [relatedAppsKey, setRelatedAppsKey] = useState(0);
    const [relatedAppsRowErrors, setRelatedAppsRowErrors] = useState(false);
    const columnHelper = createColumnHelper();
    const thisSectionStepStatus = useMemo(() => steps.find((step) => step.id === stepNo)?.touched, [steps, stepNo]);
    const [dredgeProjectData, setDredgeProjectData] = useState({});
    const district =
      lookupTableDivisionsAndDistricts?.filter((item) => item.orgName === requestFormData?.location?.district)?.[0] ??
      null;

    const convertFileNumbersToSelectFormat = (data) =>
      data && data.map((obj) => ({ label: obj.ormFileNumber, value: obj.ormFileNumber }));

    const populateDredgeTypes = () => {
      const dredgeTypes = [];
      if (permitData?.dredgeProject?.isDredgeTypeMaintenance) {
        dredgeTypes.push('Maintenance');
      }
      if (permitData?.dredgeProject?.isDredgeTypeNew) {
        dredgeTypes.push('New');
      }
      return dredgeTypes;
    };

    const relatedAppsValidationSchema = yup.object().shape({
      agency: yup.string().required('Field is required'),
      approvalType: yup.string().required('Field is required'),
      certIDNumber: yup.string().required('Field is required'),
      appliedDate: yup.string().required('Please select a date'),
      approvalDate: yup.string().nullable(),
      deniedDate: yup.string().nullable(),
    });

    const relatedAppsTableLength = relatedAppsData?.length;

    const schema = (relatedAppsTableLength) =>
      yup.object().shape(
        {
          projectName: yup.string().required('Field is required'),
          previousFileNumbersExist: yup.string().required('Please select an option'),
          previousFileNumbers: yup.array().when('previousFileNumbersExist', {
            is: 'true',
            then: () =>
              yup
                .array()
                .min(1, 'At least one Previous File Number is required')
                .required('At least one Previous File Number is required'),
          }),
          requestedPermitType: yup.string().required('Please select an option'),
          developmentType: yup.string().required('Please select an option'),
          hasRelatedAgencyApplications: yup.string().required('Please select an option'),
          relatedAppsTable: yup.boolean().when('hasRelatedAgencyApplications', {
            is: (val) => val === 'true' && relatedAppsTableLength < 1,
            then: () =>
              yup
                .boolean()
                .required(
                  'Previous Permits, Certificates... : At least one certificate, approval, or denial is required'
                ),
          }),
          projectDescription: yup
            .string()
            .test(
              'isDefault',
              'DEFAULT_VALUE is a reserved word, please enter a valid string',
              (val) => val.toUpperCase() !== 'DEFAULT_VALUE'
            )
            .required('Field is required'),
          natureOfActivity: yup
            .string()
            .test(
              'isDefault',
              'DEFAULT_VALUE is a reserved word, please enter a valid string',
              (val) => val.toUpperCase() !== 'DEFAULT_VALUE'
            )
            .required('Field is required'),
          projectPurpose: yup
            .string()
            .test(
              'isDefault',
              'DEFAULT_VALUE is a reserved word, please enter a valid string',
              (val) => val.toUpperCase() !== 'DEFAULT_VALUE'
            )
            .required('Field is required'),
          projectWorkStartDate: yup.string().required('Please select a date'),
          projectWorkEndDate: yup.string().required('Please select a date'),
          isAnyWorkComplete: yup.string().required('Please select an option'),
          completedWorkDescription: yup
            .string()
            .when('isAnyWorkComplete', { is: 'true', then: () => yup.string().required('Field is required') })
            .nullable(),
          navigableWatersAdditionalInfo: yup.string().nullable(),
          applicableStatutoryAuthority: yup.array().min(1, 'Please select at least one option'),
          //IP Specific
          isCoastalDistrict: yup.boolean(),
          isInTerrSeasOrOceanWaters: yup.string().when('isCoastalDistrict', {
            is: true,
            then: (schema) => schema.required('Please select an option'),
            otherwise: (schema) => schema.nullable(),
          }),
          activityBaselineRelationship: yup
            .string()
            .when('isInTerrSeasOrOceanWaters', { is: 'true', then: () => yup.string().required('Field is required') })
            .nullable(),
          //Discharges
          dischargeMaterialSource: yup
            .string()
            .when('applicableStatutoryAuthority', {
              is: (val) => val?.includes(ApplicableStatutoryAuthorities.Section404),
              then: () => yup.string().required('Field is required'),
            })
            .nullable(),
          dischargeReason: yup
            .string()
            .when('applicableStatutoryAuthority', {
              is: (val) => val?.includes(ApplicableStatutoryAuthorities.Section404),
              then: () => yup.string().required('Field is required'),
            })
            .nullable(),
          //DREDGING
          isDredgingNavWaters: yup
            .string()
            .when('applicableStatutoryAuthority', {
              is: (val) => val?.includes(ApplicableStatutoryAuthorities.Section10),
              then: () => yup.string().required('Please select an option'),
            })
            .nullable(),
          materialTypeComposition: yup
            .string()
            .when('isDredgingNavWaters', { is: 'true', then: () => yup.string().required('Field is required') })
            .nullable(),
          dredgeMethod: yup
            .string()
            .when('isDredgingNavWaters', { is: 'true', then: () => yup.string().required('Field is required') })
            .nullable(),
          constructionMethods: yup
            .string()
            .when('isDredgingNavWaters', { is: 'true', then: () => yup.string().required('Field is required') })
            .nullable(),
          estimatedNumberOfEvents: yup
            .string()
            .when(['estimatedNumberOfEvents', 'isDredgingNavWaters'], (estimatedNumberOfEvents, isDredgingNavWaters) =>
              estimatedNumberOfEvents && isDredgingNavWaters === 'true'
                ? yup.string().matches(integerRegex, { message: 'Must be an integer', excludeEmptyString: true })
                : yup.string().nullable()
            ),
          workStartDate: yup
            .string()
            .when('isDredgingNavWaters', { is: 'true', then: () => yup.string().required('Please select a date') })
            .nullable(),
          workEndDate: yup
            .string()
            .when('isDredgingNavWaters', { is: 'true', then: () => yup.string().required('Please select a date') })
            .nullable()
            .test({
              test: (value, { parent: { isDredgingNavWaters } }) =>
                isDredgingNavWaters === 'true' ? value !== '' : true,
              message: 'Please select a date',
            })
            .test({
              test: (value, { parent: { workStartDate } }) => !value || new Date(value) >= new Date(workStartDate),
              message: 'The end date must be after the start date',
            }),
          durationOfWork: yup
            .string()
            .when('isDredgingNavWaters', {
              is: 'true',
              then: () =>
                yup
                  .number()
                  .required('Field is required')
                  .typeError('Must be an integer.')
                  .positive('Value must be > 0'),
            })
            .nullable(),
          unitOfWork: yup
            .string()
            .when('isDredgingNavWaters', { is: 'true', then: () => yup.string().required('Please select an option') })
            .nullable(),
          currentDepth: yup
            .string()
            .when('isDredgingNavWaters', {
              is: 'true',
              then: () => yup.string().required('Field is required').matches(decimalNumberRegex, 'Value is invalid'),
            })
            .nullable(),
          dredgeType: yup
            .array()
            .when('isDredgingNavWaters', {
              is: 'true',
              then: () => yup.array().min(1, 'Please select at least one value'),
            })
            .nullable(),
          lastDredgeCycle: yup
            .string()
            .when('dredgeType', {
              is: (val) => val?.includes('Maintenance'),
              then: () => yup.string().required('Please select a date'),
            })
            .nullable(),
          authorizedLength: yup
            .string()
            .when('dredgeType', {
              is: (val) => val?.length > 0,
              then: () => yup.string().required('Field is required').matches(decimalNumberRegex, 'Field is invalid'),
            })
            .nullable(),
          authorizedWidth: yup
            .string()
            .when('dredgeType', {
              is: (val) => val?.length > 0,
              then: () => yup.string().required('Field is required').matches(decimalNumberRegex, 'Field is invalid'),
            })
            .nullable(),
          authorizedDepth: yup
            .string()
            .when('dredgeType', {
              is: (val) => val?.length > 0,
              then: () => yup.string().required('Field is required').matches(decimalNumberRegex, 'Field is invalid'),
            })
            .nullable(),
          disposalPlan: yup
            .string()
            .when('dredgeType', {
              is: (val) => val?.length > 0,
              then: () => yup.string().required('Value is required'),
            })
            .nullable(),
          estNumDisposalTrips: yup
            .string()
            .when(['estNumDisposalTrips', 'dredgeType'], (estNumDisposalTrips, dredgeType) =>
              estNumDisposalTrips && dredgeType?.length > 0
                ? yup.string().matches(integerRegex, { message: 'Must be an integer', excludeEmptyString: true })
                : yup.string().nullable()
            ),
          dredgeDisposalLatitude: yup
            .mixed()
            .when('dredgeType', {
              is: (val) => val?.length > 0,
              then: () =>
                yup
                  .string()
                  .required('Field is required')
                  .matches(latRegex, 'Format is incorrect. Must be +-XX.XXXXXX and include at least 6 decimal places.'),
            })
            .nullable(),
          dredgeDisposalLongitude: yup
            .mixed()
            .when('dredgeType', {
              is: (val) => val?.length > 0,
              then: () =>
                yup
                  .string()
                  .required('Field is required')
                  .matches(
                    lngRegex,
                    'Format is incorrect. Must be +-XXX.XXXXXX and include at least 6 decimal places.'
                  ),
            })
            .nullable(),
          // DREDGE TRANSPORTATION
          isSiteDesignatedForDISP: yup
            .string()
            .when('applicableStatutoryAuthority', {
              is: (val) => val?.includes(ApplicableStatutoryAuthorities.Section103),
              then: () => yup.string().required('Please select an option'),
            })
            .nullable(),
          disposalSiteCharacteristics: yup
            .string()
            .when('isSiteDesignatedForDISP', { is: 'false', then: () => yup.string().required('Please enter a value') })
            .nullable(),
          knownDredgeMaterialDischarges: yup
            .string()
            .when('applicableStatutoryAuthority', {
              is: (val) => val?.includes(ApplicableStatutoryAuthorities.Section103),
              then: () => yup.string().required('Field is required'),
            })
            .nullable(),
          previousDisposals: yup
            .string()
            .when('applicableStatutoryAuthority', {
              is: (val) => val?.includes(ApplicableStatutoryAuthorities.Section103),
              then: () => yup.string().required('Field is required'),
            })
            .nullable(),
          estNumberOfMonthsOnSite: yup
            .string()
            .when('applicableStatutoryAuthority', {
              is: (val) => val?.includes(ApplicableStatutoryAuthorities.Section103),
              then: () => yup.string().matches(integerRegex, 'Must be an integer').required('Field is required'),
            })
            .nullable(),
          materialSource: yup
            .string()
            .when('applicableStatutoryAuthority', {
              is: (val) => val?.includes(ApplicableStatutoryAuthorities.Section103),
              then: () => yup.string().required('Field is required'),
            })
            .nullable(),
          materialTypeAndComp: yup
            .string()
            .when('applicableStatutoryAuthority', {
              is: (val) => val?.includes(ApplicableStatutoryAuthorities.Section103),
              then: () => yup.string().required('Field is required'),
            })
            .nullable(),
          transportationAndDisposalMethod: yup
            .string()
            .when('applicableStatutoryAuthority', {
              is: (val) => val?.includes(ApplicableStatutoryAuthorities.Section103),
              then: () => yup.string().required('Field is required'),
            })
            .nullable(),
        },
        [
          ['estimatedNumberOfEvents', 'estimatedNumberOfEvents'],
          ['estNumDisposalTrips', 'estNumDisposalTrips'],
          ['existingVesselSlips', 'existingVesselSlips'],
        ]
      );

    const defaultValues = {
      projectName: requestFormData?.projectName,
      previousFileNumbersExist: requestFormData?.previousFileNumbers?.length > 0 ? 'true' : 'false',
      previousFileNumbers:
        requestFormData?.previousFileNumbers?.length > 0
          ? convertFileNumbersToSelectFormat(requestFormData.previousFileNumbers)
          : [],
      requestedPermitType: permitData?.requestedPermitType ?? '',
      developmentType: permitData?.developmentType ?? '',
      hasRelatedAgencyApplications:
        permitData?.hasRelatedAgencyApplications !== undefined && permitData?.hasRelatedAgencyApplications !== null
          ? permitData.hasRelatedAgencyApplications
            ? 'true'
            : 'false'
          : '',
      projectDescription: requestFormData?.projectDescription ?? '',
      projectPurpose: requestFormData?.projectPurpose ?? '',
      natureOfActivity: requestFormData?.natureOfActivity ?? '',
      projectWorkStartDate: permitData?.workStartDate?.split('T')?.[0] ?? '',
      projectWorkEndDate: permitData?.workEndDate?.split('T')?.[0] ?? '',
      applicableStatutoryAuthority: permitData?.applicableStatutoryAuthorities ?? [],
      relatedAgencyApplications: permitData?.relatedAgencyApplications
        ? permitData?.relatedAgencyApplications?.map((item) => ({
            ...item,
            appliedDate: item?.appliedDate?.split('T')?.[0],
            approvedDate: item?.approvedDate?.split('T')?.[0],
            deniedDate: item?.deniedDate?.split('T')?.[0],
          }))
        : [],
      navigableWatersAdditionalInfo: permitData?.navigableWatersAdditionalInfo ?? '',
      isAnyWorkComplete:
        permitData?.isAnyWorkComplete !== undefined && permitData?.isAnyWorkComplete !== null
          ? permitData.isAnyWorkComplete
            ? 'true'
            : 'false'
          : '',
      completedWorkDescription: permitData?.completedWorkDescription ?? '',
      // Dredge Project
      isDredgingNavWaters:
        permitData?.isDredgingNavWaters !== undefined && permitData?.isDredgingNavWaters !== null
          ? permitData.isDredgingNavWaters
            ? 'true'
            : 'false'
          : '',
      materialTypeComposition: permitData?.dredgeProject?.materialTypeComposition ?? '',
      dredgeMethod: permitData?.dredgeProject?.dredgeMethod ?? '',
      constructionMethods: permitData?.dredgeProject?.constructionMethods ?? '',
      estimatedNumberOfEvents: permitData?.dredgeProject?.estimatedNumberOfEvents ?? '',
      workStartDate: permitData?.dredgeProject?.workStartDate?.split('T')?.[0] ?? '',
      workEndDate: permitData?.dredgeProject?.workEndDate?.split('T')?.[0] ?? '',
      durationOfWork: permitData?.dredgeProject?.durationOfWork ?? null,
      unitOfWork: permitData?.dredgeProject?.unitOfWork ?? '',
      currentDepth: permitData?.dredgeProject?.currentDepth ?? '',
      dredgeType: populateDredgeTypes(),
      lastDredgeCycle: permitData?.dredgeProject?.lastDredgeCycle?.split('T')?.[0] ?? '',
      authorizedLength: permitData?.dredgeProject?.authorizedLength ?? '',
      authorizedWidth: permitData?.dredgeProject?.authorizedWidth ?? '',
      authorizedDepth: permitData?.dredgeProject?.authorizedDepth ?? '',
      disposalPlan: permitData?.dredgeProject?.disposalPlan ?? '',
      estNumDisposalTrips: permitData?.dredgeProject?.estNumDisposalTrips ?? '',
      dredgeDisposalLatitude:
        typeof permitData?.dredgeProject?.dredgeDisposalLatitude === 'number'
          ? permitData?.dredgeProject?.dredgeDisposalLatitude?.toFixed(7)
          : (permitData?.dredgeProject?.dredgeDisposalLatitude ?? ''),
      dredgeDisposalLongitude:
        typeof permitData?.dredgeProject?.dredgeDisposalLongitude === 'number'
          ? permitData?.dredgeProject?.dredgeDisposalLongitude?.toFixed(7)
          : (permitData?.dredgeProject?.dredgeDisposalLongitude ?? ''),
      // Pile Driving and Coastal Shoreline Stabilization Manual Overwrite
      includesInstallPiling:
        permitData?.includesInstallPiling !== undefined && permitData?.includesInstallPiling !== ''
          ? permitData.includesInstallPiling
            ? 'true'
            : 'false'
          : 'false',
      includesShorelineStabilization:
        permitData?.includesShorelineStabilization !== undefined && permitData?.includesShorelineStabilization !== ''
          ? permitData.includesShorelineStabilization
            ? 'true'
            : 'false'
          : 'false',
      // Dredge Transportation
      isSiteDesignatedForDISP: permitData?.dredgedTransportation?.isSiteDesignatedForDISP?.toString() || '',
      knownDredgeMaterialDischarges: permitData?.dredgedTransportation?.knownDredgeMaterialDischarges ?? '',
      disposalSiteCharacteristics: permitData?.dredgedTransportation?.disposalSiteCharacteristics ?? '',
      previousDisposals: permitData?.dredgedTransportation?.previousDisposals ?? '',
      estNumberOfMonthsOnSite: permitData?.dredgedTransportation?.estNumberOfMonthsOnSite ?? '',
      materialSource: permitData?.dredgedTransportation?.materialSource ?? '',
      materialTypeAndComp: permitData?.dredgedTransportation?.materialTypeAndComp ?? '',
      transportationAndDisposalMethod: permitData?.dredgedTransportation?.transportationAndDisposalMethod ?? '',
      //Discharges
      dischargeMaterialSource: permitData?.discharges_404?.materialSource ?? '',
      dischargeReason: permitData?.discharges_404?.dischargeReason ?? '',
      //IP Specific
      isCoastalDistrict: district?.isCoastalDistrict ?? false,
      isInTerrSeasOrOceanWaters:
        permitData?.isInTerrSeasOrOceanWaters !== undefined && permitData?.isInTerrSeasOrOceanWaters !== null
          ? permitData.isInTerrSeasOrOceanWaters
            ? 'true'
            : 'false'
          : '',
      activityBaselineRelationship: permitData?.activityBaselineRelationship ?? '',
    };

    const methods = useForm({
      resolver: yupResolver(schema(relatedAppsTableLength)),
      mode: 'onBlur',
      defaultValues: defaultValues,
    });
    const {
      formState: { isValid, errors },
      setValue,
      watch,
      getValues,
      setError,
      clearErrors,
      trigger,
    } = methods;
    const previousFileNumbersExist = watch('previousFileNumbersExist', 'false');
    const previousFileNumbers = watch('previousFileNumbers', []);

    const isAnyWorkComplete = watch('isAnyWorkComplete');
    const applicableStatutoryAuthority = watch('applicableStatutoryAuthority');
    const isDredgingNavWaters = watch('isDredgingNavWaters');
    const dredgeType = watch('dredgeType');
    const hasRelatedAgencyApplications = watch('hasRelatedAgencyApplications');
    const isSiteDesignatedForDISP = watch('isSiteDesignatedForDISP');
    const isInTerrSeasOrOceanWaters = watch('isInTerrSeasOrOceanWaters');
    const isCoastalDistrict = watch('isCoastalDistrict');

    const relatedAppsColumns = useMemo(
      () => [
        columnHelper.display({
          id: 'edit',
          cell: isReadOnly ? <></> : EditCell,
          size: 40,
          enableResizing: false,
          enableSorting: false,
        }),
        columnHelper.accessor('agency', {
          header: 'Agency Name',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
          size: 400,
          meta: {
            required: true,
          },
        }),
        columnHelper.accessor('approvalType', {
          header: 'Type of Approval',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
          size: 380,
          meta: {
            required: true,
          },
        }),
        columnHelper.accessor('certIDNumber', {
          header: 'Identification Number',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
          size: 300,
          meta: {
            required: true,
            maxLength: 100,
          },
        }),
        columnHelper.accessor('appliedDate', {
          header: 'Date Applied',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : PermitDateTableCell,
          size: 150,
          meta: {
            type: 'date',
            min: dateBeforeB,
            required: true,
          },
        }),
        columnHelper.accessor('approvedDate', {
          header: 'Date Approved',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : PermitDateTableCell,
          size: 150,
          meta: {
            type: 'date',
            min: dateBeforeB,
            required: false,
          },
        }),
        columnHelper.accessor('deniedDate', {
          header: 'Date Denied',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : PermitDateTableCell,
          size: 150,
          cellDisabled: true,
          meta: {
            type: 'date',
            min: dateBeforeB,
            required: false,
          },
        }),
      ],
      [columnHelper, isReadOnly]
    );

    const updateRelatedAppsData = useCallback(
      (rowIndex, columnId, updatedValue) => {
        setRelatedAppsData((oldData) => {
          const newData = oldData ? [...oldData] : null;
          if (newData && newData[rowIndex]) {
            // Update properties
            newData[rowIndex] = {
              ...newData[rowIndex],
              [columnId]: updatedValue,
            };
            return newData;
          }
        });
      },
      [setRelatedAppsData]
    );

    const removeMultipleRelatedAppRows = useCallback(
      (indicesToRemove) => {
        setRelatedAppsData((oldData) => {
          const newRows = oldData.filter((_, index) => !indicesToRemove.includes(index));
          return newRows;
        });
        setRelatedAppsKey((old) => old + 1);
      },
      [setRelatedAppsData, setRelatedAppsKey]
    );

    useEffect(() => {
      relatedAppsData && doUpdatePermitRequest({ relatedAgencyApplications: relatedAppsData });
    }, [relatedAppsData, doUpdatePermitRequest]);

    // Update Dredge Project data in Permit Request Body
    useEffect(() => {
      dredgeProjectData && doUpdatePermitRequest({ dredgeProject: dredgeProjectData });
    }, [dredgeProjectData, doUpdatePermitRequest]);

    // Load data from API
    useEffect(() => {
      if (requestAPIData?.request?.permits?.[0]?.relatedAgencyApplications) {
        setRelatedAppsData(
          requestAPIData?.request?.permits?.[0]?.relatedAgencyApplications.map((item) => ({
            ...item,
            appliedDate: isDateValid(item?.appliedDate) === true ? item?.appliedDate?.split('T')?.[0] : '',
            approvedDate: isDateValid(item?.approvedDate) === true ? item?.approvedDate?.split('T')?.[0] : '',
            deniedDate: isDateValid(item?.deniedDate) === true ? item?.deniedDate?.split('T')?.[0] : '',
          }))
        );
      }

      if (requestAPIData?.request?.permits?.[0]?.dredgeProject) {
        setDredgeProjectData(requestAPIData?.request?.permits?.[0]?.dredgeProject);
      }
    }, [requestAPIData]);

    const handleChange = (e) => {
      if (e?.target?.name === 'previousFileNumbersExist' && e?.target?.value === 'false') {
        setValue('previousFileNumbers', [], { shouldValidate: true });
        doUpdateRequestData({ previousFileNumbers: [] });
        return;
      }

      if (e?.target?.name === 'dredgeType' && thisSectionStepStatus === 'true') trigger();

      // Map & insert values into GP data modal
      if (!isReadOnly) {
        const values = getValues();
        const gpValues = {
          hasRelatedAgencyApplications:
            values?.hasRelatedAgencyApplications === 'true'
              ? true
              : values?.hasRelatedAgencyApplications === 'false'
                ? false
                : null,
          requestedPermitType: values?.requestedPermitType ?? '',
          developmentType: values?.developmentType ?? '',
          workStartDate: isDateValid(values?.projectWorkStartDate) === true ? values?.projectWorkStartDate : '',
          workEndDate: isDateValid(values?.projectWorkEndDate) ? values?.projectWorkEndDate : '',
          navigableWatersAdditionalInfo: values?.navigableWatersAdditionalInfo ?? '',
          isAnyWorkComplete:
            values?.isAnyWorkComplete === 'true' ? true : values?.isAnyWorkComplete === 'false' ? false : null,
          completedWorkDescription: values?.completedWorkDescription ?? '',
          isInTerrSeasOrOceanWaters:
            values?.isInTerrSeasOrOceanWaters === 'true'
              ? true
              : values?.isInTerrSeasOrOceanWaters === 'false'
                ? false
                : null,
          activityBaselineRelationship: values?.activityBaselineRelationship ?? '',
          isDredgingNavWaters:
            values?.isDredgingNavWaters === 'true' ? true : values?.isDredgingNavWaters === 'false' ? false : null,
          includesInstallPiling: false,
          includesShorelineStabilization: false,
          applicableStatutoryAuthorities: values?.applicableStatutoryAuthority ?? [],
          dredgeProject: populateDredgeProject() ?? null,
          discharges_404: populateDischarges() ?? null,
          dredgedTransportation: populateDredgedTransportation() ?? null,
        };
        const prevFileNumbers =
          values?.previousFileNumbersExist === 'false'
            ? []
            : values?.previousFileNumbers?.length > 0
              ? values?.previousFileNumbers?.map((val) => ({ ormFileNumber: val?.value?.toUpperCase() }))
              : [];
        doUpdateRequestData({
          projectName: values?.projectName,
          previousFileNumbers: prevFileNumbers,
          projectDescription: values?.projectDescription,
          projectPurpose: values?.projectPurpose,
          natureOfActivity: values?.natureOfActivity,
        });
        doUpdatePermitRequest(gpValues);
      }
    };

    // Populate General Project Info nested sections

    const populateDredgeProject = () => {
      const values = getValues();
      // Dredge Project
      const dredgeProjectObj = {
        materialTypeComposition: values?.materialTypeComposition ?? '',
        dredgeMethod: values?.dredgeMethod ?? '',
        constructionMethods: values?.constructionMethods ?? '',
        estimatedNumberOfEvents: setNumberValue(values?.estimatedNumberOfEvents),
        workStartDate: isDateValid(values?.workStartDate) ? values?.workStartDate : '',
        workEndDate: isDateValid(values?.workEndDate) ? values?.workEndDate : '',
        durationOfWork: setNumberValue(values?.durationOfWork),
        unitOfWork: values?.unitOfWork ?? '',
        currentDepth: setNumberValue(values?.currentDepth, false),
        isDredgeTypeMaintenance:
          values?.dredgeType.length > 0 ? (values?.dredgeType?.includes('Maintenance') ? true : false) : null,
        isDredgeTypeNew: values?.dredgeType.length > 0 ? (values?.dredgeType?.includes('New') ? true : false) : null,
        lastDredgeCycle: values?.lastDredgeCycle ?? '',
        authorizedLength: setNumberValue(values?.authorizedLength, false),
        authorizedWidth: setNumberValue(values?.authorizedWidth, false),
        authorizedDepth: setNumberValue(values?.authorizedDepth, false),
        disposalPlan: values?.disposalPlan ?? '',
        estNumDisposalTrips: setNumberValue(values?.estNumDisposalTrips),
        dredgeDisposalLatitude: formatCoordFlt(values?.dredgeDisposalLatitude) ?? null,
        dredgeDisposalLongitude: formatCoordFlt(values?.dredgeDisposalLongitude) ?? null,
      };
      // Check if values in obj are all null/empty string
      return filterNullEmptyObjects(dredgeProjectObj) !== null ? { ...dredgeProjectData, ...dredgeProjectObj } : null;
    };

    const populateDischarges = () => {
      const values = getValues();
      const discharges404Obj = {
        materialSource: values?.dischargeMaterialSource ?? '',
        dischargeReason: values?.dischargeReason ?? '',
      };
      return filterNullEmptyObjects(discharges404Obj) !== null ? { ...discharges404Obj } : null;
    };

    const populateDredgedTransportation = () => {
      const values = getValues();
      const dredgedTransportationObj = {
        isSiteDesignatedForDISP:
          values?.isSiteDesignatedForDISP === 'true' ? true : values?.isSiteDesignatedForDISP === 'false' ? false : '',
        knownDredgeMaterialDischarges: values?.knownDredgeMaterialDischarges ?? '',
        disposalSiteCharacteristics: values?.disposalSiteCharacteristics ?? '',
        previousDisposals: values?.previousDisposals ?? '',
        estNumberOfMonthsOnSite: setNumberValue(values?.estNumberOfMonthsOnSite),
        materialSource: values?.materialSource ?? '',
        materialTypeAndComp: values?.materialTypeAndComp ?? '',
        transportationAndDisposalMethod: values?.transportationAndDisposalMethod ?? '',
      };
      return filterNullEmptyObjects(dredgedTransportationObj) !== null ? { ...dredgedTransportationObj } : null;
    };

    const handleSelectChange = (e) => {
      const results = e.map((num) => isValidORMNumber(num.value));

      if (!results.every(Boolean)) {
        setValue('previousFileNumbers', e.length > 1 ? previousFileNumbers : []);
        doSecondaryModalOpen(statusModal, { status: 'Failed', msg: ormErrorMsg });
      }
    };

    /* clear out sections when dropdowns are false */
    useEffect(() => {
      if (isAnyWorkComplete === 'false' || isAnyWorkComplete === '') {
        setValue('completedWorkDescription', '', { shouldValidate: true });
        doUpdatePermitRequest({ completedWorkDescription: '' });
      }
    }, [isAnyWorkComplete, setValue, doUpdatePermitRequest]);

    useEffect(() => {
      if (isInTerrSeasOrOceanWaters === 'false' || isInTerrSeasOrOceanWaters === '') {
        setValue('activityBaselineRelationship', '', { shouldValidate: true });
        doUpdatePermitRequest({ activityBaselineRelationship: '' });
      }
    }, [isInTerrSeasOrOceanWaters, setValue, doUpdatePermitRequest]);

    useEffect(() => {
      if (hasRelatedAgencyApplications === 'false' || hasRelatedAgencyApplications === '') {
        setRelatedAppsData([]);
        doUpdatePermitRequest({ activityBaselineRelationship: '' });
      }
    }, [hasRelatedAgencyApplications, setValue, doUpdatePermitRequest]);

    useEffect(() => {
      if (relatedAppsData?.length < 1 && hasRelatedAgencyApplications === 'true') {
        setError('relatedAppsTable', {
          type: 'custom',
          message: 'Previous Permits, Certificates... : At least one certificate, approval or denial is required',
        });
      } else if (relatedAppsRowErrors) {
        setError('relatedAppsTable', {
          type: 'custom',
          message: 'Previous Permits, Certificates... : Table contains invalid rows',
        });
      } else if (!relatedAppsRowErrors && relatedAppsData?.length > 0) {
        clearErrors('relatedAppsTable');
      } else {
        clearErrors('relatedAppsTable');
      }
    }, [hasRelatedAgencyApplications, relatedAppsRowErrors, relatedAppsData, clearErrors, setError]);

    useEffect(() => {
      if (!applicableStatutoryAuthority.includes(ApplicableStatutoryAuthorities.Section404)) {
        setValue('dischargeMaterialSource', '', { shouldValidate: true });
        setValue('dischargeReason', '', { shouldValidate: true });
        doUpdatePermitRequest({ discharges_404: null });
      }
    }, [applicableStatutoryAuthority, doUpdatePermitRequest, setValue]);

    useEffect(() => {
      if (!applicableStatutoryAuthority.includes(ApplicableStatutoryAuthorities.Section10)) {
        setValue('isDredgingNavWaters', '', { shouldValidate: true });
        doUpdatePermitRequest({ isDredgingNavWaters: null });
      }
    }, [applicableStatutoryAuthority, doUpdatePermitRequest, setValue]);

    useEffect(() => {
      if (!applicableStatutoryAuthority.includes(ApplicableStatutoryAuthorities.Section103)) {
        setValue('isSiteDesignatedForDISP', '', { shouldValidate: true });
        setValue('knownDredgeMaterialDischarges', '', { shouldValidate: true });
        setValue('disposalSiteCharacteristics', '', { shouldValidate: true });
        setValue('previousDisposals', '', { shouldValidate: true });
        setValue('estNumberOfMonthsOnSite', '', { shouldValidate: true });
        setValue('materialSource', '', { shouldValidate: true });
        setValue('materialTypeAndComp', '', { shouldValidate: true });
        doUpdatePermitRequest({ dredgedTransportation: null });
      }
    }, [applicableStatutoryAuthority, doUpdatePermitRequest, setValue]);

    useEffect(() => {
      if (applicableStatutoryAuthority.length === 0) {
        setValue('isDredgingNavWaters', '', { shouldValidate: true });
        doUpdatePermitRequest({ isDredgingNavWaters: null });
      }
    }, [applicableStatutoryAuthority, doUpdatePermitRequest, setValue]);

    // Dredging
    useEffect(() => {
      if (dredgeType.length === 0) {
        setValue('lastDredgeCycle', '', { shouldValidate: true });
        setValue('authorizedLength', '', { shouldValidate: true });
        setValue('authorizedWidth', '', { shouldValidate: true });
        setValue('authorizedDepth', '', { shouldValidate: true });
        setValue('disposalPlan', '', { shouldValidate: true });
        setValue('estNumDisposalTrips', '', { shouldValidate: true });
        setValue('dredgeDisposalLatitude', '', { shouldValidate: true });
        setValue('dredgeDisposalLongitude', '', { shouldValidate: true });
        doUpdatePermitRequestSub('dredgeProject', { lastDredgeCycle: null });
        doUpdatePermitRequestSub('dredgeProject', { authorizedLength: null });
        doUpdatePermitRequestSub('dredgeProject', { authorizedWidth: null });
        doUpdatePermitRequestSub('dredgeProject', { authorizedDepth: null });
        doUpdatePermitRequestSub('dredgeProject', { disposalPlan: '' });
        doUpdatePermitRequestSub('dredgeProject', { estNumDisposalTrips: null });
        doUpdatePermitRequestSub('dredgeProject', { dredgeDisposalLatitude: null });
        doUpdatePermitRequestSub('dredgeProject', { dredgeDisposalLongitude: null });
      }
    }, [dredgeType, setValue, doUpdatePermitRequestSub]);

    useEffect(() => {
      if (isDredgingNavWaters === 'false' || isDredgingNavWaters === '') {
        setValue('materialTypeComposition', '', { shouldValidate: true });
        setValue('dredgeMethod', '', { shouldValidate: true });
        setValue('constructionMethods', '', { shouldValidate: true });
        setValue('estimatedNumberOfEvents', '', { shouldValidate: true });
        setValue('workStartDate', '', { shouldValidate: true });
        setValue('workEndDate', '', { shouldValidate: true });
        setValue('durationOfWork', '', { shouldValidate: true });
        setValue('unitOfWork', '', { shouldValidate: true });
        setValue('currentDepth', '', { shouldValidate: true });
        setValue('dredgeType', [], { shouldValidate: true });
        doUpdatePermitRequestSub('dredgeProject', { materialTypeComposition: '' });
        doUpdatePermitRequestSub('dredgeProject', { dredgeMethod: '' });
        doUpdatePermitRequestSub('dredgeProject', { constructionMethods: '' });
        doUpdatePermitRequestSub('dredgeProject', { estimatedNumberOfEvents: null });
        doUpdatePermitRequestSub('dredgeProject', { workStartDate: '' });
        doUpdatePermitRequestSub('dredgeProject', { workEndDate: '' });
        doUpdatePermitRequestSub('dredgeProject', { durationOfWork: null });
        doUpdatePermitRequestSub('dredgeProject', { unitOfWork: '' });
        doUpdatePermitRequestSub('dredgeProject', { currentDepth: null });
        doUpdatePermitRequestSub('dredgeProject', { isDredgeTypeMaintenance: null });
        doUpdatePermitRequestSub('dredgeProject', { isDredgeTypeNew: null });
        // Reset all dredge project data
        setDredgeProjectData({});
      }
    }, [isDredgingNavWaters, setValue, doUpdatePermitRequestSub]);

    //Dredge Transportation
    useEffect(() => {
      if (!applicableStatutoryAuthority.includes(ApplicableStatutoryAuthorities.Section103)) {
        setValue('isSiteDesignatedForDISP', '', { shouldValidate: true });
        setValue('disposalSiteCharacteristics', '', { shouldValidate: true });
        setValue('previousDisposals', '', { shouldValidate: true });
        setValue('estNumberOfMonthsOnSite', '', { shouldValidate: true });
        setValue('materialSource', '', { shouldValidate: true });
        setValue('materialTypeAndComp', '', { shouldValidate: true });
        setValue('transportationAndDisposalMethod', '', { shouldValidate: true });

        doUpdatePermitRequestSub('dredgedTransportation', { isSiteDesignatedForDISP: '' });
        doUpdatePermitRequestSub('dredgedTransportation', { disposalSiteCharacteristics: '' });
        doUpdatePermitRequestSub('dredgedTransportation', { previousDisposals: '' });
        doUpdatePermitRequestSub('dredgedTransportation', { estNumberOfMonthsOnSite: null });
        doUpdatePermitRequestSub('dredgedTransportation', { materialSource: '' });
        doUpdatePermitRequestSub('dredgedTransportation', { materialTypeAndComp: '' });
        doUpdatePermitRequestSub('dredgedTransportation', { transportationAndDisposalMethod: '' });
      }
    }, [applicableStatutoryAuthority, doUpdatePermitRequestSub, setValue]);

    useEffect(() => {
      if (isSiteDesignatedForDISP === 'true') {
        setValue('disposalSiteCharacteristics', '', { shouldValidate: true });
        doUpdatePermitRequestSub('dredgedTransportation', { disposalSiteCharacteristics: '' });
      }
    }, [isSiteDesignatedForDISP, doUpdatePermitRequestSub, setValue]);

    useEffect(() => {
      const relatedAppsValid =
        hasRelatedAgencyApplications === 'true' ? relatedAppsData?.length > 0 && !relatedAppsRowErrors : true;
      const sectionValid = isValid && relatedAppsValid;
      doUpdateSectionValidity(IPFormGeneralProjectInformationMetadata.sectionName, sectionValid, stepNo, isReadOnly);
    }, [
      isValid,
      doUpdateSectionValidity,
      hasRelatedAgencyApplications,
      stepNo,
      relatedAppsData?.length,
      relatedAppsRowErrors,
      isReadOnly,
    ]);

    useErrorFocus({ steps, stepNo, activeStep, trigger, isReadOnly });

    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly && (
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        )}
        <FieldHeader text='General Project Information' />
        <h6 className='border-bottom width-full padding-bottom-1 margin-top-3'>Project</h6>
        <div className='ml-2'>
          <TextInput
            label='Project Name'
            name='projectName'
            type='text'
            tooltip={tooltipText.projectName}
            onChange={handleChange}
            readOnly={isReadOnly}
            required
          />
        </div>
        <div className='ml-2'>
          <SelectInput
            className='w-50'
            name='previousFileNumbersExist'
            label='Has the USACE previously issued a file number for any part of the project area?'
            onChange={handleChange}
            readOnly={isReadOnly}
            required
          >
            <option key='2' value='true'>
              Yes
            </option>
            <option key='3' value='false'>
              No
            </option>
          </SelectInput>
        </div>
        {previousFileNumbersExist === 'true' && (
          <div className='mt-2 ml-2'>
            <CreatableSelectInput
              className='mt-2'
              name='previousFileNumbers'
              label='Previous File Number(s)'
              tooltip={tooltipText.previousFileNumbers}
              placeholder='e.g. MVS-2023-12345, MVM-2020-1234 ...'
              required
              multi
              readOnly={isReadOnly}
              handleChange={handleSelectChange}
              handleBlur={handleChange}
            />
          </div>
        )}
        <h6 className='border-bottom width-full padding-bottom-1 margin-top-3'>Permits Info</h6>
        <div className='ml-2'>
          <SelectInput
            className='w-50'
            name='requestedPermitType'
            label={"Select the type of permit you're requesting"}
            onChange={handleChange}
            required
            readOnly={isReadOnly}
          >
            <option key='2' value='Standard Permit'>
              Standard Permit
            </option>
            <option key='3' value='Letter of Permission'>
              Letter of Permission
            </option>
            <option key='4' value='General Permit'>
              General Permit
            </option>
          </SelectInput>
        </div>
        <div className='ml-2'>
          <SelectInput
            className='w-50'
            name='developmentType'
            label='Select a development type'
            required
            tooltip={devTypeTooltipContent}
            onChange={handleChange}
            readOnly={isReadOnly}
          >
            <option key='2' value='Commercial'>
              Commercial
            </option>
            <option key='3' value='Non-Commercial'>
              Non-Commercial
            </option>
            <option key='4' value='Government/Tribal'>
              Government/Tribal
            </option>
          </SelectInput>
        </div>
        <div className='ml-2' id='relatedAppsTable'>
          <SelectInput
            className='w-50'
            name='hasRelatedAgencyApplications'
            label='Have other Certificates or Approvals/Denials been received from other Federal, State, or Local Agencies for Work Described in This Application?'
            required
            tooltip={otherCertsContent}
            onChange={handleChange}
            readOnly={isReadOnly}
          >
            <option key='2' value='true'>
              Yes
            </option>
            <option key='3' value='false'>
              No
            </option>
          </SelectInput>
        </div>
        {hasRelatedAgencyApplications === 'true' && (
          <>
            <label className='mt-3 mb-0 ml-2'>
              List any certificates or approvals/denials that have been received from other federal, state, or local
              agencies for work described in this application.<span className='asterisk-color'>*</span>
            </label>
            <TanStackTableNew
              data={relatedAppsData}
              key={relatedAppsKey}
              columns={relatedAppsColumns}
              validationSchema={relatedAppsValidationSchema}
              rowErrorCallback={setRelatedAppsRowErrors}
              addRow={() => setRelatedAppsData((prev) => (prev ? [...prev, {}] : [{}]))}
              updateSourceData={updateRelatedAppsData}
              removeMultipleRows={removeMultipleRelatedAppRows}
              isReadOnly={isReadOnly}
              initialTableState={{}}
              hideDrag={true}
              placeholderText={
                'No certificates or approvals/denials found, Please click below to add a certificate,  approvals and/or denial'
              }
              placeholderClick={() => setRelatedAppsData((prev) => (prev ? [...prev, {}] : [{}]))}
            />
          </>
        )}
        <div className='ml-2'>
          <TextAreaInput
            name='projectDescription'
            label='Project Description (Describe the proposed Individual Permit activity)'
            tooltip={tooltipText.projectDescription}
            readOnly={isReadOnly}
            onChange={handleChange}
            required
          />
        </div>
        <div className='ml-2'>
          <TextAreaInput
            name='natureOfActivity'
            label='Nature of Activity'
            tooltip={tooltipText.natureOfActivity}
            readOnly={isReadOnly}
            onChange={handleChange}
            required
          />
        </div>
        <div className='ml-2'>
          <TextAreaInput
            name='projectPurpose'
            label='Project Purpose (Describe the reason or purpose of the project)'
            required
            tooltip={tooltipText.projectPurpose}
            onChange={handleChange}
            readOnly={isReadOnly}
          />
        </div>
        <label className='mt-3 mb-0 ml-2'>Approximate dates you plan to both begin and complete all work:</label>
        <DateRange
          className='col-sm-6'
          nameBefore='projectWorkStartDate'
          nameAfter='projectWorkEndDate'
          labelBefore='Project Start Date'
          labelAfter='Project End Date'
          dateBefore={dateBeforeA}
          onChange={handleChange}
          readOnly={isReadOnly}
          required
        />
        <div className='ml-2'>
          <SelectInput
            className='w-50'
            name='isAnyWorkComplete'
            label='Is any portion of the work complete?'
            tooltip={tooltipText.isAnyWorkComplete}
            onChange={handleChange}
            readOnly={isReadOnly}
            required
          >
            <option key='2' value='true'>
              Yes
            </option>
            <option key='3' value='false'>
              No
            </option>
          </SelectInput>
        </div>
        {(isAnyWorkComplete === 'true' || isAnyWorkComplete === true) && (
          <div className='ml-2'>
            <TextAreaInput
              name='completedWorkDescription'
              label='Describe the completed work'
              onChange={handleChange}
              readOnly={isReadOnly}
              required
            />
          </div>
        )}
        <fieldset className='mt-3' id='applicableStatutoryAuthority'>
          <Label className='margin-bottom-0' htmlFor='applicableStatutoryAuthority'>
            <span id='applicableStatutoryAuthority_label'>Select Applicable Statutory Authority</span>
            <span className='asterisk-color'>*</span>
          </Label>
          <Alert className='mt-1' type='info'>
            If you're unsure of which authorities apply to your project, you can{' '}
            <ExternalLink
              href={
                currentEnv === 'local' || currentEnv === 'development' ? '/home/permitting' : '/rrs/home/permitting'
              }
              title='read about permitting requirements'
              content='read about permitting requirements'
              aria-label='read about permitting requirements'
            />{' '}
            or contact your{' '}
            <ExternalLink
              href='https://regulatory.ops.usace.army.mil/offices '
              title='local regulatory office'
              content='local regulatory office'
              aria-label='local regulatory office'
            />
            .
          </Alert>
          <Checkbox
            disabled={isReadOnly}
            id='check-section-404'
            label={ApplicableStatutoryAuthorities.Section404}
            labelDescription='Requires authorization from the U.S. Army Corps of Engineers, for the discharge of dredged or fill material into all waters of the United States, including wetlands. Discharges of fill material generally include, without limitation: placement of fill that is necessary for the construction of any structure, or impoundment requiring rock, sand, dirt, or other material for its construction; site-development fills for recreational, industrial, commercial, residential, and other uses; causeways or road fills; dams and dikes; artificial islands; property protection or reclamation devices such as riprap, groins, seawalls, breakwaters, and revetments; beach nourishment; levees; fill for intake and outfall pipes and subaqueous utility lines; fill associated with the creation of ponds; and any other work involving the discharge of fill or dredged material. A USACE permit is required whether the work is permanent or temporary. Examples of temporary discharges include dewatering of dredged material prior to final disposal, and temporary fills for access roadways, cofferdams, storage and work areas.'
            name='applicableStatutoryAuthority'
            onChange={handleChange}
            tile
            value={ApplicableStatutoryAuthorities.Section404}
          />
          <Checkbox
            disabled={isReadOnly}
            id='check-section-10'
            label={ApplicableStatutoryAuthorities.Section10}
            labelDescription='Requires authorization from the United States Army Corps of Engineers, for the construction of any structure in or over any navigable water of the United States. Structures or work outside the limits defined for navigable waters of the United States require a Section 10 permit if the structure or work affects the course, location, or condition of the water body. The law applies to any dredging or disposal of dredged materials, excavation, filling, re-channelization, or any other modification of a navigable water of the United States, and applies to all structures, from the smallest floating dock to the largest commercial undertaking. It further includes, without limitation, any wharf, dolphin, weir, boom breakwater, jetty, groin, bank protection (e.g. riprap, revetment, bulkhead), mooring structures such as pilings, aerial or subaqueous power transmission lines, intake or outfall pipes, permanently moored floating vessel, tunnel, artificial canal, boat ramp, aids to navigation, and any other permanent, or semi-permanent obstacle or obstruction.'
            name='applicableStatutoryAuthority'
            onChange={handleChange}
            tile
            value={ApplicableStatutoryAuthorities.Section10}
          />
          <Checkbox
            disabled={isReadOnly}
            id='check-section-103'
            label={ApplicableStatutoryAuthorities.Section103}
            labelDescription='Requires authorization from the United States Army Corps of Engineers for the disposal of dredged materials in the ocean.'
            name='applicableStatutoryAuthority'
            onChange={handleChange}
            tile
            value={ApplicableStatutoryAuthorities.Section103}
          />
        </fieldset>
        {isCoastalDistrict && (
          <>
            <div className='ml-2'>
              <SelectInput
                name='isInTerrSeasOrOceanWaters'
                label='Would the project occur in the territorial seas or ocean waters?'
                required
                className='w-50'
                readOnly={isReadOnly}
                onChange={handleChange}
              >
                <option key='2' value='true'>
                  Yes
                </option>
                <option key='3' value='false'>
                  No
                </option>
              </SelectInput>
            </div>
            {isInTerrSeasOrOceanWaters === 'true' && (
              <div className='ml-2'>
                <TextAreaInput
                  name='activityBaselineRelationship'
                  label="Describe the activity's relationship to the baseline from which the territorial sea is measured"
                  tooltip={tooltipText.activityBaselineRelationship}
                  required
                  onChange={handleChange}
                  readOnly={isReadOnly}
                />
              </div>
            )}
          </>
        )}
        {/* Discharge Section */}
        {applicableStatutoryAuthority.length > 0 &&
          applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section404) && (
            <>
              <FieldHeader text='Discharges' subtext='Projects involving Section 404 Discharges' />
              <PermitsDischarges handleChange={handleChange} isReadOnly={isReadOnly} />
            </>
          )}
        {/* Dredge Project Section */}
        {applicableStatutoryAuthority.length > 0 &&
          applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && (
            <>
              <FieldHeader text='Dredging' subtext='Projects Involving Impacts to Section 10 Navigable Waters' />
              <div className='ml-2'>
                <SelectInput
                  name='isDredgingNavWaters'
                  label='Does the project involve dredging in navigable waters of the United States?'
                  className='w-50'
                  onChange={handleChange}
                  readOnly={isReadOnly}
                  required
                >
                  <option key='2' value='true'>
                    Yes
                  </option>
                  <option key='3' value='false'>
                    No
                  </option>
                </SelectInput>
              </div>
              {(isDredgingNavWaters === 'true' || isDredgingNavWaters === true) && (
                <PermitsDredgeProject handleChange={handleChange} isReadOnly={isReadOnly} />
              )}
            </>
          )}
        {/* Dredge Transportation Section */}
        {applicableStatutoryAuthority.length > 0 &&
          applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section103) && (
            <>
              <FieldHeader
                text='Dredge Transportation'
                subtext='Projects Involving the transportation of dredged material for the purpose of disposing of it in ocean waters'
              />
              <div className='ml-2'>
                <Alert type='warning'>
                  IMPORTANT: The physical boundaries of the disposal site must be provided in a map attached to this
                  request. The specific location of the disposal of the dredged material must be itemized in the impact
                  section of this request.
                </Alert>
              </div>
              <PermitsDredgeTransportation handleChange={handleChange} isReadOnly={isReadOnly} />
            </>
          )}
        <div className='mb-4' />
      </FormProvider>
    );
  }
);

IPFormGeneralProjectInformation.metadata = IPFormGeneralProjectInformationMetadata;

export default IPFormGeneralProjectInformation;
