import Icon from '@components/icon/Icon';
import { mdiDownload, mdiTrashCan } from '@mdi/js';
import { Button } from '@trussworks/react-uswds';

import '@pages/PublicNotices/public-notices-table/actionsTableCell.scss';

const EditSupportingDocsTableCell = ({
  row, 
  doDeleteFile,
  doDownloadFile,
  isReadOnly }) => (
  <>
    {row?.original?.key && (
      <div className='button-container'>
        <Button className='download-file' title={'Download File'} onClick={() => doDownloadFile(row?.original?.fileName, 'Request')}>
          <Icon aria-label={'Download File'} focusable={false} path={mdiDownload} size={'24px'}/>
        </Button>
        { !isReadOnly && 
        <Button className='delete-file' title={'Delete File'} onClick={() => doDeleteFile(row?.original?.fileName, row?.original?.componentID)} secondary>
          <Icon aria-label={'Delete File'} focusable={false} path={mdiTrashCan} size={'24px'} />
        </Button> }
      </div> )}
  </>
);

export default EditSupportingDocsTableCell;
